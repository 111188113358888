import React from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { usePRF } from "../../utils/PRFContext"; // Import PRF context

const roleConditions = [
  "Decapitation",
  "Maceration",
  "Decomposition/Putrifaction",
  "Hemicorporectomy",
  "Hypostasis",
  "Incineration",
  "Massive Cerebral Destruction",
  "Rigor Mortis",
];

const cessationOfResuscitation = [
  "Patient Asystolic",
  "Remained Asystolic",
  "30 seconds of asystole",
  "20 minutes of asystole after 30 minutes of ALS",
];

const unequivocalConditions = [
  "Collapse >15 minutes",
  "No evidence of CPR prior to arrival",
  "No contradiction to protocol",
  "30 seconds of asystole",
];

const RecognitionOfLifeExtinct = () => {
  const { prfData, handleInputChange } = usePRF();

  const handleCheckboxChange = (event, section, field) => {
    handleInputChange(section, field, event.target.checked);
  };

  return (
    <Box>
      {/* DNACPR, Advanced Directive, Terminally Ill Checkboxes */}
      <FormControlLabel
        control={
          <Checkbox
            checked={prfData.ROLE?.dnacpr || false}
            onChange={(e) => handleCheckboxChange(e, "ROLE", "dnacpr")}
          />
        }
        label="Witnessed DNACPR / ReSPECT Document"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={prfData.ROLE?.advanced_directive || false}
            onChange={(e) =>
              handleCheckboxChange(e, "ROLE", "advanced_directive")
            }
          />
        }
        label="Advanced Directive"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={prfData.ROLE?.terminally_ill || false}
            onChange={(e) => handleCheckboxChange(e, "ROLE", "terminally_ill")}
          />
        }
        label="Terminally Ill"
      />

      {/* Conditions Incompatible with Life */}
      <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
        Conditions Incompatible with Life
      </Typography>
      {roleConditions.map((condition) => (
        <FormControlLabel
          key={condition}
          control={
            <Checkbox
              checked={prfData.ROLE?.[condition] || false}
              onChange={(e) => handleCheckboxChange(e, "ROLE", condition)}
            />
          }
          label={condition}
        />
      ))}

      {/* Cessation of Resuscitation */}
      <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
        Cessation of Resuscitation
      </Typography>
      {cessationOfResuscitation.map((item) => (
        <FormControlLabel
          key={item}
          control={
            <Checkbox
              checked={prfData.ROLE?.[item] || false}
              onChange={(e) => handleCheckboxChange(e, "ROLE", item)}
            />
          }
          label={item}
        />
      ))}

      {/* Conditions unequivocally associated with death */}
      <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
        Conditions unequivocally associated with death
      </Typography>
      {unequivocalConditions.map((condition) => (
        <FormControlLabel
          key={condition}
          control={
            <Checkbox
              checked={prfData.ROLE?.[condition] || false}
              onChange={(e) => handleCheckboxChange(e, "ROLE", condition)}
            />
          }
          label={condition}
        />
      ))}

      {/* ROLE Time, Clinician Name, and Grade */}
      <TextField
        label="ROLE Time"
        name="role_time"
        value={prfData.ROLE?.role_time || ""}
        onChange={(e) => handleInputChange("ROLE", "role_time", e.target.value)}
        fullWidth
        sx={{ mb: 2 }}
      />
      <TextField
        label="ROLE Clinician Name"
        name="role_clinician_name"
        value={prfData.ROLE?.role_clinician_name || ""}
        onChange={(e) =>
          handleInputChange("ROLE", "role_clinician_name", e.target.value)
        }
        fullWidth
        sx={{ mb: 2 }}
      />
      <TextField
        label="ROLE Clinician Grade (TECH/AAP+)"
        name="role_clinician_grade"
        value={prfData.ROLE?.role_clinician_grade || ""}
        onChange={(e) =>
          handleInputChange("ROLE", "role_clinician_grade", e.target.value)
        }
        fullWidth
        sx={{ mb: 2 }}
      />
    </Box>
  );
};

export default RecognitionOfLifeExtinct;
