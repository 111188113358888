import React, { useEffect } from "react";
import { TextField, Box, Button } from "@mui/material";
import { usePRF } from "../../utils/PRFContext"; // Import PRFContext

const Observations = () => {
  const { prfData, setPrfData, handleObservationChange, addObservation } =
    usePRF(); // Use context and set function

  useEffect(() => {
    console.log("Loaded prfData.observations:", prfData.observations);
    if (!prfData.observations) {
      setPrfData((prevData) => ({
        ...prevData,
        observations: [],
      }));
    }
  }, [prfData, setPrfData]);

  return (
    <Box>
      {/* Check if prfData.observations exists and map over each observation */}
      {prfData.observations &&
        prfData.observations.map((observation, index) => (
          <Box key={index} sx={{ mb: 2 }}>
            <TextField
              label="Date and Time"
              type="datetime-local"
              name="date_time"
              value={
                observation.date_time
                  ? new Date(observation.date_time).toISOString().slice(0, 16)
                  : ""
              }
              onChange={(e) =>
                handleObservationChange(index, "date_time", e.target.value)
              }
              fullWidth
              sx={{ mb: 2 }}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Heart Rate"
              name="heart_rate"
              value={observation.heart_rate || ""}
              onChange={(e) =>
                handleObservationChange(index, "heart_rate", e.target.value)
              }
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="Blood Pressure"
              name="blood_pressure"
              value={observation.blood_pressure || ""}
              onChange={(e) =>
                handleObservationChange(index, "blood_pressure", e.target.value)
              }
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="Oxygen Saturation"
              name="oxygen_saturation"
              value={observation.oxygen_saturation || ""}
              onChange={(e) =>
                handleObservationChange(
                  index,
                  "oxygen_saturation",
                  e.target.value
                )
              }
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="Respiratory Rate"
              name="respiratory_rate"
              value={observation.respiratory_rate || ""}
              onChange={(e) =>
                handleObservationChange(
                  index,
                  "respiratory_rate",
                  e.target.value
                )
              }
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="Temperature"
              name="temperature"
              value={observation.temperature || ""}
              onChange={(e) =>
                handleObservationChange(index, "temperature", e.target.value)
              }
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="GCS"
              name="gcs"
              value={observation.gcs || ""}
              onChange={(e) =>
                handleObservationChange(index, "gcs", e.target.value)
              }
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="Pain Score"
              name="pain_score"
              value={observation.pain_score || ""}
              onChange={(e) =>
                handleObservationChange(index, "pain_score", e.target.value)
              }
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="ECG"
              name="ecg"
              value={observation.ecg || ""}
              onChange={(e) =>
                handleObservationChange(index, "ecg", e.target.value)
              }
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="Comments"
              name="comments"
              value={observation.comments || ""}
              onChange={(e) =>
                handleObservationChange(index, "comments", e.target.value)
              }
              fullWidth
              multiline
              rows={2}
              sx={{ mb: 2 }}
            />
          </Box>
        ))}

      {/* Button to add a new observation */}
      <Button variant="contained" onClick={addObservation} sx={{ mt: 2 }}>
        Add Observation
      </Button>
    </Box>
  );
};

export default Observations;
