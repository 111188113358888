import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Box,
  Typography,
  Paper,
} from "@mui/material";

// Status options (all possible statuses)
const statusOptions = [
  "triage",
  "waiting",
  "treatment",
  "dischargedEvent",
  "dischargedUTC",
  "dischargedHome",
  "awaitingTransport",
  "transportedOwnMeans",
  "transportedAmbulance",
];

// Define display names for each status
const statusDisplayNames = {
  triage: "Triage",
  waiting: "Waiting",
  treatment: "Treatment",
  dischargedEvent: "Discharged (Event)",
  dischargedUTC: "Discharged (UTC)",
  dischargedHome: "Discharged (Home)",
  awaitingTransport: "Awaiting Transport",
  transportedOwnMeans: "Transported (Own Means)",
  transportedAmbulance: "Transported (Ambulance)",
};

// Define the flow of statuses (possible next steps)
const statusFlow = {
  triage: ["waiting", "treatment"],
  waiting: ["treatment"],
  treatment: [
    "dischargedEvent",
    "dischargedUTC",
    "dischargedHome",
    "awaitingTransport",
  ],
  awaitingTransport: ["transportedOwnMeans", "transportedAmbulance"],
  dischargedEvent: [],
  dischargedUTC: [],
  dischargedHome: [],
  transportedOwnMeans: [],
  transportedAmbulance: [],
};

const PRFStatus = ({ prfData, setPrfData, isAdmin, handleStatusChange }) => {
  const currentStatus = prfData.status || "triage"; // Default to Triage if no status is set
  const nextSteps = statusFlow[currentStatus] || []; // Get the possible next steps based on current status

  const handleNextStepChange = (nextStatus) => {
    // Update the PRF status to the next status
    handleStatusChange(nextStatus);
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          width: "100%",
          maxWidth: "500px",
          textAlign: "center",
          backgroundColor: "#ffffff",
          borderRadius: 4,
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            fontSize: "1.5rem",
            fontWeight: "bold",
            marginBottom: "16px",
            color: "#333",
          }}
        >
          Current Status:{" "}
          <Typography
            component="span"
            sx={{
              fontWeight: "bold",
              fontSize: "1.4rem",
              color: "#1976d2",
            }}
          >
            {statusDisplayNames[currentStatus]}
          </Typography>
        </Typography>

        {/* Admin-only dropdown to change status */}
        {isAdmin ? (
          <FormControl fullWidth sx={{ mt: 3, mb: 3 }}>
            <InputLabel id="status-label">Status</InputLabel>
            <Select
              labelId="status-label"
              name="status"
              value={currentStatus}
              onChange={(e) => handleStatusChange(e.target.value)}
            >
              {statusOptions.map((status) => (
                <MenuItem key={status} value={status}>
                  {statusDisplayNames[status]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <Box sx={{ mt: 3 }}>
            {/* Render next step buttons for non-admin users */}
            {nextSteps.length > 0 ? (
              <>
                <Typography variant="subtitle1" gutterBottom>
                  Next Steps:
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: 2,
                    flexWrap: "wrap",
                  }}
                >
                  {nextSteps.map((nextStatus) => (
                    <Button
                      key={nextStatus}
                      variant="contained"
                      color="primary"
                      onClick={() => handleNextStepChange(nextStatus)}
                      sx={{
                        mb: 2,
                        minWidth: 180,
                        backgroundColor: "#1976d2",
                        "&:hover": {
                          backgroundColor: "#115293",
                        },
                      }}
                    >
                      {statusDisplayNames[nextStatus]}
                    </Button>
                  ))}
                </Box>
              </>
            ) : (
              <Typography variant="body2" color="textSecondary">
                No further status changes possible.
              </Typography>
            )}
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default PRFStatus;
