// conditionsActions.js
export const conditionsActions = {
    Anaphylaxis: [
      "Administer adrenaline intramuscularly",
      "Call for emergency assistance",
      "Maintain airway and breathing",
      "Monitor vital signs",
      "Prepare for possible CPR",
    ],
    Asthma: [
      "Onset",
      "Adrenaline",
      "Salbutamol",
      "Ipratropium Bromide",
      "Oxygen",
      "Hydrocortisone",
      "Observations (BP, Resp, HR, PEFR, BM)",
      "Notes",
    ],
    // Other conditions remain the same...
    "Cardiac Arrest": [
      "Begin CPR immediately",
      "Call for emergency assistance",
      "Use an AED if available",
      "Maintain airway and ventilation",
      "Monitor and document interventions",
    ],
    // Add other conditions similarly...
  };
  
  export default conditionsActions;
  