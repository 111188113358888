import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  CircularProgress,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from '@mui/material';
import apiClient from '../../utils/requestHandler'; // Custom API client handler

const ClientEvents = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const res = await apiClient.get('/api/client/events');
        setEvents(res.data); // Set event data from API response
        setLoading(false);
      } catch (err) {
        console.error('Error fetching events:', err.message);
        setError('Failed to load events.');
        setLoading(false);
      }
    };
    fetchEvents();
  }, []);

  if (loading)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
        <CircularProgress />
      </Box>
    );

  if (error)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom align="center">
        Your Events
      </Typography>

      <TableContainer component={Paper} elevation={3}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><Typography variant="subtitle1" fontWeight="bold">Event Name</Typography></TableCell>
              <TableCell><Typography variant="subtitle1" fontWeight="bold">Date</Typography></TableCell>
              <TableCell><Typography variant="subtitle1" fontWeight="bold">Status</Typography></TableCell>
              <TableCell><Typography variant="subtitle1" fontWeight="bold">Actions</Typography></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events.map((event) => (
              <TableRow key={event.id} hover>
                <TableCell>{event.name}</TableCell>
                <TableCell>{new Date(event.date).toLocaleDateString()}</TableCell>
                <TableCell>{event.status}</TableCell>
                <TableCell>
                  <Button variant="contained" component={Link} to={`/client/event/${event.eventID}`}>
                    View Details
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ClientEvents;
