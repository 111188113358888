import React, { useEffect, useState } from "react";
import {
  TextField,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Autocomplete,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { usePRF } from "../../utils/PRFContext";
import apiClient from "../../utils/requestHandler";
import useNetworkStatus from "../../hooks/useNetworkStatus";

const IncidentDetails = ({ handleInputChange, readOnly, prfId }) => {
  const { prfData, setPrfData } = usePRF();
  const [events, setEvents] = useState([]);
  const [loadingEvents, setLoadingEvents] = useState(true);
  const [eventError, setEventError] = useState("");
  const isOnline = useNetworkStatus();
  const navigate = useNavigate();

  // Default fallback for incidentDetails to avoid undefined errors
  const incidentDetails = prfData?.incidentDetails || {
    event_id: "",
    incident_date: "",
    incident_location: "",
    event_name: "",
    chief_complaint: "",
  };

  const chiefComplaints = [
    "Abdominal pain or problems",
    "Allergic reactions",
    "Animal bites or stings",
    "Assault or sexual assault",
    "Back pain (non-traumatic or non-recent)",
    "Breathing difficulties",
    "Burns or scalds",
    "Carbon monoxide poisoning or inhalation injury",
    "Cardiac arrest or respiratory arrest",
    "Chest pain (non-traumatic)",
    "Choking",
    "Convulsions or seizures",
    "Diabetic emergencies (low or high blood sugar)",
    "Drowning or diving accidents",
    "Electrocution or lightning strike",
    "Eye injuries or problems",
    "Falls from height",
    "Fractures or suspected fractures",
    "Head injuries (minor or major)",
    "Heart problems (e.g., palpitations or arrhythmia)",
    "Heat exhaustion or heatstroke",
    "Hypothermia or cold exposure",
    "Heavy bleeding or lacerations",
    "Inaccessible or entrapped patient (e.g., trapped limbs)",
    "Overdose or poisoning (intentional or accidental)",
    "Pregnancy-related problems or miscarriage",
    "Psychiatric emergencies or suicide attempts",
    "General unwellness (e.g., feeling faint or dizzy)",
    "Penetrating injuries (e.g., stab wounds)",
    "Stroke or transient ischaemic attack",
    "Sports injuries (e.g., sprains, strains, or ligament damage)",
    "Dance-related injuries (e.g., ankle sprains, muscle tears)",
    "Knee pain or injuries",
    "Shoulder dislocation or injuries",
    "Neck pain or stiffness (e.g., whiplash)",
    "Suspected concussion",
    "Overuse injuries (e.g., tendinitis, shin splints)",
    "Dislocations (e.g., fingers, shoulders, or toes)",
    "Soft tissue injuries (e.g., bruises, contusions)",
    "Traumatic injuries (e.g., road traffic collisions)",
    "Wound care (e.g., abrasions, cuts, or blisters)",
    "Hyperventilation or panic attacks",
    "Chest trauma (e.g., rib fractures)",
    "Abdominal trauma (e.g., blunt injury)",
    "Pelvic injuries",
    "Spinal injuries or suspected spinal cord damage",
    "Sudden collapse or unexplained unconsciousness",
    "Headache or migraines",
    "Nosebleeds (epistaxis)",
    "Dental trauma or injuries",
    "Asthma attack or breathing exacerbation",
    "Severe pain (e.g., abdomen, chest, or limbs)",
    "Skin reactions or rashes (e.g., hives, eczema flare-ups)",
    "Suspected dehydration",
    "Overexertion-related injuries or symptoms",
  ];

  useEffect(() => {
    console.log("IncidentDetails component mounted or updated.");
    console.log("incidentDetails:", incidentDetails);
    console.log("events:", events);

    if (!incidentDetails.event_id) {
      setPrfData((prev) => ({
        ...prev,
        incidentDetails: {
          event_id: "",
          incident_date: "",
          incident_location: "",
          event_name: "",
          chief_complaint: "",
          ...prev.incidentDetails,
        },
      }));
    }

    // Fetch events if no event is selected and only if online
    if (isOnline && loadingEvents && !incidentDetails.event_id) {
      fetchEvents();
    } else {
      setLoadingEvents(false);
    }
  }, [isOnline, loadingEvents, incidentDetails.event_id, setPrfData]);

  const fetchEvents = async () => {
    try {
      const response = await apiClient.get("/api/events");
      setEvents(response.data || []);
      console.log("Fetched events:", response.data);
    } catch (err) {
      console.error("Error fetching events:", err.message);
      setEventError("Failed to load events.");
    } finally {
      setLoadingEvents(false);
    }
  };

  const handleEventChange = (event) => {
    const selectedEventId = parseInt(event.target.value, 10);
    const selectedEvent = events.find((evt) => evt.eventID === selectedEventId);

    console.log("Selected Event ID:", selectedEventId);
    console.log("Selected Event Data:", selectedEvent);

    setPrfData((prev) => ({
      ...prev,
      incidentDetails: {
        ...prev.incidentDetails,
        event_id: selectedEventId,
        event_name: selectedEvent?.name || "",
        incident_location: selectedEvent?.location || "",
      },
    }));

    handleInputChange("event_id", selectedEventId);
    handleInputChange("incident_location", selectedEvent?.location || "");
    handleInputChange("event_name", selectedEvent?.name || "");
  };

  const handleLocalChange = (name, value) => {
    console.log(`handleLocalChange: ${name} = ${value}`);

    setPrfData((prev) => ({
      ...prev,
      incidentDetails: {
        ...prev.incidentDetails,
        [name]: value,
      },
    }));

    handleInputChange(name, value);
  };

  const handleStartKioskMode = async () => {
    try {
      await apiClient.put(`/api/prf/${prfId}/kiosk/enable`);
      navigate(`/prf/${prfId}/kiosk`);
    } catch (err) {
      console.error("Failed to start kiosk mode:", err.message);
    }
  };

  return (
    <Box>
      {!isOnline && (
        <Typography variant="body1" color="error" sx={{ mb: 2 }}>
          You are currently offline. Some options may be unavailable.
        </Typography>
      )}

      {/* Event Selection */}
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="event-label">Select Event</InputLabel>
        <Select
          labelId="event-label"
          label="Select Event"
          name="event_id"
          value={incidentDetails.event_id || ""}
          onChange={handleEventChange}
          disabled={!isOnline || loadingEvents || events.length === 0}
        >
          {loadingEvents ? (
            <MenuItem value="" disabled>
              Loading events...
            </MenuItem>
          ) : events.length > 0 ? (
            events.map((event) => (
              <MenuItem
                key={event.eventID || event.id}
                value={event.eventID || event.id || ""}
              >
                {event.name || "Unnamed Event"}
              </MenuItem>
            ))
          ) : (
            <MenuItem value="" disabled>
              No events available
            </MenuItem>
          )}
        </Select>
      </FormControl>

      {/* Incident Details */}
      <TextField
        label="Event Name"
        name="event_name"
        value={incidentDetails.event_name || ""}
        fullWidth
        disabled
        sx={{ mb: 2 }}
      />
      <TextField
        label="Incident Date and Time"
        name="incident_date"
        type="datetime-local"
        value={
          incidentDetails.incident_date
            ? new Date(incidentDetails.incident_date).toISOString().slice(0, 16)
            : ""
        }
        onChange={(e) => handleLocalChange("incident_date", e.target.value)}
        fullWidth
        sx={{ mb: 2 }}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        label="Incident Location"
        name="incident_location"
        value={incidentDetails.incident_location || ""}
        onChange={(e) => handleLocalChange("incident_location", e.target.value)}
        fullWidth
        sx={{ mb: 2 }}
      />

      {/* Chief Complaint */}
      <FormControl fullWidth sx={{ mb: 2 }}>
        <Autocomplete
          options={chiefComplaints}
          value={incidentDetails.chief_complaint || null}
          onChange={(event, newValue) =>
            handleLocalChange("chief_complaint", newValue)
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Chief Complaint"
              variant="outlined"
              InputLabelProps={{ shrink: !!incidentDetails.chief_complaint }}
            />
          )}
          fullWidth
          sx={{ mb: 2 }}
        />
      </FormControl>

      {/* Start Kiosk Mode */}
      {!readOnly && (
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleStartKioskMode}
          sx={{ mt: 4 }}
        >
          Start Kiosk Mode - hand to patient
        </Button>
      )}
    </Box>
  );
};

export default IncidentDetails;
