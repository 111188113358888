import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import apiClient from "../utils/requestHandler";
import { useLoading } from "../contexts/LoadingContext";

function AuthLoader({ children }) {
  const [user, setUser] = useState(null);
  const [userLoaded, setUserLoaded] = useState(false);
  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const location = useLocation();

  const fetchUser = async () => {
    setLoading(true);
    try {
      const res = await apiClient.get("/api/auth/user", {
        withCredentials: true,
      });
      setUser(res.data);
    } catch (err) {
      setUser(null);
    } finally {
      setLoading(false);
      setUserLoaded(true);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (userLoaded) {
      const currentPath = location.pathname;
      const publicRoutes = ["/login", "/patient-register", "/createadmin"];

      if (!user && !publicRoutes.includes(currentPath)) {
        // Redirect to login if user is not authenticated and on a restricted route
        navigate("/login", { replace: true });
      } else if (user) {
        // Redirect to appropriate dashboard if user is logged in and at login page or root
        if (currentPath === "/" || currentPath === "/login") {
          const target =
            user.role === "admin" || user.role === "staff"
              ? "/dashboard"
              : "/patient-access";
          navigate(target, { replace: true });
        }
      }
    }
  }, [user, userLoaded, navigate, location]);

  if (!userLoaded) return setLoading(true);

  return children;
}

export default AuthLoader;
