import React from 'react';
import { Box, Typography } from '@mui/material';

const OfflineBanner = () => (
  <Box
    sx={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bgcolor: 'error.main',
      color: 'white',
      p: 1,
      textAlign: 'center',
      zIndex: 1000,
    }}
  >
    <Typography variant="body1">You are currently offline. Changes cannot be saved.</Typography>
  </Box>
);

export default OfflineBanner;
