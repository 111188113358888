import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import apiClient from "../utils/requestHandler";

const GDPRNoticeModal = ({ open, onClose }) => {
  const [notices, setNotices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeNoticeId, setActiveNoticeId] = useState(null);
  const [content, setContent] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [error, setError] = useState("");

  // Fetch all GDPR notices
  useEffect(() => {
    if (open) {
      fetchNotices();
    }
  }, [open]);

  const fetchNotices = async () => {
    setLoading(true);
    try {
      const response = await apiClient.get("/api/gdpr-notice");
      setNotices(response.data);
      const activeNotice = response.data.find((notice) => notice.is_active);
      if (activeNotice) {
        setActiveNoticeId(activeNotice.notice_id);
        setContent(activeNotice.content);
        setIsActive(activeNotice.is_active);
      }
    } catch (err) {
      setError("Failed to fetch GDPR notices.");
      console.error("Error fetching notices:", err.message);
    } finally {
      setLoading(false);
    }
  };

  // Handle updating or creating a notice
  const handleSave = async () => {
    if (!content.trim()) {
      setError("Content cannot be empty.");
      return;
    }

    setLoading(true);
    try {
      if (activeNoticeId) {
        // Update the existing active notice
        await apiClient.put(`/api/gdpr-notice/${activeNoticeId}`, {
          content,
          is_active: isActive,
        });
      } else {
        // Create a new GDPR notice
        await apiClient.post("/api/gdpr-notice", {
          content,
          is_active: isActive,
        });
      }

      setError("");
      fetchNotices(); // Refresh notices after saving
    } catch (err) {
      setError("Failed to save GDPR notice.");
      console.error("Error saving GDPR notice:", err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          width: 600,
          bgcolor: "background.paper",
          borderRadius: 2,
          p: 4,
          mx: "auto",
          my: "10vh",
          boxShadow: 24,
        }}
      >
        <Typography variant="h6" sx={{ mb: 2 }}>
          GDPR Notice Management
        </Typography>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Manage GDPR notices for your application. Only one notice can be
              active at a time.
            </Typography>
            <TextField
              label="GDPR Notice Content"
              multiline
              rows={6}
              fullWidth
              value={content}
              onChange={(e) => setContent(e.target.value)}
              sx={{ mb: 2 }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isActive}
                  onChange={(e) => setIsActive(e.target.checked)}
                  disabled={!content.trim()}
                />
              }
              label="Mark as Active"
            />
            {error && (
              <Typography color="error" sx={{ mb: 2 }}>
                {error}
              </Typography>
            )}
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                disabled={loading || !content.trim()}
              >
                Save Notice
              </Button>
            </Box>
            <Typography variant="subtitle1" sx={{ mt: 4 }}>
              Existing Notices
            </Typography>
            {notices.map((notice) => (
              <Box
                key={notice.notice_id}
                sx={{
                  p: 2,
                  border: "1px solid #ddd",
                  borderRadius: 2,
                  mb: 2,
                  backgroundColor: notice.is_active ? "#e0f7fa" : "inherit",
                }}
              >
                <Typography variant="body2">
                  {notice.content.length > 100
                    ? `${notice.content.substring(0, 100)}...`
                    : notice.content}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {notice.is_active ? "Active Notice" : "Inactive Notice"}
                </Typography>
              </Box>
            ))}
          </>
        )}
      </Box>
    </Modal>
  );
};

export default GDPRNoticeModal;
