import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Box,
  Typography,
  Paper,
  Button,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
  Grid,
} from "@mui/material";
import { AddCircleOutline, Person, Logout } from "@mui/icons-material";
import apiClient from "../../utils/requestHandler";
import { formatDate } from "../../utils/helpers";
import PrintPRF from "../../utils/PrintPRF";
import { usePRF } from "../../utils/PRFContext";

const PatientAccessDashboard = () => {
  const {
    prfData,
    setPrfData,
    handleInputChange,
    addObservation,
    handleObservationChange,
    addDefibAction,
    handleDefibActionChange,
    addDrugAction,
    handleDrugActionChange,
  } = usePRF();

  const [prfs, setPrfs] = useState([]);
  const [patientInfo, setPatientInfo] = useState(null);
  const [editPatientInfo, setEditPatientInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [openPRFDialog, setOpenPRFDialog] = useState(false);
  const [openSARDialog, setOpenSARDialog] = useState(false);
  const [openErasureDialog, setOpenErasureDialog] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  // Fetch Patient Data and PRFs on component mount
  useEffect(() => {
    const fetchPatientData = async () => {
      setLoading(true);
      try {
        const userRes = await apiClient.get("/api/auth/user", {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
        const patientId = userRes.data.patientId;
        if (!patientId) throw new Error("Patient ID not found.");

        const patientRes = await apiClient.get(`/api/patient/${patientId}`);
        setPatientInfo(patientRes.data);
        setEditPatientInfo(patientRes.data);

        const prfRes = await apiClient.get(`/api/patient/${patientId}/prfs`);
        setPrfs(prfRes.data);
      } catch (error) {
        console.error("Error fetching patient data:", error);
        setError("Failed to load patient information. Please try again.");
      } finally {
        setLoading(false);
      }
    };
    fetchPatientData();
  }, []);

  const handlePatientInfoChange = (e) => {
    const { name, value } = e.target;
    setEditPatientInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleSavePatientInfo = async () => {
    try {
      await apiClient.put(
        `/api/patient/${patientInfo.patient_id}`,
        editPatientInfo
      );
      setPatientInfo(editPatientInfo);
      alert("Patient information updated successfully.");
    } catch (error) {
      console.error("Error updating patient information:", error);
      alert("Failed to update patient information.");
    }
  };

  const handleViewPRF = (prf) => {
    setPrfData(prf);
    setOpenPRFDialog(true);
  };

  const handleClosePRFDialog = () => {
    setOpenPRFDialog(false);
  };

  const handlePrintPRF = () => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write(
      "<html><head><title>Print PRF</title></head><body>"
    );
    printWindow.document.write(
      document.getElementById("print-content").outerHTML
    );
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
  };

  const handleSARRequest = async () => {
    setOpenSARDialog(false);
    try {
      await apiClient.post("/api/patient/request-sar");
      alert("Your data access request has been submitted.");
    } catch (error) {
      console.error("Error submitting SAR:", error);
      alert("Failed to submit request. Please try again.");
    }
  };

  const handleErasureRequest = async () => {
    setOpenErasureDialog(false);
    try {
      await apiClient.post("/api/patient/request-erasure");
      alert("Your request to delete your data has been submitted.");
    } catch (error) {
      console.error("Error submitting erasure request:", error);
      alert("Failed to submit request. Please try again.");
    }
  };

  const handleDownloadData = async () => {
    try {
      const res = await apiClient.get("/api/patient/download-data", {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `patient_data_${patientInfo.patient_id}.json`
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading data:", error);
      alert("Failed to download data. Please try again.");
    }
  };

  const handleLogout = async () => {
    try {
      await apiClient.post("/api/auth/logout");
      navigate("/login");
    } catch (error) {
      console.error("Error logging out:", error);
      setError("Logout failed. Please try again.");
    }
  };

  return (
    <>
      {/* Fixed Navigation Bar */}
      <AppBar position="fixed" color="primary">
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6" component="div">
            Patient Dashboard
          </Typography>
          <Button color="inherit" onClick={handleLogout} startIcon={<Logout />}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>

      {/* Add padding to main content to prevent overlap with fixed AppBar */}
      <Box sx={{ mt: 10, p: 3 }}>
        {error && <Typography color="error">{error}</Typography>}

      {/* Patient Information */}
      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Patient Information
        </Typography>
        {patientInfo ? (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                value={editPatientInfo.name || ""}
                onChange={handlePatientInfoChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Date of Birth"
                name="dob"
                type="date"
                value={formatDate(editPatientInfo.dob)}
                onChange={handlePatientInfoChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Address"
                name="address"
                value={editPatientInfo.address || ""}
                onChange={handlePatientInfoChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Contact Number"
                name="contact_number"
                value={editPatientInfo.contact_number || ""}
                onChange={handlePatientInfoChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Gender"
                name="gender"
                value={editPatientInfo.gender || ""}
                onChange={handlePatientInfoChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSavePatientInfo}
              >
                Save Changes
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Typography color="textSecondary">
            No patient information available.
          </Typography>
        )}
      </Paper>

      {/* PRFs Section */}
      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Patient Report Forms (PRFs)
        </Typography>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ height: 100 }}
          >
            <CircularProgress />
          </Box>
        ) : prfs.length > 0 ? (
          <List>
            {prfs.map((prf, index) => (
              <ListItem key={index} button onClick={() => handleViewPRF(prf)}>
                <ListItemText
                  primary={`PRF ID: ${prf.incidentDetails.custom_prf_id}`}
                  secondary={`Date: ${formatDate(
                    prf.incidentDetails.incident_date
                  )}`}
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography color="textSecondary">No PRFs available.</Typography>
        )}
      </Paper>

      {/* Data Management Section */}
      <Paper elevation={3} sx={{ p: 3, textAlign: "center" }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Data Management
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 2, mr: 2 }}
          onClick={() => setOpenSARDialog(true)}
        >
          Request Data Access (SAR)
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{ mt: 2, mr: 2 }}
          onClick={handleDownloadData}
        >
          Download My Data
        </Button>
        <Button
          variant="contained"
          color="error"
          sx={{ mt: 2 }}
          onClick={() => setOpenErasureDialog(true)}
        >
          Request Data Deletion
        </Button>
      </Paper>

      {/* PRF Dialog */}
      <Dialog
        open={openPRFDialog}
        onClose={handleClosePRFDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Patient Report Form</DialogTitle>
        <DialogContent dividers>
          <Box id="print-content">
            <PrintPRF prfData={prfData} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePRFDialog} color="primary">
            Close
          </Button>
          <Button onClick={handlePrintPRF} color="primary" variant="contained">
            Print PRF
          </Button>
        </DialogActions>
      </Dialog>

      {/* SAR Request Confirmation Dialog */}
      <Dialog open={openSARDialog} onClose={() => setOpenSARDialog(false)}>
        <DialogTitle>Request Data Access</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to request access to your data?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenSARDialog(false)}>Cancel</Button>
          <Button
            onClick={handleSARRequest}
            color="primary"
            variant="contained"
          >
            Confirm Request
          </Button>
        </DialogActions>
      </Dialog>

      {/* Erasure Request Confirmation Dialog */}
      <Dialog
        open={openErasureDialog}
        onClose={() => setOpenErasureDialog(false)}
      >
        <DialogTitle>Request Data Deletion</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to request deletion of your personal data?
            This action is irreversible.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenErasureDialog(false)}>Cancel</Button>
          <Button
            onClick={handleErasureRequest}
            color="error"
            variant="contained"
          >
            Confirm Deletion Request
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
    </>
  );
};

export default PatientAccessDashboard;
