import React, { useEffect, useState } from "react";
import {
  Box,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Button,
} from "@mui/material";
import { usePRF } from "../../utils/PRFContext";

// Predefined options for disposition/outcome
const dispositionOptions = [
  "Treated and Discharged back to event",
  "Treated and Discharged home",
  "Treated and referred to MIU/UTC/GP",
  "Treated and referred to ED by own means",
  "Treated and referred to ED by taxi",
  "Treated and referred to ED by ambulance",
  "Refusal of care",
  "Treated and refused referral to Alternate Care Pathway",
  "Assistance provided only",
  "Recognition of Life Extinct",
];

// Define template sections
const initialSections = {
  onArrival: "",
  presentingComplaint: "",
  historyOfPresentingComplaint: "",
  onExamination: "",
  treatmentPlan: "",
  adviceGuidance: "",
};

const DispositionOutcome = () => {
  const { prfData, handleInputChange } = usePRF();
  const [clinicalDiscussionSections, setClinicalDiscussionSections] =
    useState(initialSections);

  useEffect(() => {
    // If existing data exists, split it into sections (this assumes a known structure in the stored summary)
    if (prfData.DispositionOutcome.clinical_discussion_summary) {
      const existingSummary =
        prfData.DispositionOutcome.clinical_discussion_summary.split("\n\n");
      setClinicalDiscussionSections({
        onArrival: existingSummary[0] || "",
        presentingComplaint: existingSummary[1] || "",
        historyOfPresentingComplaint: existingSummary[2] || "",
        onExamination: existingSummary[3] || "",
        treatmentPlan: existingSummary[4] || "",
        adviceGuidance: existingSummary[5] || "",
      });
    } else {
      setClinicalDiscussionSections(initialSections);
    }
  }, [prfData.DispositionOutcome.clinical_discussion_summary]);

  const handleSectionChange = (section, value) => {
    setClinicalDiscussionSections((prev) => ({
      ...prev,
      [section]: value,
    }));
  };

  const handleSave = () => {
    // Combine all sections into a single string when saving
    const combinedSummary = `
      On Arrival (O/A): ${clinicalDiscussionSections.onArrival}

      Presenting Complaint (PC): ${clinicalDiscussionSections.presentingComplaint}

      History of Presenting Complaint (HxPc): ${clinicalDiscussionSections.historyOfPresentingComplaint}

      On Examination (O/E): ${clinicalDiscussionSections.onExamination}

      Treatment Plan (T/P): ${clinicalDiscussionSections.treatmentPlan}

      Advice & Guidance (A&G): ${clinicalDiscussionSections.adviceGuidance}
    `.trim();

    // Update the PRF with the combined summary
    handleInputChange(
      "disposition_outcome",
      "clinical_discussion_summary",
      combinedSummary
    );
  };

  return (
    <Box>
      {/* Disposition/Outcome */}
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="disposition-outcome-label">
          Disposition/Outcome
        </InputLabel>
        <Select
          labelId="disposition-outcome-label"
          name="disposition_outcome"
          value={prfData.DispositionOutcome.disposition_outcome || ""}
          onChange={(e) =>
            handleInputChange(
              "disposition_outcome",
              "disposition_outcome",
              e.target.value
            )
          }
        >
          {dispositionOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Time left DC Medical Team Care */}
      <TextField
        label="Time Left DC Medical Team Care"
        name="time_left_dc_care"
        type="time"
        value={prfData.DispositionOutcome.time_left_dc_care || ""}
        onChange={(e) =>
          handleInputChange(
            "disposition_outcome",
            "time_left_dc_care",
            e.target.value
          )
        }
        fullWidth
        sx={{ mb: 2 }}
        InputLabelProps={{ shrink: true }}
      />

      {/* Discharging Clinician */}
      <TextField
        label="Discharging Clinician"
        name="discharging_clinician"
        value={prfData.DispositionOutcome.discharging_clinician || ""}
        onChange={(e) =>
          handleInputChange(
            "disposition_outcome",
            "discharging_clinician",
            e.target.value
          )
        }
        fullWidth
        sx={{ mb: 2 }}
      />

      {/* Clinical Discussion */}
      <TextField
        label="Clinician Consulted Name"
        name="clinician_consulted_name"
        value={prfData.DispositionOutcome.clinician_consulted_name || ""}
        onChange={(e) =>
          handleInputChange(
            "disposition_outcome",
            "clinician_consulted_name",
            e.target.value
          )
        }
        fullWidth
        sx={{ mb: 2 }}
      />
      <TextField
        label="Clinician Consulted Role"
        name="clinician_consulted_role"
        value={prfData.DispositionOutcome.clinician_consulted_role || ""}
        onChange={(e) =>
          handleInputChange(
            "disposition_outcome",
            "clinician_consulted_role",
            e.target.value
          )
        }
        fullWidth
        sx={{ mb: 2 }}
      />

      {/* Clinical Discussion Sections */}
      <TextField
        label="On Arrival (O/A)"
        name="onArrival"
        value={clinicalDiscussionSections.onArrival}
        onChange={(e) => handleSectionChange("onArrival", e.target.value)}
        fullWidth
        multiline
        rows={3}
        sx={{ mb: 2 }}
      />
      <TextField
        label="Presenting Complaint (PC)"
        name="presentingComplaint"
        value={clinicalDiscussionSections.presentingComplaint}
        onChange={(e) =>
          handleSectionChange("presentingComplaint", e.target.value)
        }
        fullWidth
        multiline
        rows={2}
        sx={{ mb: 2 }}
      />
      <TextField
        label="History of Presenting Complaint (HxPc)"
        name="historyOfPresentingComplaint"
        value={clinicalDiscussionSections.historyOfPresentingComplaint}
        onChange={(e) =>
          handleSectionChange("historyOfPresentingComplaint", e.target.value)
        }
        fullWidth
        multiline
        rows={3}
        sx={{ mb: 2 }}
      />
      <TextField
        label="On Examination (O/E)"
        name="onExamination"
        value={clinicalDiscussionSections.onExamination}
        onChange={(e) => handleSectionChange("onExamination", e.target.value)}
        fullWidth
        multiline
        rows={4}
        sx={{ mb: 2 }}
      />
      <TextField
        label="Treatment Plan (T/P)"
        name="treatmentPlan"
        value={clinicalDiscussionSections.treatmentPlan}
        onChange={(e) => handleSectionChange("treatmentPlan", e.target.value)}
        fullWidth
        multiline
        rows={3}
        sx={{ mb: 2 }}
      />
      <TextField
        label="Advice & Guidance (A&G)"
        name="adviceGuidance"
        value={clinicalDiscussionSections.adviceGuidance}
        onChange={(e) => handleSectionChange("adviceGuidance", e.target.value)}
        fullWidth
        multiline
        rows={3}
        sx={{ mb: 2 }}
      />

      {/* Button to save combined Clinical Discussion Summary */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleSave}
        fullWidth
        sx={{ mt: 2 }}
      >
        Save Clinical Discussion Summary
      </Button>
    </Box>
  );
};

export default DispositionOutcome;
