import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CircularProgress,
  Box,
  Typography,
  Grid,
  Paper,
  Button,
  Select,
  MenuItem,
  FormControl,
  Card,
  CardContent,
  Modal,
  List,
  ListItem,
  ListItemText,
  TextField,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import apiClient from "../../utils/requestHandler";
import PatientFlow from "./PatientFlow";
import "./DashboardPage.css"; // Additional CSS for PSL indicators

const modalStyles = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
  backgroundColor: "#fff",
  borderRadius: "10px",
  boxShadow: 24,
  width: "80%",
  maxWidth: 500,
  maxHeight: "80vh",
  overflowY: "auto",
};

function EventDashboard() {
  const { eventID } = useParams(); // Get eventID from URL
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [pslLevel, setPslLevel] = useState(null); // Default PSL Level
  const [updatedAt, setUpdatedAt] = useState(null); // Store PSL updatedAt
  const [openPslModal, setOpenPslModal] = useState(false); // PSL Modal
  const [openCommsModal, setOpenCommsModal] = useState(false); // Generic Comms Modal
  const [logModalOpen, setLogModalOpen] = useState(false); // Log Modal
  const [flowRates, setFlowRates] = useState({
    triage: 0,
    waiting: 0,
    treatment: 0,
    dischargedEvent: 0,
    dischargedUTC: 0,
    dischargedHome: 0,
    awaitingTransport: 0,
    transportedOwnMeans: 0,
    transportedAmbulance: 0,
  });

  const [eventLogs, setEventLogs] = useState([]);
  const [commsLogs, setCommsLogs] = useState([]);
  const [newLog, setNewLog] = useState(""); // For adding new logs
  const [newCommsLog, setNewCommsLog] = useState(""); // For adding new comms logs
  const [logType, setLogType] = useState("Information"); // For comms log type
  const [urgencyLevel, setUrgencyLevel] = useState("Routine"); // For urgency level
  const [source, setSource] = useState("On-site Radio"); // For source of comms log
  const [prfs, setPrfs] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      await fetchUser();
      await fetchPSLLevel();
      await fetchFlowRates();
      await fetchEventLogs();
      await fetchCommsLogs();
      await fetchPRFs(); // Include fetchPRFs here
    };

    const fetchUser = async () => {
      try {
        const res = await apiClient.get("/api/auth/user", {
          withCredentials: true,
        });
        setUser(res.data);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching user data:", err);
        setError("Your session has expired. Please log in again.");
        setLoading(false);
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
    };

    const fetchPSLLevel = async () => {
      try {
        const res = await apiClient.get(`/api/event/${eventID}/psl`, {
          withCredentials: true,
        });
        if (res.data && res.data.level !== undefined) {
          setPslLevel(res.data.level);
          setUpdatedAt(res.data.updatedAt);
        } else {
          setPslLevel(4);
        }
      } catch (err) {
        console.error("Error fetching PSL level:", err);
        setPslLevel(4);
      }
    };

    const fetchFlowRates = async () => {
      try {
        const res = await apiClient.get(`/api/event/${eventID}/flow-rates`, {
          withCredentials: true,
        });
        setFlowRates(res.data.flowRates);
      } catch (err) {
        console.error("Error fetching flow rates:", err);
      }
    };

    const fetchEventLogs = async () => {
      try {
        const res = await apiClient.get(`/api/event/${eventID}/logs`, {
          withCredentials: true,
        });
        setEventLogs(res.data.eventLogs);
      } catch (err) {
        console.error("Error fetching event logs:", err);
        setError("Failed to load event logs.");
      }
    };

    const fetchCommsLogs = async () => {
      try {
        const res = await apiClient.get(`/api/event/${eventID}/commslogs`, {
          withCredentials: true,
        });
        setCommsLogs(res.data.commsLogs);
      } catch (err) {
        console.error("Error fetching comms logs:", err);
        setError("Failed to load comms logs.");
      }
    };

    const fetchPRFs = async () => {
      try {
        const res = await apiClient.get(`/api/event/${eventID}/prfs`, {
          withCredentials: true,
        });
        setPrfs(res.data);
      } catch (err) {
        console.error("Error fetching PRFs:", err);
        setError("Error fetching PRFs.");
      }
    };

    fetchData();

    const intervalId = setInterval(() => {
      fetchData();
    }, 30000);

    return () => clearInterval(intervalId);
  }, [navigate, eventID]);

  const getPSLDetails = (level) => {
    switch (level) {
      case 1:
        return {
          color: "#e60000",
          text: "PSL 1: Critical Overload - Medical Team Overwhelmed",
          details: (
            <ul>
              <li>Maximum deployment of all available medical personnel.</li>
              <li>Mass casualty triage protocols are in place.</li>
              <li>
                Non-critical cases are deferred or referred to external
                healthcare facilities.
              </li>
              <li>
                External support from NHS or event medical providers is
                required.
              </li>
              <li>
                Continuous communication with Event Organisers to assess the
                possibility of event suspension or evacuation.
              </li>
            </ul>
          ),
        };
      case 2:
        return {
          color: "#ff8c00",
          text: "PSL 2: Surge Response - Situation Critical",
          details: (
            <ul>
              <li>Full deployment of all on-site staff.</li>
              <li>
                Command structure activated, with regular updates to Event
                Organisers.
              </li>
              <li>
                Event activities may be slowed down or adjusted to alleviate
                pressure on medical resources.
              </li>
              <li>External resources are on standby for rapid deployment.</li>
              <li>
                Critical care pathways are prioritised; non-urgent cases may be
                delayed.
              </li>
            </ul>
          ),
        };
      case 3:
        return {
          color: "#ffcc00",
          text: "PSL 3: Elevated Patient Flow - Medical Team Handling",
          details: (
            <ul>
              <li>Patient flow is elevated, but manageable.</li>
              <li>Additional staff are on standby.</li>
              <li>
                Triage procedures enhanced; non-urgent cases may be directed to
                designated waiting areas.
              </li>
              <li>
                Frequent communication between medical teams and Event
                Organisers to ensure operational alignment.
              </li>
              <li>
                Backup medical supplies are prepared for rapid deployment.
              </li>
            </ul>
          ),
        };
      case 4:
      default:
        return {
          color: "#32cd32",
          text: "PSL 4: Normal Operations - Everything is OK",
          details: (
            <ul>
              <li>Operations are running smoothly.</li>
              <li>
                Staffing and resources are sufficient for current patient flow.
              </li>
              <li>Standard triage protocols are followed.</li>
              <li>Routine updates are provided to Event Organisers.</li>
              <li>
                Monitoring continues for any emerging risks or deviations from
                the expected patient flow.
              </li>
            </ul>
          ),
        };
    }
  };

  const handlePslChange = async (event) => {
    const newPslLevel = event.target.value;
    const token = localStorage.getItem("token"); // Retrieve token

    try {
      await apiClient.put(
        `/api/event/${eventID}/psl`,
        { newPslLevel },
        { headers: { Authorization: `Bearer ${token}` } } // Pass token in header
      );
      setPslLevel(newPslLevel);
    } catch (err) {
      console.error("Error updating PSL level:", err.message);
    }
  };

  // Modal for adding new logs
  const handleLogModalOpen = () => setLogModalOpen(true);
  const handleLogModalClose = () => setLogModalOpen(false);

  // Modal for PSL details and changing PSL (for admins)
  const handlePslModalOpen = () => setOpenPslModal(true);
  const handlePslModalClose = () => setOpenPslModal(false);

  // Modal for adding new comms logs
  const handleCommsModalOpen = () => setOpenCommsModal(true);
  const handleCommsModalClose = () => setOpenCommsModal(false);

  // Function to handle log submission
  const handleAddLog = async () => {
    try {
      await apiClient.post(`/api/event/${eventID}/logs`, {
        logMessage: newLog,
      });
      setNewLog("");
      setLogModalOpen(false);
      // Fetch updated logs
      const res = await apiClient.get(`/api/event/${eventID}/logs`);
      setEventLogs(res.data.eventLogs);
    } catch (err) {
      console.error("Error adding log:", err.message);
    }
  };

  // Function to handle comms log submission
  const handleAddCommsLog = async () => {
    try {
      await apiClient.post(`/api/event/${eventID}/commslogs`, {
        logMessage: newCommsLog,
        priority: logType === "Priority Radio Call",
        logType,
        urgencyLevel,
        source,
      });
      setNewCommsLog("");
      setLogType("Information");
      setUrgencyLevel("Routine");
      setSource("On-site Radio");
      setOpenCommsModal(false);
      // Fetch updated logs
      const res = await apiClient.get(`/api/event/${eventID}/logs`);
      setCommsLogs(res.data.priorityCommsLogs);
    } catch (err) {
      console.error("Error adding comms log:", err.message);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ textAlign: "center", marginTop: "50px" }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  if (!user) {
    return null;
  }

  // Helper function to get the color based on urgency level
  const getRowColor = (urgencyLevel) => {
    switch (urgencyLevel) {
      case "Critical":
        return "#ffcccc"; // Light red for critical logs
      case "Urgent":
        return "#fff3cd"; // Light yellow for urgent logs
      case "Routine":
      default:
        return "#e6ffe6"; // Light green for routine logs
    }
  };
  // Conditionally calculate `pslDetails` only if `pslLevel` is not null
  const pslDetails = pslLevel !== null ? getPSLDetails(pslLevel) : null;

  return (
    <Box sx={{ p: 4, backgroundColor: "#f5f5f5", minHeight: "100vh" }}>
      {pslDetails && (
        <Box
          className="psl-banner"
          sx={{ backgroundColor: pslDetails.color, borderRadius: "10px", p: 2 }}
          onClick={handlePslModalOpen} // Open PSL modal
          style={{ cursor: "pointer" }}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold", color: "#fff" }}>
            {pslDetails.text}
          </Typography>
          <Typography variant="body2" sx={{ color: "#fff", marginTop: "5px" }}>
            Last updated at:{" "}
            {updatedAt ? new Date(updatedAt).toLocaleString() : "N/A"}
          </Typography>
          <Typography variant="body2" sx={{ color: "#fff", marginTop: "5px" }}>
            Click for details
          </Typography>
        </Box>
      )}

      <Modal open={openPslModal} onClose={handlePslModalClose}>
        <Box sx={modalStyles}>
          {pslDetails && ( // Ensure pslDetails is defined before using it
            <>
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", color: pslDetails.color }}
              >
                {pslDetails.text}
              </Typography>
              <Typography sx={{ mt: 2 }}>{pslDetails.details}</Typography>
            </>
          )}
          {/* Allow PSL change only if user is an admin */}
          {user?.role === "admin" && (
            <FormControl fullWidth sx={{ mt: 2 }}>
              <Select
                value={pslLevel !== null ? pslLevel : 4} // Ensure a value is always set for the select
                onChange={handlePslChange}
                label="PSL Level"
              >
                <MenuItem value={1}>PSL 1: Critical Overload</MenuItem>
                <MenuItem value={2}>PSL 2: Surge Response</MenuItem>
                <MenuItem value={3}>PSL 3: Elevated Flow</MenuItem>
                <MenuItem value={4}>PSL 4: Normal Operations</MenuItem>
              </Select>
            </FormControl>
          )}
          <Button onClick={handlePslModalClose} sx={{ mt: 2 }}>
            Close
          </Button>
        </Box>
      </Modal>

      {/* Main Dashboard Content */}
      <Typography
        variant="h4"
        sx={{ mb: 3, textAlign: "center", fontWeight: "bold" }}
      >
        Event Dashboard for {eventID}
      </Typography>
      <Grid container spacing={4} justifyContent="center" sx={{ mb: 5 }}>
        <Grid item xs={12} sm={6} lg={4}>
          <Card sx={{ boxShadow: 3, borderRadius: 2, padding: 2 }}>
            <CardContent>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Total Patients Not Discharged
              </Typography>
              <Typography variant="h3" color="primary" sx={{ mt: 2 }}>
                {flowRates.triage + flowRates.waiting + flowRates.treatment}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <Card sx={{ boxShadow: 3, borderRadius: 2, padding: 2 }}>
            <CardContent>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Total Patients Waiting
              </Typography>
              <Typography variant="h3" color="error" sx={{ mt: 2 }}>
                {flowRates.waiting}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <Card sx={{ boxShadow: 3, borderRadius: 2, padding: 2 }}>
            <CardContent>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Total Patients Discharged
              </Typography>
              <Typography variant="h3" color="success" sx={{ mt: 2 }}>
                {flowRates.dischargedEvent +
                  flowRates.dischargedUTC +
                  flowRates.dischargedHome}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {/* Flow Diagram */}
      <Paper
        elevation={3}
        sx={{
          padding: { xs: 2, md: 4 },
          backgroundColor: "#ffffff",
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" sx={{ mb: 3, fontWeight: "bold" }}>
          Patient Flow
        </Typography>
        <PatientFlow flowRates={flowRates} user={user} />
      </Paper>

      {/* PRF List Section */}
      <Typography variant="h4" sx={{ mt: 5, mb: 2 }}>
        PRFs for Event
      </Typography>
      <Paper elevation={3} sx={{ padding: { xs: 2, md: 4 }, mb: 5 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>PRF ID</strong>
                </TableCell>
                <TableCell>
                  <strong>Incident Date</strong>
                </TableCell>
                <TableCell>
                  <strong>Chief Complaint</strong>
                </TableCell>
                <TableCell>
                  <strong>Patient Name</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {prfs.length > 0 ? (
                prfs.map((prf) => (
                  <TableRow key={prf.custom_prf_id}>
                    <TableCell>{prf.custom_prf_id}</TableCell>
                    <TableCell>
                      {new Date(prf.incident_date).toLocaleString()}
                    </TableCell>
                    <TableCell>{prf.chief_complaint}</TableCell>
                    <TableCell>{prf.patient?.name || "N/A"}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          window.open(`/prf/${prf.custom_prf_id}`, "_blank")
                        }
                      >
                        View PRF
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5}>
                    No PRFs available for this event.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {/* Logs Section - Event Log and Priority Communications side by side */}
      <Grid container spacing={4} justifyContent="center" sx={{ mt: 5, mb: 5 }}>
        {/* Event Logs Table */}
        <Grid item xs={12} md={6}>
          <Typography variant="h4" sx={{ mb: 2 }}>
            Event Log
          </Typography>
          <Paper elevation={3} sx={{ padding: { xs: 2, md: 4 }, mb: 5 }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>Message</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Timestamp</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Type</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Urgency Level</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Source</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {eventLogs
                    .sort(
                      (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
                    )
                    .map((log) => (
                      <TableRow
                        key={log.id}
                        sx={{ backgroundColor: getRowColor(log.urgencyLevel) }}
                      >
                        <TableCell>{log.logMessage}</TableCell>
                        <TableCell>
                          {new Date(log.timestamp).toLocaleString()}
                        </TableCell>
                        <TableCell>{log.logType || "N/A"}</TableCell>
                        <TableCell>{log.urgencyLevel || "N/A"}</TableCell>
                        <TableCell>{log.source || "N/A"}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>

        {/* Priority Communications Table */}
        <Grid item xs={12} md={6}>
          <Typography variant="h4" sx={{ mb: 2 }}>
            Priority Communications
          </Typography>
          <Paper elevation={3} sx={{ padding: { xs: 2, md: 4 } }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>Message</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Timestamp</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Type</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Urgency Level</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Source</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {commsLogs
                    .sort(
                      (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
                    )
                    .map((log) => (
                      <TableRow
                        key={log.id}
                        sx={{ backgroundColor: getRowColor(log.urgencyLevel) }}
                      >
                        <TableCell>{log.logMessage}</TableCell>
                        <TableCell>
                          {new Date(log.timestamp).toLocaleString()}
                        </TableCell>
                        <TableCell>{log.logType}</TableCell>
                        <TableCell>{log.urgencyLevel}</TableCell>
                        <TableCell>{log.source}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          {/* Button to open the comms log modal */}
          <Button
            onClick={() => setOpenCommsModal(true)}
            variant="contained"
            sx={{ mt: 4 }}
          >
            Add Comms Log
          </Button>
        </Grid>
      </Grid>

      {/* Modal for adding logs */}
      <Modal open={logModalOpen} onClose={handleLogModalClose}>
        <Box sx={modalStyles}>
          <Typography variant="h6">Add New Log</Typography>
          <TextField
            value={newLog}
            onChange={(e) => setNewLog(e.target.value)}
            fullWidth
            label="Event Log Message"
            sx={{ mt: 2 }}
          />
          <Button onClick={handleAddLog} variant="contained" sx={{ mt: 2 }}>
            Submit Log
          </Button>
        </Box>
      </Modal>

      {/* Modal for adding generic comms logs */}
      <Modal open={openCommsModal} onClose={handleCommsModalClose}>
        <Box sx={modalStyles}>
          <Typography variant="h6">Add Comms Log</Typography>
          <TextField
            value={newCommsLog}
            onChange={(e) => setNewCommsLog(e.target.value)}
            fullWidth
            label="Comms Log Message"
            sx={{ mt: 2 }}
            autoFocus // Autofocus on opening
          />
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Log Type</InputLabel>
            <Select
              value={logType}
              onChange={(e) => setLogType(e.target.value)}
            >
              <MenuItem value="Information">Information</MenuItem>
              <MenuItem value="Priority Radio Call">
                Priority Radio Call
              </MenuItem>
              <MenuItem value="Instruction">Instruction</MenuItem>
              <MenuItem value="Request for Assistance">
                Request for Assistance
              </MenuItem>
              <MenuItem value="Resource Allocation">
                Resource Allocation
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Urgency Level</InputLabel>
            <Select
              value={urgencyLevel}
              onChange={(e) => setUrgencyLevel(e.target.value)}
            >
              <MenuItem value="Routine">Routine</MenuItem>
              <MenuItem value="Urgent">Urgent</MenuItem>
              <MenuItem value="Critical">Critical</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Source</InputLabel>
            <Select value={source} onChange={(e) => setSource(e.target.value)}>
              <MenuItem value="On-site Radio">On-site Radio</MenuItem>
              <MenuItem value="Control Room">Control Room</MenuItem>
              <MenuItem value="External Agency">External Agency</MenuItem>
              <MenuItem value="Ambulance Crew">Ambulance Crew</MenuItem>
            </Select>
          </FormControl>
          <Button
            onClick={handleAddCommsLog}
            variant="contained"
            sx={{ mt: 2 }}
          >
            Submit Comms Log
          </Button>
        </Box>
      </Modal>
    </Box>
  );
}

export default EventDashboard;
