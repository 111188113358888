import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Avatar,
  Alert,
  CircularProgress,
  Paper,
  Grid,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import apiClient from "../utils/requestHandler";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [accessType, setAccessType] = useState(null);
  const navigate = useNavigate();

  const isEmailValid = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const isFormValid = () => email && password && isEmailValid(email);

  const handleAccessTypeChange = (event, newAccessType) => {
    setAccessType(newAccessType);
    setEmail("");
    setPassword("");
    setError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    if (!isFormValid()) {
      setError("Please enter a valid email and password");
      setLoading(false);
      return;
    }

    try {
      const res = await apiClient.post("/api/auth/login", { email, password });

      if (res.status === 200) {
        const userRes = await apiClient.get("/api/auth/user", {
          withCredentials: true,
        });
        const { role } = userRes.data;

        if (role === "admin" || role === "staff") {
          navigate("/dashboard", { replace: true });
        } else if (role === "patient") {
          navigate("/patient-access", { replace: true });
        } else {
          setError("Unauthorized role. Please contact support.");
        }
      } else {
        setError("Login failed. Please try again.");
      }
    } catch (err) {
      setError(err.response?.data?.msg || "Invalid email or password");
    } finally {
      setLoading(false);
    }
  };

  const handleSignUpClick = () => {
    navigate("/patient-register");
  };

  return (
    <Container component="main" maxWidth="sm">
      <Paper elevation={6} sx={{ padding: 4, mt: 8, borderRadius: 2 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
            Select Access Type
          </Typography>

          <ToggleButtonGroup
            value={accessType}
            exclusive
            onChange={handleAccessTypeChange}
            sx={{ mb: 4 }}
          >
            <ToggleButton value="staff" sx={{ px: 4 }}>
              Staff Access
            </ToggleButton>
            <ToggleButton value="patient" sx={{ px: 4 }}>
              Patient Access
            </ToggleButton>
          </ToggleButtonGroup>

          {accessType && (
            <>
              <Typography component="h2" variant="h6" sx={{ mb: 3 }}>
                {accessType === "staff" ? "Staff Login" : "Patient Login"}
              </Typography>

              {error && (
                <Alert severity="error" sx={{ width: "100%", mb: 2 }}>
                  {error}
                </Alert>
              )}

              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1, width: "100%" }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={!!error && !isEmailValid(email)}
                  helperText={
                    !isEmailValid(email) && error
                      ? "Please enter a valid email"
                      : ""
                  }
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  error={!!error && !password}
                  helperText={
                    !!error && !password ? "Password is required" : ""
                  }
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={!isFormValid() || loading || !accessType}
                  sx={{
                    py: 1.5,
                    mt: 2,
                    mb: 3,
                    boxShadow: "none",
                    ":hover": {
                      boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                    },
                  }}
                >
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Login"
                  )}
                </Button>

                {accessType === "patient" && (
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Button
                        type="button"
                        variant="text"
                        onClick={() =>
                          alert("Forgot password functionality coming soon.")
                        }
                      >
                        Forgot password?
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        type="button"
                        variant="text"
                        color="primary"
                        onClick={handleSignUpClick}
                      >
                        Don't have an account? Sign Up
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </>
          )}
        </Box>
      </Paper>
    </Container>
  );
};

export default LoginPage;
