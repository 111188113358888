// src/components/PrivateRoute.js
import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { useLoading } from "../contexts/LoadingContext";

const PrivateRoute = ({ children }) => {
  const { user, userLoaded } = useAuth();
  const { isLoading } = useLoading();

  if (isLoading || !userLoaded) {
    return null; // or a loading indicator
  }

  return user ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
