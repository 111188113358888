import React from "react";
import { Box, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { usePRF } from "../../utils/PRFContext";

const ClinicalImpression = () => {
  const { prfData, handleInputChange } = usePRF();

  // Array of clinical impressions to populate the dropdown
  const clinicalImpressions = [
    "Abdominal Pain",
    "Allergic Reaction (Mild/Moderate/Severe)",
    "Anaphylaxis",
    "Asthma Exacerbation",
    "Cardiac Arrest (Non-Traumatic)",
    "Cardiac Arrhythmia (e.g., AF, SVT)",
    "Chest Pain (Non-Traumatic)",
    "Chronic Obstructive Pulmonary Disease (COPD) Exacerbation",
    "Dehydration",
    "Diabetic Hyperglycemia",
    "Diabetic Hypoglycemia",
    "Epileptic Seizure",
    "Febrile Illness",
    "Gastroenteritis",
    "Heat Exhaustion",
    "Heat Stroke",
    "Hypertension",
    "Hypothermia",
    "Infectious Disease (e.g., COVID-19, Flu, etc.)",
    "Mental Health Crisis (Anxiety, Depression)",
    "Myocardial Infarction (Heart Attack)",
    "Nausea/Vomiting",
    "Overdose (Drugs/Medications)",
    "Poisoning (Intentional/Unintentional)",
    "Pneumonia",
    "Pulmonary Embolism",
    "Respiratory Failure",
    "Sepsis/Septic Shock",
    "Stroke (CVA)",
    "Syncope (Fainting)",
    "Urinary Tract Infection",
    "Viral Illness",
    "Wheezing/Shortness of Breath (Non-Traumatic)",
    "Abrasion/Laceration",
    "Amputation (Partial/Complete)",
    "Animal Bite",
    "Burn (Chemical/Electrical/Thermal)",
    "Concussion (Mild/Severe)",
    "Contusion/Bruising",
    "Dislocation (Shoulder, Knee, etc.)",
    "Electrical Injury",
    "Eye Injury (Penetrating/Blunt)",
    "Facial Injury",
    "Fracture (Open/Closed)",
    "Head Injury (Mild/Severe)",
    "Internal Bleeding (Suspected)",
    "Limb Crush Injury",
    "Neck/Back Injury (Suspected Spinal)",
    "Penetrating Injury (Stabbing/GSW)",
    "Pulmonary Contusion",
    "Severe Hemorrhage (External/Internal)",
    "Soft Tissue Injury",
    "Splenic Injury",
    "Sprain/Strain",
    "Subdural Hematoma",
    "Traumatic Cardiac Arrest",
    "Traumatic Brain Injury",
    "Traumatic Pneumothorax (Open/Tension)",
    "Sporting Injury/Illness Clinical Impressions",
    "Achilles Tendon Rupture",
    "Anterior Cruciate Ligament (ACL) Injury",
    "Dehydration (Sports-Related)",
    "Exertional Heat Illness",
    "Exertional Rhabdomyolysis",
    "Fracture (Sports-Related)",
    "Groin Strain",
    "Hamstring Injury",
    "Head Injury (Sports-Related)",
    "Hyperextension Injury (Knee/Elbow)",
    "Ligament Sprain (Ankle/Knee/Shoulder)",
    "Muscle Cramps (Exertional)",
    "Muscle Strain",
    "Patellar Dislocation",
    "Quadriceps Contusion",
    "Rotator Cuff Injury",
    "Shin Splints",
    "Stress Fracture (Tibia, Foot, etc.)",
    "Tennis Elbow (Lateral Epicondylitis)",
    "Tibial Stress Syndrome",
    "Turf Toe",
    "Wrist Sprain/Fracture (Sports-Related)",
    "Environmental/External Factors",
    "Altitude Sickness",
    "Cold Exposure (Hypothermia/Frostbite)",
    "Heat-Related Illness (Heat Stroke, Heat Exhaustion)",
    "Lightning Strike",
    "Submersion Injury/Drowning",
    "Sunburn",
    "Toxic Gas Inhalation (e.g., Carbon Monoxide)",
    "Venomous Bite/Sting (Insect, Snake, Marine)",
    "Alcohol Intoxication",
    "Drug Intoxication",
    "Foreign Body (Eye/Ear/Nose/Throat)",
    "Pregnancy-Related Condition (e.g., Labour, Preeclampsia)",
    "Psychiatric/Behavioral Emergency",
    "Severe Anxiety Attack",
    "Sexual Assault",
    "Shock (Hypovolemic, Septic, Cardiogenic)",
  ];

  return (
    <Box>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="clinical-impression-label">
          Clinical Impression
        </InputLabel>
        <Select
          labelId="clinical-impression-label"
          name="clinical_impression"
          value={prfData.clinical_impression || ""}
          onChange={(e) =>
            handleInputChange("clinical_impression", e.target.value)
          }
        >
          {clinicalImpressions.map((impression, index) => (
            <MenuItem key={index} value={impression}>
              {impression}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default ClinicalImpression;
