import React from "react";
import {
  Box,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  RadioGroup,
  Radio,
  FormControlLabel,
  Checkbox,
  ListItemText,
  OutlinedInput,
  TextField,
  FormLabel,
  Typography,
} from "@mui/material";
import { usePRF } from "../../utils/PRFContext"; // Import the PRFContext

// Options for multiple-choice fields
const cervicalSpineOptions = [
  "Normal",
  "Deformity",
  "Tenderness",
  "Immobile neck",
  "Unable to assess",
];

const airwayOptions = [
  "Normal",
  "Gurgling",
  "Burns",
  "Partial obstruction",
  "Complete obstruction",
  "Oedema",
  "Vomitus",
  "Snoring",
  "Stridor",
  "Trachy or lary blocked",
];

const breathingOptions = [
  "Normal",
  "Reduced chest movement L/R",
  "Surgical Emphysema L/R",
  "Open Chest Wound L/R",
  "Tracheal Deviation L/R",
  "Flail Chest L/R",
  "Rate >30",
  "Rate <10",
  "Absent",
  "Noisy Breathing",
];

const circulationOptions = [
  "Pulse normal",
  "Absent",
  "Rapid",
  "Slow",
  "Weak",
  "Strong",
  "Normal",
  "Regular",
  "Irregular",
  "Complexion Pale",
  "Cyanosed",
  "Flushed",
  "Hypostasis",
  "Normal",
  "Cap Refill <2s",
  "Cap Refill >2s",
];

// Capacity assessment options
const understandingOptions = [
  "Understands fully",
  "Partially understands",
  "Does not understand",
];

const retainingOptions = [
  "Can retain information",
  "Cannot retain information",
];

const weighingOptions = [
  "Can weigh the information to make a decision",
  "Cannot weigh the information to make a decision",
];

const communicatingOptions = [
  "Can communicate their decision",
  "Cannot communicate their decision",
];

const involvementOptions = [
  "Yes, family/carers involved",
  "No, family/carers not involved",
  "Not applicable",
];

const PrimarySurvey = () => {
  const { prfData, handleInputChange } = usePRF(); // Access context data

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h5" gutterBottom>
        Primary Survey
      </Typography>

      {/* Consent */}
      <Box
        sx={{ mb: 3, border: "1px solid #ddd", padding: 2, borderRadius: 1 }}
      >
        <FormLabel component="legend" sx={{ mb: 1 }}>
          Patient Consent
        </FormLabel>
        <RadioGroup
          name="consent"
          value={prfData.PrimarySurvey?.consent || ""}
          onChange={(e) =>
            handleInputChange("PrimarySurvey", e.target.name, e.target.value)
          }
          row
        >
          <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="No" control={<Radio />} label="No" />
        </RadioGroup>
      </Box>

      {/* Capacity assessment if consent is NO */}
      {prfData.PrimarySurvey?.consent === "No" && (
        <Box
          sx={{ mb: 3, border: "1px solid #ddd", padding: 2, borderRadius: 1 }}
        >
          <Typography variant="subtitle1" gutterBottom>
            Capacity Assessment
          </Typography>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="understanding-label">Understanding</InputLabel>
            <Select
              labelId="understanding-label"
              name="understanding"
              value={prfData.PrimarySurvey?.understanding || ""}
              onChange={(e) =>
                handleInputChange(
                  "PrimarySurvey",
                  e.target.name,
                  e.target.value
                )
              }
            >
              {understandingOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="retaining-label">Retaining</InputLabel>
            <Select
              labelId="retaining-label"
              name="retaining"
              value={prfData.PrimarySurvey?.retaining || ""}
              onChange={(e) =>
                handleInputChange(
                  "PrimarySurvey",
                  e.target.name,
                  e.target.value
                )
              }
            >
              {retainingOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="weighing-label">Weighing</InputLabel>
            <Select
              labelId="weighing-label"
              name="weighing"
              value={prfData.PrimarySurvey?.weighing || ""}
              onChange={(e) =>
                handleInputChange(
                  "PrimarySurvey",
                  e.target.name,
                  e.target.value
                )
              }
            >
              {weighingOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="communicating-label">Communicating</InputLabel>
            <Select
              labelId="communicating-label"
              name="communicating"
              value={prfData.PrimarySurvey?.communicating || ""}
              onChange={(e) =>
                handleInputChange(
                  "PrimarySurvey",
                  e.target.name,
                  e.target.value
                )
              }
            >
              {communicatingOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormLabel component="legend" sx={{ mb: 1 }}>
            Capacity Conclusion
          </FormLabel>
          <RadioGroup
            name="capacity_conclusion"
            value={prfData.PrimarySurvey?.capacity_conclusion || ""}
            onChange={(e) =>
              handleInputChange("PrimarySurvey", e.target.name, e.target.value)
            }
            row
          >
            <FormControlLabel
              value="Has capacity"
              control={<Radio />}
              label="Has capacity"
            />
            <FormControlLabel
              value="Lacks capacity"
              control={<Radio />}
              label="Lacks capacity"
            />
          </RadioGroup>

          {/* Best Interests (if lacks capacity) */}
          {prfData.PrimarySurvey?.capacity_conclusion === "Lacks capacity" && (
            <Box sx={{ mt: 2 }}>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="involvement-label">
                  Involvement of Family/Carers
                </InputLabel>
                <Select
                  labelId="involvement-label"
                  name="involvement"
                  value={prfData.PrimarySurvey?.involvement || ""}
                  onChange={(e) =>
                    handleInputChange(
                      "PrimarySurvey",
                      e.target.name,
                      e.target.value
                    )
                  }
                >
                  {involvementOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                label="Wishes, Feelings, Beliefs, and Values"
                name="wishes_feelings"
                value={prfData.PrimarySurvey?.wishes_feelings || ""}
                onChange={(e) =>
                  handleInputChange(
                    "PrimarySurvey",
                    e.target.name,
                    e.target.value
                  )
                }
                fullWidth
                multiline
                rows={3}
                sx={{ mb: 2 }}
              />

              <TextField
                label="Does the Patient Understand the Consequences?"
                name="understand_consequences"
                value={prfData.PrimarySurvey?.understand_consequences || ""}
                onChange={(e) =>
                  handleInputChange(
                    "PrimarySurvey",
                    e.target.name,
                    e.target.value
                  )
                }
                fullWidth
                multiline
                rows={2}
                sx={{ mb: 2 }}
              />

              <TextField
                label="Is this the Least Restrictive Option?"
                name="least_restrictive_option"
                value={prfData.PrimarySurvey?.least_restrictive_option || ""}
                onChange={(e) =>
                  handleInputChange(
                    "PrimarySurvey",
                    e.target.name,
                    e.target.value
                  )
                }
                fullWidth
                multiline
                rows={2}
                sx={{ mb: 2 }}
              />
            </Box>
          )}
        </Box>
      )}

      {/* Catastrophic haemorrhage */}
      <Box
        sx={{ mb: 3, border: "1px solid #ddd", padding: 2, borderRadius: 1 }}
      >
        <FormLabel component="legend" sx={{ mb: 1 }}>
          Catastrophic Haemorrhage
        </FormLabel>
        <RadioGroup
          name="catastrophic_haemorrhage"
          value={prfData.PrimarySurvey?.catastrophic_haemorrhage || ""}
          onChange={(e) =>
            handleInputChange("PrimarySurvey", e.target.name, e.target.value)
          }
          row
        >
          <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="No" control={<Radio />} label="No" />
        </RadioGroup>
      </Box>

      {/* Cervical Spine */}
      <Box
        sx={{ mb: 3, border: "1px solid #ddd", padding: 2, borderRadius: 1 }}
      >
        <FormControl fullWidth>
          <InputLabel id="cervical-spine-label">Cervical Spine</InputLabel>
          <Select
            labelId="cervical-spine-label"
            name="cervical_spine"
            value={prfData.PrimarySurvey?.cervical_spine || ""}
            onChange={(e) =>
              handleInputChange("PrimarySurvey", e.target.name, e.target.value)
            }
          >
            {cervicalSpineOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* Airway */}
      <Box
        sx={{ mb: 3, border: "1px solid #ddd", padding: 2, borderRadius: 1 }}
      >
        <FormControl fullWidth>
          <InputLabel id="airway-label">Airway</InputLabel>
          <Select
            labelId="airway-label"
            name="airway"
            value={prfData.PrimarySurvey?.airway || ""}
            onChange={(e) =>
              handleInputChange("PrimarySurvey", e.target.name, e.target.value)
            }
          >
            {airwayOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* Breathing */}
      <Box
        sx={{ mb: 3, border: "1px solid #ddd", padding: 2, borderRadius: 1 }}
      >
        <FormControl fullWidth>
          <InputLabel id="breathing-label">Breathing</InputLabel>
          <Select
            labelId="breathing-label"
            multiple
            name="breathing"
            value={prfData.PrimarySurvey?.breathing || []}
            onChange={(e) =>
              handleInputChange("PrimarySurvey", e.target.name, e.target.value)
            }
            input={<OutlinedInput label="Breathing" />}
            renderValue={(selected) => selected.join(", ")}
          >
            {breathingOptions.map((option) => (
              <MenuItem key={option} value={option}>
                <Checkbox
                  checked={
                    prfData.PrimarySurvey?.breathing?.indexOf(option) > -1
                  }
                />
                <ListItemText primary={option} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* Circulation */}
      <Box
        sx={{ mb: 3, border: "1px solid #ddd", padding: 2, borderRadius: 1 }}
      >
        <FormControl fullWidth>
          <InputLabel id="circulation-label">Circulation</InputLabel>
          <Select
            labelId="circulation-label"
            multiple
            name="circulation"
            value={prfData.PrimarySurvey?.circulation || []}
            onChange={(e) =>
              handleInputChange("PrimarySurvey", e.target.name, e.target.value)
            }
            input={<OutlinedInput label="Circulation" />}
            renderValue={(selected) => selected.join(", ")}
          >
            {circulationOptions.map((option) => (
              <MenuItem key={option} value={option}>
                <Checkbox
                  checked={
                    prfData.PrimarySurvey?.circulation?.indexOf(option) > -1
                  }
                />
                <ListItemText primary={option} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default PrimarySurvey;
