// auditLogger.js (New File)
import apiClient from "./requestHandler";
import { storeEncryptedData, retrieveDecryptedData } from "./sessionHandler";

const formatDateTimeForSQL = (date) => {
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const dd = String(date.getDate()).padStart(2, "0");
  const hh = String(date.getHours()).padStart(2, "0");
  const min = String(date.getMinutes()).padStart(2, "0");
  const ss = String(date.getSeconds()).padStart(2, "0");
  return `${yyyy}-${mm}-${dd} ${hh}:${min}:${ss}`;
};

export const logAuditEntry = async (
  user_id,
  prf_id,
  action,
  changedFields = {}
) => {
  try {
    const date_time = formatDateTimeForSQL(new Date()); // Call the formatting function

    console.log(`[logAuditEntry] Date and Time: ${date_time}`);

    const logEntry = {
      user_id,
      prf_id,
      action_type: action,
      date_time: date_time, // Use the formatted date without milliseconds and timezone
      changed_fields: JSON.stringify(changedFields),
      status: "pending",
      location: navigator.onLine ? "Online" : "Offline",
    };

    // Store in session storage for offline logs
    let auditLogs = JSON.parse(retrieveDecryptedData("auditLogs")) || [];
    auditLogs.push(logEntry);
    storeEncryptedData("auditLogs", JSON.stringify(auditLogs));

    console.log(`[logAuditEntry] Audit log entry saved to sessionStorage.`);

    // If online, send to backend
    if (navigator.onLine) {
      await sendAuditLogToServer(logEntry);
    }
  } catch (error) {
    console.error(`[logAuditEntry] Error while logging audit entry: ${error}`);
  }
};

// Function to send log to the server
const sendAuditLogToServer = async (logEntry) => {
  try {
    console.log(
      `[sendAuditLogToServer] Sending log entry to server:`,
      logEntry
    );

    const response = await apiClient.post("/api/audit-log", logEntry);

    if (response.status === 201) {
      // Mark log as synced if the response is 201 (created)
      console.log(
        `[sendAuditLogToServer] Audit log successfully synced with the server.`
      );
      markLogAsSynced(logEntry);
    } else {
      console.warn(
        `[sendAuditLogToServer] Unexpected response status: ${response.status}`
      );
    }
  } catch (err) {
    console.error(`[sendAuditLogToServer] Failed to sync audit log`, err);
  }
};

// Mark audit log as synced in session storage
const markLogAsSynced = (logEntry) => {
  try {
    console.log(`[markLogAsSynced] Marking log as synced:`, logEntry);

    // Retrieve and decrypt the audit logs from session storage
    let auditLogs = JSON.parse(retrieveDecryptedData("auditLogs")) || [];

    if (!Array.isArray(auditLogs)) {
      throw new Error("auditLogs is not an array");
    }

    // Update the specific log entry to mark it as "synced"
    auditLogs = auditLogs.map((log) =>
      log.prf_id === logEntry.prf_id && log.date_time === logEntry.date_time
        ? { ...log, status: "synced" }
        : log
    );

    // Encrypt and store the updated audit logs back in session storage
    storeEncryptedData("auditLogs", JSON.stringify(auditLogs));

    console.log(
      `[markLogAsSynced] Updated audit logs in sessionStorage:`,
      auditLogs
    );
  } catch (error) {
    console.error(
      `[markLogAsSynced] Error while marking log as synced: ${error}`
    );
  }
};

// Sync offline logs when coming back online
export const syncOfflineLogs = async () => {
  try {
    console.log(`[syncOfflineLogs] Checking for pending logs...`);

    const offlineLogs = JSON.parse(retrieveDecryptedData("auditLogs")) || [];
    const pendingLogs = offlineLogs.filter((log) => log.status === "pending");

    console.log(
      `[syncOfflineLogs] Found ${pendingLogs.length} pending logs to sync.`
    );

    for (const log of pendingLogs) {
      console.log(`[syncOfflineLogs] Syncing log:`, log);
      await sendAuditLogToServer(log);
    }
  } catch (error) {
    console.error(
      `[syncOfflineLogs] Error while syncing offline logs: ${error}`
    );
  }
};
