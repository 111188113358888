import React, { useState } from "react";
import {
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Checkbox,
  ListItemText,
  OutlinedInput,
} from "@mui/material";

const prefilledTexts = {
  respiratory:
    "No shortness of breath at rest. No shortness of breath on exertion. No difficulty in breathing, no accessory muscle use. No cough. No audible wheeze. Equal bilateral air entry. No crackles. No consolidation. No pleuritic chest pain. No COVID concerns. No cyanosis. Saturating well on RA.",
  cardiac:
    "No cardiac chest pain. No non-cardiac chest pain. No radiating pain to arm, neck, jaw or shoulder. No palpitations. No dyspnea. No dizziness. No syncope. No orthopnea. No paroxysmal nocturnal dyspnea. No nausea. No diaphoresis. Well perfused. No peripheral oedema. ECG NSR: No ST changes. No T wave changes. No QT changes. No arrhythmias.",
  neurological:
    "No current headache, no new, severe or worsening headaches. No altered mental status, no confusion, shaking, disorientation, or memory loss. No dizziness or vertigo, no loss of balance. No seizure, no convulsions, shaking, or loss of consciousness. No loss of consciousness. FAST-VE. AVVV-VE. DANISH-VE. No vomiting. No focal neurological deficit, no weakness, no numbness, no tingling. No vision changes, no blurred vision, double vision or loss of vision. No confusion. Alert and oriented.",
  gastrointestinal:
    "No abdominal pain. No nausea or vomiting. No diarrhoea. No constipation. No haematemesis. No malaena. No polydipsia. No weight loss. Appetite normal for patient. No distension. No rigidity. No guarding. No rebound tenderness.",
  genitourinary:
    "No dysuria. No haematuria. No urinary frequency. No urgency. No flank pain. No vaginal or penile discharge. No pelvic pain. No polyuria.",
  integumentary:
    "No rashes. No lesions. No itching. No bruising. No swelling. No changes in colour. No petechia. No lymphadenopathy. No signs of jaundice.",
  general:
    "No fever. No chills. No Malaise. No bleeding disorders. No recent infections. No recent illness. No recent surgeries.",
  head_neck:
    "No loss of consciousness. No head pain. No scalp lacerations. No skull deformity. No facial injuries. No eye injuries. No epistaxis. No ear bleeding or discharge. No neck pain. No tracheal deviation. No jugular vein distension. No altered neurology. No back pain. No stepping. No loss of sensation. No motor deficits. No urinary or faecal incontinence. No pupil abnormality. No seizures. No loss of balance or coordination. No altered mental status.",
  chest:
    "No chest pain. No shortness of breath. No visible deformity. No obvious rib fractures. No open chest wounds. No paradoxical chest movement. No subcutaneous emphysema. No bruising. No signs of hypoxia. Equal bilateral air entry.",
  abdomen:
    "No abdominal pain. No distension. No rigidity. No guarding. No rebound tenderness. No visible injuries. No bruising. No lacerations. No wounds.",
  pelvis:
    "No pelvic pain. No instability. No deformity. No bleeding. No signs of hip dislocations or fractures.",
  extremities:
    "No pain or tenderness. No deformities. No swelling. No lacerations. No contusions. No loss of range of motion. No loss of sensations. No capillary refill delay. No distal pulse abnormalities.",
  physical_appearance:
    "No disheveled or unkempt appearance. No poor hygiene. No inappropriate or bizarre clothing. No signs of self neglect. No noticeable weight loss or gain. No unusual postures or movements. No signs of intoxication or drug use.",
  behavioural:
    "No agitation or restlessness. No hyperactivity. No catatonia. No impulsive or reckless behaviour. No self harm behaviour. No aggression or violence. No inappropriate social interactions. No repetitive or stereotyped behaviours. No tremors or tics. No psychomotor slowing. No psychomotor agitation.",
  speech:
    "No slurred speech. No pressured speech. No poverty of speech. No repetitive speech. No nonsensical words or phrases. No flight of ideas. No tangential speech. No mutism.",
  thoughts:
    "No suicidal ideation. No homicidal ideation. No hallucinations. No paranoia. No obsessions. No compulsions. No disorganised thinking.",
  cognition:
    "No memory loss. No disorientation. No difficulty with attention or concentration. No impaired judgment. No lack of insight.",
  mood: "No depressed mood. No elevated or euphoric mood. No flat affect. No changes in mood. No inappropriate affect.",
  harm: "No history of self harm. No history of suicide. No history of violent behaviour. No access to means of self harm. No recent major stressors. No overdose.",
  obstetric_history:
    "No history of preterm labour. No history of miscarriage. No history of gestational diabetes. No history of hypertension or preeclampsia.",
  obstetric:
    "No signs of jaundice. No joint pain or swelling. No uterine contractions. No rupture of membranes. No signs of placental abruption. No signs of preeclampsia. No abnormal foetal movements. No anxiety or panic. No depression. No self harm thoughts or thoughts of harm to baby.",
  wcag: "If you experience any of the following, seek medical assistance immediately: increased pain or discomfort that is not relieved by pain killers. New or unusual symptoms. Fever over 38°. Dizziness or fainting. Shortness of breath. New or worsening chest pain. Severe headache not relieved by pain killers. Confusion or disorientation. Slurred speech. New or worsening weakness or numbness. New onset of seizures. Severe abdominal pain not relieved by pain killers. Blood in your stools or vomiting. Persistent vomiting or diarrhoea. Fast, pounding or irregular heartbeat that does not go away. For advice and support contact your GP or 111. Make your own way to an urgent care centre, emergency department or dial 999 for an ambulance in an emergency.",
};

// Mechanisms of Injury Options
const mechanismsOfInjuryOptions = [
  "Blunt Trauma",
  "Penetrating Trauma",
  "Fall",
  "Vehicle Collision",
  "Burns",
  "Other",
];

const SectionField = ({ label, name, prfData, handleInputChange }) => {
  const currentValue = prfData[name] || "";
  const isYes = currentValue.trim() !== "";

  const handleSelectChange = (e) => {
    const value = e.target.value;
    if (value === "Yes") {
      const prefilledText = prefilledTexts[name] || "";
      handleInputChange(name, prefilledText);
    } else {
      handleInputChange(name, "");
    }
  };

  const handleTextFieldChange = (e) => {
    const value = e.target.value;
    handleInputChange(name, value);
  };

  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant="h6">{label}</Typography>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id={`${name}-label`}>Select</InputLabel>
        <Select
          labelId={`${name}-label`}
          value={isYes ? "Yes" : "No"}
          onChange={handleSelectChange}
        >
          <MenuItem value="Yes">Yes</MenuItem>
          <MenuItem value="No">No</MenuItem>
        </Select>
      </FormControl>
      {isYes && (
        <TextField
          label={label}
          value={currentValue}
          onChange={handleTextFieldChange}
          fullWidth
          multiline
          rows={3}
        />
      )}
    </Box>
  );
};

const SecondarySurvey = ({ prfData, handleInputChange }) => {
  const [selectedMechanisms, setSelectedMechanisms] = useState([]);

  const handleMechanismsChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedMechanisms(typeof value === "string" ? value.split(",") : value);
    handleInputChange("mechanismsOfInjury", value);
  };

  return (
    <Box sx={{ padding: 3 }}>
      {/* Medical Sections */}
      <Typography variant="h4" sx={{ mb: 3 }}>
        Secondary Survey
      </Typography>
      {/* Respiratory */}
      <SectionField
        label="Respiratory"
        name="respiratory"
        prfData={prfData.SecondarySurvey || {}}
        handleInputChange={handleInputChange}
      />
      {/* Cardiac */}
      <Box sx={{ mt: 3 }}>
        <Typography variant="h5">Cardiac</Typography>
        <SectionField
          label="Cardiac Assessment"
          name="cardiac"
          prfData={prfData.SecondarySurvey || {}}
          handleInputChange={handleInputChange}
        />
        {/* ECG Subsection */}
        <Typography variant="h6" sx={{ mt: 2 }}>
          ECG Rhythm Review
        </Typography>
        <TextField
          label="ECG Rhythm Type 1"
          multiline
          rows={3}
          fullWidth
          sx={{ mb: 2 }}
          onChange={(e) => handleInputChange("ecgReview1", e.target.value)}
        />
        <TextField
          label="ECG Rhythm Type 2"
          multiline
          rows={3}
          fullWidth
          onChange={(e) => handleInputChange("ecgReview2", e.target.value)}
        />
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6">Upload ECG Picture</Typography>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleInputChange("ecgPicture", e.target.files[0])}
          />
        </Box>
      </Box>
      {/* Neurological */}
      <SectionField
        label="Neurological"
        name="neurological"
        prfData={prfData.SecondarySurvey || {}}
        handleInputChange={handleInputChange}
      />
      {/* Gastrointestinal */}
      <SectionField
        label="Gastrointestinal"
        name="gastrointestinal"
        prfData={prfData.SecondarySurvey || {}}
        handleInputChange={handleInputChange}
      />
      {/* Trauma Section */}
      <Typography variant="h4" sx={{ mt: 4 }}>
        Trauma
      </Typography>
      <SectionField
        label="Integumentary/Skin"
        name="integumentary"
        prfData={prfData.SecondarySurvey || {}}
        handleInputChange={handleInputChange}
      />
      <FormControl fullWidth sx={{ mt: 3 }}>
        <InputLabel id="mechanisms-label">Mechanisms of Injury</InputLabel>
        <Select
          labelId="mechanisms-label"
          multiple
          value={selectedMechanisms}
          onChange={handleMechanismsChange}
          input={<OutlinedInput label="Mechanisms of Injury" />}
          renderValue={(selected) => selected.join(", ")}
        >
          {mechanismsOfInjuryOptions.map((mechanism) => (
            <MenuItem key={mechanism} value={mechanism}>
              <Checkbox checked={selectedMechanisms.indexOf(mechanism) > -1} />
              <ListItemText primary={mechanism} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SecondarySurvey;
