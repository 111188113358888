// App.jsx

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Box, CssBaseline } from "@mui/material"; // Added imports
import ErrorBoundary from "./components/ErrorBoundary";
import LoginPage from "./pages/LoginPage";
import DashboardPage from "./pages/DashboardPage";
import CreateAdminUserPage from "./pages/CreateAdminUserPage";
import ClientEvents from "./pages/Client/ClientEvents";
import EventDetails from "./pages/Client/EventDetails";
import EventDashboard from "./pages/Client/EventDashboard";
import PrivateRoute from "./components/PrivateRoute";
import CreatePRF from "./components/CreatePRF";
import { PRFProvider } from "./utils/PRFContext";
import AuditLogsPage from "./pages/Reports/AuditLogReport";
import PatientDashboard from "./pages/Patient/PatientDashboard";
import PatientRecord from "./pages/Patient/PatientDetails";
import PRFDetails from "./pages/Patient/PRFDetails";
import CheerTeamsPage from "./pages/Reports/CheerTeamReport";
import PatientAccessDashboard from "./pages/PatientAccess/Dashboard";
import { LoadingProvider } from "./contexts/LoadingContext";
import AuthLoader from "./components/AuthLoader";
import { AuthProvider } from "./contexts/AuthContext";
import PatientRegister from "./pages/PatientRegister";
import Footer from "./components/Footer";
import IMSDashboard from "./pages/Stock/IMSDashboard";
import InventoryOverview from "./pages/Stock/InventoryOverview";
import ItemDetails from "./pages/Stock/ItemDetails";
import BOMsPage from "./pages/Stock/BOMs";

const theme = createTheme({
  palette: {
    primary: { main: "#1976d2" },
    secondary: { main: "#9c27b0" },
    success: { main: "#4caf50" },
    error: { main: "#f44336" },
    warning: { main: "#ff9800" },
    info: { main: "#2196f3" },
    purple: { main: "#673ab7", dark: "#512da8" },
  },
  spacing: 8, // Default spacing unit (8px)
  components: {
    MuiContainer: {
      defaultProps: {
        maxWidth: "lg",
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline /> {/* Reset CSS for consistent styling across browsers */}
      <LoadingProvider>
        <AuthProvider>
          <PRFProvider>
            <ErrorBoundary>
              <Router>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "100vh",
                  }}
                >
                  {/* Main content */}
                  <Box
                    component="main"
                    sx={{
                      flex: 1,
                      py: 4, // Padding top and bottom
                    }}
                  >
                    <AuthLoader>
                      <Routes>
                        {/* Public Routes */}
                        <Route path="/login" element={<LoginPage />} />
                        <Route
                          path="/createadmin"
                          element={<CreateAdminUserPage />}
                        />
                        <Route path="/inventory/dashboard" element={<IMSDashboard />} />

                        {/* Inventory Overview */}
                        <Route
                          path="/inventory/items"
                          element={<InventoryOverview />}
                        />

                        {/* Add New Inventory Item */}
                        <Route
                          path="/inventory/add"
                          element={<ItemDetails />}
                        />

                        {/* Item Details (view, edit) */}
                        <Route
                          path="/inventory/items/:itemId"
                          element={<ItemDetails />}
                        />
                        <Route
                          path="/inventory/boms"
                          element={<BOMsPage />}
                        />
                        <Route
                          path="/reports/auditlog"
                          element={<AuditLogsPage />}
                        />
                        <Route
                          path="/reports/cheerreport/:eventID"
                          element={<CheerTeamsPage />}
                        />
                        <Route path="/createprf" element={<CreatePRF />} />
                        <Route
                          path="/prf/:custom_prf_id"
                          element={<CreatePRF />}
                        />
                        <Route
                          path="/patient-access"
                          element={<PatientAccessDashboard />}
                        />
                        <Route
                          path="/patient-register"
                          element={<PatientRegister />}
                        />

                        {/* Protected Routes */}
                        <Route
                          path="/dashboard"
                          element={
                            <PrivateRoute>
                              <DashboardPage />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/client"
                          element={
                            <PrivateRoute>
                              <ClientEvents />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/client/event/:eventID"
                          element={
                            <PrivateRoute>
                              <EventDetails />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/client/event/:eventID/dashboard"
                          element={
                            <PrivateRoute>
                              <EventDashboard />
                            </PrivateRoute>
                          }
                        />

                        {/* Patient Dashboard Routes */}
                        <Route
                          path="/patients"
                          element={
                            <PrivateRoute>
                              <PatientDashboard />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/patients/:patient_id"
                          element={
                            <PrivateRoute>
                              <PatientRecord />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/patients/:patient_id/prfs/:prf_id"
                          element={
                            <PrivateRoute>
                              <PRFDetails />
                            </PrivateRoute>
                          }
                        />
                      </Routes>
                    </AuthLoader>
                  </Box>

                  {/* Footer */}
                  <Footer />
                </Box>
              </Router>
            </ErrorBoundary>
          </PRFProvider>
        </AuthProvider>
      </LoadingProvider>
    </ThemeProvider>
  );
}

export default App;
