import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Box,
  Typography,
  Paper,
  Button,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { AddCircleOutline, Search, Warning } from "@mui/icons-material";
import apiClient from "../../utils/requestHandler";

const InventoryOverview = () => {
  const [inventory, setInventory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [lowStockItems, setLowStockItems] = useState([]);

  useEffect(() => {
    const fetchInventory = async () => {
      setLoading(true);
      try {
        const response = await apiClient.get("/api/inventory");
        const data = response.data;
        
        setInventory(data);
        const lowStock = data.filter(
          (item) => item.quantity <= item.reorder_level
        );
        setLowStockItems(lowStock);
      } catch (error) {
        console.error("Error fetching inventory:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchInventory();
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCloseDialog = () => {
    setSelectedItem(null);
  };

  const filteredInventory = inventory.filter((item) =>
    item.item_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box sx={{ mt: 10, p: 3 }}>
      <AppBar position="fixed" color="primary">
        <Toolbar>
          <Typography variant="h6">Inventory Management</Typography>
        </Toolbar>
      </AppBar>

      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
        <TextField
          label="Search Inventory"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearch}
          sx={{ width: "70%" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutline />}
          component={Link}
          to="/inventory/add"
        >
          Add New Item
        </Button>
      </Box>

      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Inventory Items
        </Typography>
        {loading ? (
          <CircularProgress />
        ) : filteredInventory.length > 0 ? (
          <List>
            {filteredInventory.map((item) => (
              <ListItem
                key={item.item_id}
                button
                onClick={() => setSelectedItem(item)}
              >
                <ListItemText
                  primary={`${item.item_name} (${item.item_type})`}
                  secondary={`Quantity: ${item.quantity} - Reorder Level: ${item.reorder_level}`}
                />
                {item.quantity <= item.reorder_level && (
                  <IconButton edge="end" color="error">
                    <Warning />
                  </IconButton>
                )}
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography color="textSecondary">
            No inventory items found.
          </Typography>
        )}
      </Paper>

      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Low Stock Items
        </Typography>
        {lowStockItems.length > 0 ? (
          <List>
            {lowStockItems.map((item) => (
              <ListItem
                key={item.item_id}
                button
                component={Link}
                to={`/inventory/items/${item.item_id}`}
              >
                <ListItemText
                  primary={`${item.item_name} (${item.item_type})`}
                  secondary={`Quantity: ${item.quantity}`}
                />
                <IconButton edge="end" color="error">
                  <Warning />
                </IconButton>
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography color="textSecondary">
            No items with low stock levels.
          </Typography>
        )}
      </Paper>

      {selectedItem && (
        <Dialog open={Boolean(selectedItem)} onClose={handleCloseDialog}>
          <DialogTitle>Item Details</DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              <strong>Name:</strong> {selectedItem.item_name}
            </Typography>
            <Typography variant="body1">
              <strong>Type:</strong> {selectedItem.item_type}
            </Typography>
            <Typography variant="body1">
              <strong>Quantity:</strong> {selectedItem.quantity}
            </Typography>
            <Typography variant="body1">
              <strong>Reorder Level:</strong> {selectedItem.reorder_level}
            </Typography>
            <Typography variant="body1">
              <strong>Last Stock Check:</strong>{" "}
              {selectedItem.last_stock_check || "Not available"}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Close
            </Button>
            <Button
              color="primary"
              variant="contained"
              component={Link}
              to={`/inventory/items/${selectedItem.item_id}`}
            >
              View Full Details
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default InventoryOverview;
