import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Card, CardContent, Grid, Button } from '@mui/material';
import apiClient from '../../utils/requestHandler';
import { Link } from 'react-router-dom';

const PatientDashboard = () => {
  const [patients, setPatients] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPatients = async () => {
      try {
        const res = await apiClient.get('/api/patients/');
        setPatients(res.data);
      } catch (error) {
        console.error('Error fetching patients:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchPatients();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredPatients = patients.filter((patient) =>
    patient.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center', mb: 4 }}>
        Patient Dashboard
      </Typography>
      
      {/* Search Bar */}
      <Box mb={4} sx={{ textAlign: 'center' }}>
        <TextField
          label="Search Patients"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
          fullWidth
          sx={{ maxWidth: 600 }}
        />
      </Box>

      {/* Patient List */}
      {loading ? (
        <Typography>Loading...</Typography>
      ) : filteredPatients.length > 0 ? (
        <Grid container spacing={3}>
          {filteredPatients.map((patient) => (
            <Grid item xs={12} sm={6} md={4} key={patient.patient_id}>
              <Card elevation={3} sx={{ borderRadius: 3 }}>
                <CardContent>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    {patient.name}
                  </Typography>
                  <Typography>Date of Birth: {new Date(patient.dob).toLocaleDateString()}</Typography>
                  <Typography>Gender: {patient.gender}</Typography>
                  <Typography>Address: {patient.address}</Typography>

                  <Box mt={2}>
                    <Button
                      variant="outlined"
                      component={Link}
                      to={`/patients/${patient.patient_id}`}
                    >
                      View Patient Details
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography>No patients found.</Typography>
      )}
    </Box>
  );
};

export default PatientDashboard;
