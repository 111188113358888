import React from "react";
import {
  TextField,
  Box,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Button,
  Typography
} from "@mui/material";
import { usePRF } from "../../utils/PRFContext";

const airwayOptions = [
  "Manual",
  "Suction",
  "Oral airway",
  "Nasal airway",
  "iGel",
  "Intubation",
  "Front of neck access",
];
const cprProviders = ["Provider 1", "Provider 2", "Provider 3"];
const ventilationMethods = [
  "Bag valve mask",
  "Mechanical ventilation",
  "Mouth-to-mask",
];
const splintOptions = ["Arm splint", "Leg splint", "Neck brace"];
const woundTreatmentOptions = [
  "Basic dressing",
  "Advanced dressing",
  "Sterile bandage",
];
const advancedInterventionOptions = [
  "Chest decompression",
  "Thoracostomy",
  "Gastric decompression",
];

const ClinicalInterventions = () => {
  const { prfData, handleInputChange } = usePRF();

  // Function to handle adding defibrillation actions
  const addDefibAction = () => {
    const updatedDefib = [
      ...(prfData.ClinicalInterventions.defibrillation || []),
      {
        time: "",
        pad_placement: "",
        rhythm: "",
        shocks_delivered: "",
      },
    ];
    handleInputChange("ClinicalInterventions", "defibrillation", updatedDefib);
  };

  // Function to handle updating specific defibrillation actions
  const handleDefibActionChange = (index, name, value) => {
    const updatedDefib = prfData.ClinicalInterventions.defibrillation.map(
      (action, i) => (i === index ? { ...action, [name]: value } : action)
    );
    handleInputChange("ClinicalInterventions", "defibrillation", updatedDefib);
  };

  // Function to handle adding drugs administered actions
  const addDrugAction = () => {
    const updatedDrugs = [
      ...(prfData.ClinicalInterventions.drugs_administered || []),
      {
        drug_name: "",
        time_administered: "",
      },
    ];
    handleInputChange(
      "ClinicalInterventions",
      "drugs_administered",
      updatedDrugs
    );
  };

  const handleDrugActionChange = (index, name, value) => {
    const updatedDrugs = prfData.ClinicalInterventions.drugs_administered.map(
      (drug, i) => (i === index ? { ...drug, [name]: value } : drug)
    );
    handleInputChange(
      "ClinicalInterventions",
      "drugs_administered",
      updatedDrugs
    );
  };

  return (
    <Box>
      {/* Airway Section */}
      <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
        Airway
      </Typography>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="airway-label">Airway</InputLabel>
        <Select
          labelId="airway-label"
          name="airway"
          multiple
          value={prfData.ClinicalInterventions.airway || []}
          onChange={(e) =>
            handleInputChange("ClinicalInterventions", "airway", e.target.value)
          }
          input={<OutlinedInput label="Airway" />}
          renderValue={(selected) => selected.join(", ")}
        >
          {airwayOptions.map((option) => (
            <MenuItem key={option} value={option}>
              <Checkbox
                checked={prfData.ClinicalInterventions.airway?.includes(option)}
              />
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* CPR Section */}
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="cpr-label">CPR Providers</InputLabel>
        <Select
          labelId="cpr-label"
          multiple
          name="cpr_providers"
          value={prfData.ClinicalInterventions.cpr_providers || []}
          onChange={(e) =>
            handleInputChange(
              "ClinicalInterventions",
              "cpr_providers",
              e.target.value
            )
          }
          input={<OutlinedInput label="CPR Providers" />}
          renderValue={(selected) => selected.join(", ")}
        >
          {cprProviders.map((provider) => (
            <MenuItem key={provider} value={provider}>
              <Checkbox
                checked={prfData.ClinicalInterventions.cpr_providers?.includes(
                  provider
                )}
              />
              <ListItemText primary={provider} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Defibrillation Section */}
      <Box sx={{ mb: 3 }}>
        <h3>Defibrillation Actions</h3>
        {prfData.ClinicalInterventions.defibrillation?.map((action, index) => (
          <Box key={index} sx={{ mb: 2 }}>
            <TextField
              label="Time"
              type="time"
              name="time"
              value={action.time || ""}
              onChange={(e) =>
                handleDefibActionChange(index, "time", e.target.value)
              }
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="Pad Placement"
              name="pad_placement"
              value={action.pad_placement || ""}
              onChange={(e) =>
                handleDefibActionChange(index, "pad_placement", e.target.value)
              }
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="Rhythm"
              name="rhythm"
              value={action.rhythm || ""}
              onChange={(e) =>
                handleDefibActionChange(index, "rhythm", e.target.value)
              }
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="Shocks Delivered"
              name="shocks_delivered"
              value={action.shocks_delivered || ""}
              onChange={(e) =>
                handleDefibActionChange(
                  index,
                  "shocks_delivered",
                  e.target.value
                )
              }
              fullWidth
              sx={{ mb: 2 }}
            />
          </Box>
        ))}
        <Button variant="contained" onClick={addDefibAction} sx={{ mb: 2 }}>
          Add Defibrillation Action
        </Button>
      </Box>

      {/* Cardiac Arrest Drugs Administered */}
      <Box sx={{ mb: 3 }}>
        <h3>Cardiac Arrest Drugs Administered</h3>
        {prfData.ClinicalInterventions.drugs_administered?.map(
          (drug, index) => (
            <Box key={index} sx={{ mb: 2 }}>
              <TextField
                label="Drug Name"
                name="drug_name"
                value={drug.drug_name || ""}
                onChange={(e) =>
                  handleDrugActionChange(index, "drug_name", e.target.value)
                }
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                label="Time Administered"
                type="time"
                name="time_administered"
                value={drug.time_administered || ""}
                onChange={(e) =>
                  handleDrugActionChange(
                    index,
                    "time_administered",
                    e.target.value
                  )
                }
                fullWidth
                sx={{ mb: 2 }}
              />
            </Box>
          )
        )}
        <Button variant="contained" onClick={addDrugAction} sx={{ mb: 2 }}>
          Add Drug Action
        </Button>
      </Box>

      {/* Ventilation Section */}
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="ventilation-method-label">
          Ventilation Method
        </InputLabel>
        <Select
          labelId="ventilation-method-label"
          name="ventilation_method"
          value={prfData.ClinicalInterventions.ventilation_method || ""}
          onChange={(e) =>
            handleInputChange(
              "ClinicalInterventions",
              "ventilation_method",
              e.target.value
            )
          }
        >
          {ventilationMethods.map((method) => (
            <MenuItem key={method} value={method}>
              {method}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* IV & IO Access Section */}
      <TextField
        label="IV Access (Time, Size, Site)"
        name="iv_access"
        value={prfData.ClinicalInterventions.iv_access || ""}
        onChange={(e) =>
          handleInputChange(
            "ClinicalInterventions",
            "iv_access",
            e.target.value
          )
        }
        fullWidth
        sx={{ mb: 2 }}
      />
      <TextField
        label="IO Access (Time, Size, Site)"
        name="io_access"
        value={prfData.ClinicalInterventions.io_access || ""}
        onChange={(e) =>
          handleInputChange(
            "ClinicalInterventions",
            "io_access",
            e.target.value
          )
        }
        fullWidth
        sx={{ mb: 2 }}
      />

      {/* Immobilisation Section */}
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="splint-label">Splints</InputLabel>
        <Select
          labelId="splint-label"
          multiple
          name="splints"
          value={prfData.ClinicalInterventions.splints || []}
          onChange={(e) =>
            handleInputChange(
              "ClinicalInterventions",
              "splints",
              e.target.value
            )
          }
          input={<OutlinedInput label="Splints" />}
          renderValue={(selected) => selected.join(", ")}
        >
          {splintOptions.map((option) => (
            <MenuItem key={option} value={option}>
              <Checkbox
                checked={prfData.ClinicalInterventions.splints?.includes(
                  option
                )}
              />
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Wound Care Section */}
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="wound-treatment-label">Wound Treatments</InputLabel>
        <Select
          labelId="wound-treatment-label"
          multiple
          name="wound_treatments"
          value={prfData.ClinicalInterventions.wound_treatments || []}
          onChange={(e) =>
            handleInputChange(
              "ClinicalInterventions",
              "wound_treatments",
              e.target.value
            )
          }
          input={<OutlinedInput label="Wound Treatments" />}
          renderValue={(selected) => selected.join(", ")}
        >
          {woundTreatmentOptions.map((option) => (
            <MenuItem key={option} value={option}>
              <Checkbox
                checked={prfData.ClinicalInterventions.wound_treatments?.includes(
                  option
                )}
              />
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Advanced Intervention Section */}
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="advanced-intervention-label">
          Advanced Interventions
        </InputLabel>
        <Select
          labelId="advanced-intervention-label"
          multiple
          name="advanced_interventions"
          value={prfData.ClinicalInterventions.advanced_interventions || []}
          onChange={(e) =>
            handleInputChange(
              "ClinicalInterventions",
              "advanced_interventions",
              e.target.value
            )
          }
          input={<OutlinedInput label="Advanced Interventions" />}
          renderValue={(selected) => selected.join(", ")}
        >
          {advancedInterventionOptions.map((option) => (
            <MenuItem key={option} value={option}>
              <Checkbox
                checked={prfData.ClinicalInterventions.advanced_interventions?.includes(
                  option
                )}
              />
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Medications Administered Section */}
      <TextField
        label="Medications Administered (Details)"
        name="medications_administered"
        value={prfData.ClinicalInterventions.medications_administered || ""}
        onChange={(e) =>
          handleInputChange(
            "ClinicalInterventions",
            "medications_administered",
            e.target.value
          )
        }
        fullWidth
        sx={{ mb: 2 }}
      />
    </Box>
  );
};

export default ClinicalInterventions;
