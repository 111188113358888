import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  CircularProgress,
  IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link, useParams } from 'react-router-dom';
import apiClient from '../../utils/requestHandler';
import { ArrowBack } from '@mui/icons-material';

const PatientRecord = () => {
  const { patient_id } = useParams();
  const [patientDetails, setPatientDetails] = useState(null);
  const [prfs, setPrfs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPatientDetails = async () => {
      try {
        const res = await apiClient.get(`/api/patients/${patient_id}`);
        setPatientDetails(res.data);
      } catch (error) {
        console.error('Error fetching patient details:', error);
      }
    };

    const fetchPRFs = async () => {
      try {
        const res = await apiClient.get(`/api/patients/${patient_id}/prfs`);
        setPrfs(res.data);
      } catch (error) {
        console.error('Error fetching PRFs:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPatientDetails();
    fetchPRFs();
  }, [patient_id]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box p={3}>
      <Button component={Link} to="/patients" startIcon={<ArrowBack />} sx={{ mb: 3 }}>
        Back to Patient List
      </Button>

      {/* Patient Information */}
      <Card elevation={3} sx={{ mb: 4 }}>
        <CardContent>
          <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
            Patient Information
          </Typography>
          {patientDetails ? (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography>Name: {patientDetails.name}</Typography>
                <Typography>Date of Birth: {new Date(patientDetails.dob).toLocaleDateString()}</Typography>
                <Typography>Gender: {patientDetails.gender}</Typography>
                <Typography>Contact: {patientDetails.contact_number}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography>Address: {patientDetails.address}</Typography>
                <Typography>Next of Kin: {patientDetails.next_of_kin_name}</Typography>
                <Typography>NOK Contact: {patientDetails.next_of_kin_contact}</Typography>
                <Typography>Relationship: {patientDetails.relationship_to_nok}</Typography>
              </Grid>
            </Grid>
          ) : (
            <Typography>No patient data available.</Typography>
          )}
        </CardContent>
      </Card>

      {/* List of PRFs */}
      <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
        Patient Report Forms (PRFs)
      </Typography>

      {prfs.length > 0 ? (
        <Box>
          {prfs.map((prf) => (
            <Accordion key={prf.prf_id} sx={{ mb: 2 }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>PRF ID: {prf.custom_prf_id}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography>Incident Date: {new Date(prf.incident_date).toLocaleDateString()}</Typography>
                    <Typography>Status: {prf.status}</Typography>
                    <Typography>Chief Complaint: {prf.chief_complaint}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography>Triage Level: {prf.triage}</Typography>
                    <Typography>Treatment: {prf.treatment}</Typography>
                    <Typography>Discharged: {prf.dischargedEvent ? 'Yes' : 'No'}</Typography>
                  </Grid>
                </Grid>
                <Button
                  component={Link}
                  to={`/patients/${patient_id}/prfs/${prf.prf_id}`}
                  variant="outlined"
                  color="primary"
                  sx={{ mt: 2 }}
                >
                  View PRF Details
                </Button>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      ) : (
        <Typography>No PRFs available for this patient.</Typography>
      )}
    </Box>
  );
};

export default PatientRecord;
