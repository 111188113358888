import React from "react";
import {
  TextField,
  Box,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Checkbox,
  ListItemText,
  OutlinedInput,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";
import { usePRF } from "../../utils/PRFContext"; // Import the PRF context

// Define multiple-choice options
const needsOptions = [
  "Cleaning",
  "Mobility",
  "Cooking",
  "None",
  "Shopping",
  "Toileting",
  "Washing",
];

const maritalStatusOptions = [
  "Married",
  "Divorced",
  "Partner",
  "Single",
  "Widowed",
];

const homeCircumstancesOptions = [
  "Patient has own accommodation",
  "Warden controlled",
  "Residential care facility",
  "Nursing care facility",
  "Medical facility",
  "NFA/Homeless",
  "Refused",
  "Not known",
  "Friends",
  "Group",
  "Hostel",
  "Relatives",
  "Spouse",
];

const supportOptions = [
  "Care/lifeline",
  "Carer",
  "Day hospital",
  "Community nursing team",
  "Home care",
  "Keysafe",
  "Meals on wheels",
  "Dementia support services",
  "Age-related support services",
];

const levelOfSupportOptions = [
  "POC once a week",
  "POC 2-6 days a week",
  "POC ODS",
  "POC BDS",
  "POC TDS",
  "POC QDS",
  "POC 24/7",
  "Cohabitants provide care",
  "Warden controlled",
  "Family/friends/community support",
  "No support",
];

const mobilityOptions = [
  "Bed bound",
  "Crutches",
  "Frame/Walker",
  "Full",
  "Handrails",
  "Hoist",
  "Stairlift",
  "Stick (s)",
  "Weightbearing",
  "Wheelchair",
];

const riskFactorsOptions = [
  "Alcohol",
  "Smoking",
  "Family history",
  "High BMI",
  "NON-compliant with meds",
  "Drugs",
];

const disabilityOptions = [
  "Acquired brain injury",
  "Mental health concerns",
  "Dementia/altered mental state",
  "Deafness/hard of hearing",
  "Intellectual disability/learning needs",
  "Physical disability",
  "Vision impaired",
];

const clinicalFrailtyOptions = [
  "1. Very fit",
  "2. Well",
  "3. Managing well",
  "4. Vulnerable",
  "5. Mildly frail",
  "6. Moderately frail",
  "7. Severely frail",
  "8. Very severely frail",
  "9. Terminally ill",
];

// Common languages spoken in the UK
const languageOptions = [
  "English",
  "Welsh",
  "Polish",
  "Punjabi",
  "Urdu",
  "Bengali",
  "Gujarati",
  "Arabic",
  "French",
  "Spanish",
  "Chinese (Cantonese)",
  "Chinese (Mandarin)",
  "Hindi",
  "Portuguese",
  "Romanian",
];

const SocialHistory = () => {
  const { prfData, setPrfData } = usePRF();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPrfData((prev) => ({
      ...prev,
      SocialHistory: {
        ...prev.SocialHistory,
        [name]: value,
      },
    }));
  };

  const handleMultipleSelectChange = (name) => (event) => {
    const {
      target: { value },
    } = event;
    setPrfData((prev) => ({
      ...prev,
      SocialHistory: {
        ...prev.SocialHistory,
        [name]: typeof value === "string" ? value.split(",") : value,
      },
    }));
  };

  return (
    <Box>
      {/* Needs - Multiple choice */}
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="needs-label">Needs</InputLabel>
        <Select
          labelId="needs-label"
          multiple
          value={prfData.SocialHistory.needs || []}
          onChange={handleMultipleSelectChange("needs")}
          input={<OutlinedInput label="Needs" />}
          renderValue={(selected) => selected.join(", ")}
          name="needs"
        >
          {needsOptions.map((option) => (
            <MenuItem key={option} value={option}>
              <Checkbox
                checked={prfData.SocialHistory.needs?.includes(option)}
              />
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Marital Status */}
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="marital-status-label">Marital Status</InputLabel>
        <Select
          labelId="marital-status-label"
          name="marital_status"
          value={prfData.SocialHistory.marital_status || ""}
          onChange={handleInputChange}
        >
          {maritalStatusOptions.map((status) => (
            <MenuItem key={status} value={status}>
              {status}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Home Circumstances */}
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="home-circumstances-label">
          Home Circumstances
        </InputLabel>
        <Select
          labelId="home-circumstances-label"
          name="home_circumstances"
          value={prfData.SocialHistory.home_circumstances || ""}
          onChange={handleInputChange}
        >
          {homeCircumstancesOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Support - Multiple choice */}
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="support-label">Support</InputLabel>
        <Select
          labelId="support-label"
          multiple
          name="support"
          value={prfData.SocialHistory.support || []}
          onChange={handleMultipleSelectChange("support")}
          input={<OutlinedInput label="Support" />}
          renderValue={(selected) => selected.join(", ")}
        >
          {supportOptions.map((option) => (
            <MenuItem key={option} value={option}>
              <Checkbox
                checked={prfData.SocialHistory.support?.includes(option)}
              />
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Level of Support */}
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="level-of-support-label">Level of Support</InputLabel>
        <Select
          labelId="level-of-support-label"
          name="level_of_support"
          value={prfData.SocialHistory.level_of_support || ""}
          onChange={handleMultipleSelectChange("level_of_support")}
        >
          {levelOfSupportOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Mobility */}
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="mobility-label">Mobility</InputLabel>
        <Select
          labelId="mobility-label"
          name="mobility"
          value={prfData.SocialHistory.mobility || ""}
          onChange={handleInputChange}
        >
          {mobilityOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Religious Affiliation - Free Text */}
      <TextField
        label="Religious Affiliation"
        name="religious_affiliation"
        value={prfData.SocialHistory.religious_affiliation || ""}
        onChange={handleInputChange}
        fullWidth
        sx={{ mb: 2 }}
      />

      {/* Risk Factors - Multiple choice */}
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="risk-factors-label">Risk Factors</InputLabel>
        <Select
          labelId="risk-factors-label"
          multiple
          name="risk_factors"
          value={prfData.SocialHistory.risk_factors || []} // Ensure value is always an array
          onChange={handleMultipleSelectChange("risk_factors")}
          input={<OutlinedInput label="Risk Factors" />}
          renderValue={(selected) => selected.join(", ")}
        >
          {riskFactorsOptions.map((option) => (
            <MenuItem key={option} value={option}>
              <Checkbox checked={prfData.risk_factors?.indexOf(option) > -1} />
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Safeguarding Concerns */}
      <InputLabel id="safeguarding_concerns">Safeguarding Concerns</InputLabel>
      <FormControl component="fieldset" sx={{ mb: 2 }}>
        <RadioGroup
          name="safeguarding_concerns"
          value={prfData.SocialHistory.safeguarding_concerns || ""}
          onChange={handleInputChange}
        >
          <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="No" control={<Radio />} label="No" />
        </RadioGroup>
        <TextField
          label="Details"
          name="safeguarding_concerns_details"
          value={prfData.SocialHistory.safeguarding_concerns_details || ""}
          onChange={handleInputChange}
          fullWidth
          sx={{ mt: 2 }}
        />
      </FormControl>

      {/* Disability - Multiple choice */}
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="disability-label">Disability</InputLabel>
        <Select
          labelId="disability-label"
          multiple
          name="disability"
          value={prfData.SocialHistory.disability || []} // Ensure value is always an array
          onChange={handleMultipleSelectChange("disability")}
          input={<OutlinedInput label="Disability" />}
          renderValue={(selected) => selected.join(", ")}
        >
          {disabilityOptions.map((option) => (
            <MenuItem key={option} value={option}>
              <Checkbox checked={prfData.disability?.indexOf(option) > -1} />
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Clinical Frailty Score */}
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="frailty-label">Clinical Frailty Score</InputLabel>
        <Select
          labelId="frailty-label"
          name="clinical_frailty"
          value={prfData.SocialHistory.clinical_frailty || ""}
          onChange={handleMultipleSelectChange("clinical_frailty")}
        >
          {clinicalFrailtyOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Language - Multiple choice */}
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="language-label">Language</InputLabel>
        <Select
          labelId="language-label"
          multiple
          name="language"
          value={prfData.SocialHistory.language || []} // Ensure value is always an array
          onChange={handleMultipleSelectChange("language")}
          input={<OutlinedInput label="Language" />}
          renderValue={(selected) => selected.join(", ")}
        >
          {languageOptions.map((option) => (
            <MenuItem key={option} value={option}>
              <Checkbox checked={prfData.language?.indexOf(option) > -1} />
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Interpreter Required - Yes/No */}
      <InputLabel id="interpreter_required">Interpreter Required</InputLabel>
      <FormControl component="fieldset" sx={{ mb: 2 }}>
        <RadioGroup
          name="interpreter_required"
          value={prfData.SocialHistory.interpreter_required || ""}
          onChange={handleInputChange}
        >
          <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="No" control={<Radio />} label="No" />
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default SocialHistory;
