import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Grid,
  Paper,
  Button,
  Alert,
  CircularProgress,
  LinearProgress,
  Snackbar,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import IncidentDetails from "./PRF/IncidentDetails";
import PatientDemographics from "./PRF/PatientDemographics";
import PresentingComplaint from "./PRF/PresentingComplaint";
import SocialHistory from "./PRF/SocialHistory";
import PrimarySurvey from "./PRF/PrimarySurvey";
import Observations from "./PRF/Observations";
import SecondarySurvey from "./PRF/SecondarySurvey";
import ClinicalInterventions from "./PRF/ClinicalInterventions";
import ClinicalImpression from "./PRF/ClinicalImpression";
import RecognitionOfLifeExtinct from "./PRF/RecognitionOfLifeExtinct";
import AdviceGiven from "./PRF/AdviceGiven";
import DispositionOutcome from "./PRF/DispositionOutcome";
import apiClient from "../utils/requestHandler";
import useNetworkStatus from "../hooks/useNetworkStatus";
import OfflineBanner from "./OfflineBanner";
import ErrorBoundary from "./ErrorBoundary";
import { usePRF } from "../utils/PRFContext";
import PrintPRF from "../utils/PrintPRF";
import { logAuditEntry, syncOfflineLogs } from "../utils/auditLogger";
import {
  storeEncryptedData,
  retrieveDecryptedData,
} from "../utils/sessionHandler";
import PRFStatus from "./PRFStatus";
import conditionsActions from "./conditionActions";
import ConditionActionsSidebar from "./ConditionActionSidebar";

const CreatePRF = () => {
  const { eventID, patientID, custom_prf_id } = useParams();
  const storedActiveTab = retrieveDecryptedData("activeTab");
  const initialTab =
    storedActiveTab !== null && !isNaN(Number(storedActiveTab))
      ? Number(storedActiveTab)
      : 0;
  const [activeTab, setActiveTab] = useState(initialTab);
  const { prfData, setPrfData } = usePRF();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [user, setUser] = useState(null);
  const isAdmin = user?.role === "admin";
  const navigate = useNavigate();
  const isOnline = useNetworkStatus();
  const [events, setEvents] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState(
    retrieveDecryptedData("selectedEventId") || ""
  );
  const [readOnly, setReadOnly] = useState(false); // New state for readOnly
  const selectedEvent = events.find((evt) => evt.eventID === selectedEventId);
  const [selectedCondition, setSelectedCondition] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [recordedActions, setRecordedActions] = useState({});

  const handleConditionSelect = (condition) => {
    setSelectedCondition(condition);
    setIsSidebarOpen(true);
  };

  const conditionsList = Object.keys(conditionsActions);

  // Function to handle opening the print window in a new popup
  const handlePrintPRFPopup = () => {
    const printWindow = window.open("", "Print PRF", "width=800,height=600");
    printWindow.document.write(
      `<html><head><title>Print PRF</title></head><body>${
        document.getElementById("prf-print").innerHTML
      }</body></html>`
    );
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  };
  // Load user and PRF data, and handle session storage compliance
  useEffect(() => {
    const fetchUserAndLoadPRF = async () => {
      setLoading(true); // Start loading here

      try {
        const fetchedUser = await fetchUser();

        if (fetchedUser && fetchedUser.id) {
          await loadPRF(custom_prf_id, fetchedUser);
        } else {
          console.error("User is not defined or user.id is missing.");
        }
      } catch (error) {
        console.error("Error in fetchUserAndLoadPRF:", error);
      } finally {
        setLoading(false); // Stop loading after data loads or error occurs
      }
    };

    if (custom_prf_id) {
      fetchUserAndLoadPRF();
    }
  }, [custom_prf_id]);

  // Load incident details from sessionStorage
  useEffect(() => {
    const loadStoredIncidentDetails = () => {
      try {
        const storedIncidentDetails = retrieveDecryptedData("incidentDetails");

        if (storedIncidentDetails && storedIncidentDetails !== "undefined") {
          const details = JSON.parse(storedIncidentDetails);
          setPrfData((prev) => ({
            ...prev,
            incidentDetails: {
              ...prev.incidentDetails,
              ...details,
            },
          }));
        }
      } catch (error) {
        console.error(
          "Error parsing incidentDetails from sessionStorage:",
          error
        );
      }
    };

    const updateIncidentDetailsFromEvent = () => {
      if (events.length > 0 && selectedEventId) {
        setPrfData((prev) => ({
          ...prev,
          incidentDetails: {
            ...prev.incidentDetails,
            event_id: selectedEventId,
            incident_location: selectedEvent?.location || "",
          },
        }));
      }
    };

    loadStoredIncidentDetails();
    updateIncidentDetailsFromEvent();
  }, [events, selectedEventId, setPrfData]);

  // Sync audit logs when app comes back online (ISO 27001 compliance)
  useEffect(() => {
    if (navigator.onLine) {
      syncOfflineLogs();
    }
  }, [navigator.onLine]);

  // Store active tab and event details in sessionStorage (Cyber Essentials compliance)
  useEffect(() => {
    if (activeTab !== undefined && selectedEventId) {
      storeEncryptedData("activeTab", activeTab);
      storeEncryptedData("selectedEventId", selectedEventId);
      if (prfData?.incidentDetails) {
        storeEncryptedData("incidentDetails", prfData.incidentDetails);
      }

      if (user?.id) {
        storeEncryptedData("userId", user.id);
      }
    }
  }, [activeTab, selectedEventId, prfData.incidentDetails, user]);

  const fetchUser = async () => {
    try {
      const res = await apiClient.get("/api/auth/user", {
        withCredentials: true,
      });

      setUser(res.data);
      storeEncryptedData("userId", res.data.id);

      return res.data;
    } catch (err) {
      localStorage.removeItem("token");
      setError("Your session has expired. Please log in again.");
      setLoading(false);
      setTimeout(() => {
        navigate("/");
      }, 3000);
      return null;
    }
  };

  useEffect(() => {
    if (!custom_prf_id) {
      // Set default incident date for new PRFs
      setPrfData((prev) => ({
        ...prev,
        incidentDetails: {
          ...prev.incidentDetails,
          incident_date: new Date().toISOString(), // Default to current date-time
        },
      }));
    }
  }, [custom_prf_id, setPrfData]);

  const loadPRF = async (custom_prf_id) => {
    setLoading(true);
    try {
      // Fetch PRF data
      const response = await apiClient.get(`/api/prf/${custom_prf_id}`, {
        withCredentials: true,
      });
      console.log(response.data);
      // Destructure and set default values as needed
      const { data } = response;
      if (data) {
        setPrfData({
          incidentDetails: {
            event_id: data.incidentDetails.event_id || "",
            event_name: data.incidentDetails.event?.name || "",
            incident_date: data.incidentDetails.incident_date || "",
            incident_location: data.incidentDetails.incident_location || "",
            chief_complaint: data.incidentDetails.chief_complaint || "",
            history_of_presenting_complaint:
              data.incidentDetails.history_of_presenting_complaint || "",
            scene_description: data.incidentDetails.scene_description || "",
          },
          demographics: {
            name: data.patient?.name || "",
            dob: data.patient?.dob || "",
            gender: data.patient?.gender || "",
            address: data.patient?.address || "",
            contact_number: data.patient?.contact_number || "",
            next_of_kin_name: data.patient?.next_of_kin_name || "-",
            next_of_kin_contact: data.patient?.next_of_kin_contact || "-",
            relationship_to_nok: data.patient?.relationship_to_nok || "-",
            past_medical_history: data.patient?.past_medical_history || "-",
            medications: data.patient?.medications || "-",
            allergies: data.patient?.allergies || "-",
          },
          PresentingComplaint: {
            presenting_complaint:
              data.PresentingComplaint?.presenting_complaint || "",
            history_of_presenting_complaint:
              data.PresentingComplaint?.history_of_presenting_complaint || "",
            allergies: data.PresentingComplaint?.allergies || [],
            medications: data.PresentingComplaint?.medications || [],
            past_medical_history:
              data.PresentingComplaint?.past_medical_history || "",
            last_oral_intake: data.PresentingComplaint?.last_oral_intake || "",
            family_history: data.PresentingComplaint?.family_history || "",
          },
          PrimarySurvey: {
            consent: data.PrimarySurvey?.consent || "",
            understanding: data.PrimarySurvey?.understanding || "",
            retaining: data.PrimarySurvey?.retaining || "",
            weighing: data.PrimarySurvey?.weighing || "",
            communicating: data.PrimarySurvey?.communicating || "",
            capacity_conclusion: data.PrimarySurvey?.capacity_conclusion || "",
            involvement: data.PrimarySurvey?.involvement || "",
            wishes_feelings: data.PrimarySurvey?.wishes_feelings || "",
            understand_consequences:
              data.PrimarySurvey?.understand_consequences || "",
            least_restrictive_option:
              data.PrimarySurvey?.least_restrictive_option || "",
            catastrophic_haemorrhage:
              data.PrimarySurvey?.catastrophic_haemorrhage || "",
            cervical_spine: data.PrimarySurvey?.cervical_spine || "",
            airway: data.PrimarySurvey?.airway || "",
            breathing: data.PrimarySurvey?.breathing || [],
            circulation: data.PrimarySurvey?.circulation || [],
          },
          SocialHistory: {
            marital_status: data.socialHistory?.marital_status || "",
            home_circumstances: data.socialHistory?.home_circumstances || "",
            level_of_support: data.socialHistory?.level_of_support || "",
            risk_factors: data.socialHistory?.risk_factors || [],
            disability: data.socialHistory?.disability || [],
            clinical_frailty: data.socialHistory?.clinical_frailty || "",
          },
          SecondarySurvey: {
            medical: {
              respiratory: data.secondarySurvey?.respiratory || "",
              cardiac: data.secondarySurvey?.cardiac || "",
              neurological: data.secondarySurvey?.neurological || "",
              gastrointestinal: data.secondarySurvey?.gastrointestinal || "",
              urinary: data.secondarySurvey?.urinary || "",
              obs_gynae: data.secondarySurvey?.obs_gynae || "",
            },
            trauma: {
              msk_injury_assessment:
                data.secondarySurvey?.msk_injury_assessment || "",
              rtc_documentation: data.secondarySurvey?.rtc_documentation || "",
              head: data.secondarySurvey?.head || "",
              neck: data.secondarySurvey?.neck || "",
              chest: data.secondarySurvey?.chest || "",
              abdo: data.secondarySurvey?.abdo || "",
              pelvis: data.secondarySurvey?.pelvis || "",
              upper_limb: data.secondarySurvey?.upper_limb || "",
              lower_limb: data.secondarySurvey?.lower_limb || "",
              back: data.secondarySurvey?.back || "",
            },
            psych: {
              harm: data.secondarySurvey?.harm || "",
              overdose: data.secondarySurvey?.overdose || "",
              physical: data.secondarySurvey?.physical || "",
              behavioral: data.secondarySurvey?.behavioral || "",
              suicidal: data.secondarySurvey?.suicidal || "",
              speech: data.secondarySurvey?.speech || "",
              thoughts: data.secondarySurvey?.thoughts || "",
              mood: data.secondarySurvey?.mood || "",
            },
          },
          ClinicalInterventions: {
            airway: data.ClinicalIntervention?.airway || [],
            cpr_providers: data.ClinicalIntervention?.cpr_providers || [],
            defibrillation: data.ClinicalIntervention?.defibrillation || [],
            drugs_administered:
              data.ClinicalIntervention?.drugs_administered || [],
            ventilation_method:
              data.ClinicalIntervention?.ventilation_method || "",
            iv_access: data.ClinicalIntervention?.iv_access || "",
            io_access: data.ClinicalIntervention?.io_access || "",
            splints: data.ClinicalIntervention?.splints || [],
            wound_treatments: data.ClinicalIntervention?.wound_treatments || [],
            advanced_interventions:
              data.ClinicalIntervention?.advanced_interventions || [],
            medications_administered:
              data.ClinicalIntervention?.medications_administered || "",
          },
          DispositionOutcome: {
            disposition_outcome:
              data.dispositionOutcome?.disposition_outcome || "",
            time_left_dc_care: data.dispositionOutcome?.time_left_dc_care || "",
            discharging_clinician:
              data.dispositionOutcome?.discharging_clinician || "",
            clinician_consulted_name:
              data.dispositionOutcome?.clinician_consulted_name || "",
            clinician_consulted_role:
              data.dispositionOutcome?.clinician_consulted_role || "",
            clinical_discussion_summary:
              data.dispositionOutcome?.clinical_discussion_summary || "",
          },
          observations: data.Observations || [],
          status: data.status || "",
          custom_prf_id: data.custom_prf_id || "",
        });

        if (user && user.id) {
          if (!retrieveDecryptedData(`prf_accessed_${custom_prf_id}`)) {
            logAuditEntry(user.id, custom_prf_id, "PRF Accessed", {
              message: `PRF with ID ${custom_prf_id} was accessed.`,
            });
            storeEncryptedData(`prf_accessed_${custom_prf_id}`, true);
          }
        }
        // Determine readOnly based on whether event_id is populated
        setReadOnly(!!data.incidentDetails.event_id);
      } else {
        setError("No data returned from server.");
      }
    } catch (err) {
      setError("Error loading PRF. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (section, name, value) => {
    const oldValue = prfData[section]?.[name] || null;
    setPrfData((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [name]: value,
      },
    }));

    logAuditEntry(user.id, custom_prf_id, "Field Changed", {
      [name]: {
        old_value: oldValue,
        new_value: value,
      },
    });
  };

  // Status change for the PRF
  const handleStatusChange = async (newStatus) => {
    setLoading(true);
    try {
      await apiClient.put(`/api/prf/status/${prfData.custom_prf_id}`, {
        status: newStatus,
      });
      setPrfData((prev) => ({ ...prev, status: newStatus }));
    } catch (err) {
      setError("Error updating PRF status.");
    } finally {
      setLoading(false);
    }
  };

  // Handle navigation between tabs
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Handle PRF Creation
  const handleCreatePRF = async () => {
    if (!isOnline) {
      setError(
        "You are currently offline. Please connect to the internet to create a PRF."
      );
      setOpenSnackbar(true);
      return;
    }

    if (
      !prfData.incidentDetails.incident_date ||
      !prfData.incidentDetails.incident_location ||
      !prfData.incidentDetails.chief_complaint
    ) {
      setError("Please fill in all required fields before creating a PRF.");
      setOpenSnackbar(true);
      return;
    }

    setError("");
    setLoading(true);

    try {
      // Prepare the PRF payload
      const prfPayload = {
        event_id: prfData.incidentDetails.event_id,
        patient_id: prfData.demographics.patient_id,
        squad_id: prfData.squad_id || null, // Include squad_id only if it exists
        custom_prf_id: null,
        ...prfData.incidentDetails,
      };

      // Create the PRF
      const response = await apiClient.post("/api/prf", prfPayload);

      const createdPRF = response.data;

      // If squad_id exists, link the patient to the squad
      if (prfData.squad_id) {
        await apiClient.post("/api/patient-squad", {
          patient_id: prfData.demographics.patient_id,
          squad_id: prfData.squad_id,
          start_date: new Date().toISOString(), // Set start date for the squad link
        });
      }

      // Update PRF data in the local state
      setPrfData((prev) => ({
        ...prev,
        custom_prf_id: createdPRF.custom_prf_id,
      }));
      setOpenSnackbar(true);
    } catch (error) {
      setError("Error creating PRF. Please try again.");
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  // Handle PRF Updates
  const handleUpdatePRF = async () => {
    if (!isOnline) {
      setError(
        "You are currently offline. Please connect to the internet to update the PRF."
      );
      setOpenSnackbar(true);
      return;
    }

    if (
      !prfData.incidentDetails.incident_date ||
      !prfData.incidentDetails.incident_location ||
      !prfData.incidentDetails.chief_complaint
    ) {
      setError("Please fill in all required fields before updating the PRF.");
      setOpenSnackbar(true);
      return;
    }

    const removeEmptyValues = (obj) => {
      return Object.fromEntries(
        Object.entries(obj).filter(
          ([_, value]) =>
            value !== "" &&
            value !== null &&
            value !== undefined &&
            (!Array.isArray(value) || value.length > 0)
        )
      );
    };

    const validatedIncidentDetails = removeEmptyValues({
      event_name: prfData.incidentDetails.event_name,
      incident_date: new Date(prfData.incidentDetails.incident_date),
      incident_location: prfData.incidentDetails.incident_location,
      chief_complaint: prfData.incidentDetails.chief_complaint,
      history_of_presenting_complaint:
        prfData.incidentDetails.history_of_presenting_complaint,
      scene_description: prfData.incidentDetails.scene_description,
    });

    const validatedDemographics = removeEmptyValues({
      name: prfData.demographics.name,
      dob: prfData.demographics.dob ? new Date(prfData.demographics.dob) : null,
      gender: prfData.demographics.gender,
      address: prfData.demographics.address,
      contact_number: prfData.demographics.contact_number,
    });

    const validatedClinicalInterventions = removeEmptyValues({
      airway: Array.isArray(prfData.ClinicalInterventions.airway)
        ? prfData.ClinicalInterventions.airway
        : [""],
      cpr_providers: Array.isArray(prfData.ClinicalInterventions.cpr_providers)
        ? prfData.ClinicalInterventions.cpr_providers
        : [""],
      defibrillation: Array.isArray(
        prfData.ClinicalInterventions.defibrillation
      )
        ? prfData.ClinicalInterventions.defibrillation
        : [],
      drugs_administered: Array.isArray(
        prfData.ClinicalInterventions.drugs_administered
      )
        ? prfData.ClinicalInterventions.drugs_administered
        : [],
      ventilation_method:
        prfData.ClinicalInterventions.ventilation_method || "",
      iv_access: prfData.ClinicalInterventions.iv_access || "",
      io_access: prfData.ClinicalInterventions.io_access || "",
      splints: Array.isArray(prfData.ClinicalInterventions.splints)
        ? prfData.ClinicalInterventions.splints
        : [""],
      wound_treatments: Array.isArray(
        prfData.ClinicalInterventions.wound_treatments
      )
        ? prfData.ClinicalInterventions.wound_treatments
        : [""],
      advanced_interventions: Array.isArray(
        prfData.ClinicalInterventions.advanced_interventions
      )
        ? prfData.ClinicalInterventions.advanced_interventions
        : [""],
      medications_administered:
        prfData.ClinicalInterventions.medications_administered || "",
    });

    setError("");
    setLoading(true);

    try {
      await apiClient.put(`/api/prf/${custom_prf_id}`, {
        incidentDetails: validatedIncidentDetails,
        demographics: validatedDemographics,
        PresentingComplaint: removeEmptyValues(prfData.PresentingComplaint),
        SocialHistory: removeEmptyValues(prfData.SocialHistory),
        SecondarySurvey: removeEmptyValues(prfData.SecondarySurvey),
        ClinicalInterventions: validatedClinicalInterventions,
        DispositionOutcome: removeEmptyValues(prfData.DispositionOutcome),
        Observations: prfData.observations,
        status: prfData.status,
      });

      setOpenSnackbar(true);
      setError("");
    } catch (error) {
      setError("Error updating PRF. Please try again.");
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const renderActiveTabContent = () => {
    if (!prfData) return null;

    const status = prfData.status || "new";

    switch (activeTab) {
      case 0:
        return (
          <ErrorBoundary>
            <IncidentDetails
              data={prfData.incidentDetails}
              readOnly={readOnly} // Pass readOnly here
              handleInputChange={(name, value) =>
                handleInputChange("incidentDetails", name, value)
              }
            />
          </ErrorBoundary>
        );
      case 1:
        return status !== "dischargedEvent" || isAdmin ? (
          <PatientDemographics
            prfData={prfData.demographics}
            handleInputChange={(name, value) =>
              handleInputChange("demographics", name, value)
            }
          />
        ) : null;
      case 2:
        return status === "triage" || status === "treatment" || isAdmin ? (
          <PresentingComplaint
            prfData={prfData.presenting_complaint}
            handleInputChange={(e) =>
              handleInputChange("presenting_complaint", e)
            }
          />
        ) : null;
      case 3:
        return (
          (status === "treatment" || isAdmin) && (
            <SocialHistory
              prfData={prfData.social_history}
              handleInputChange={(e) => handleInputChange("social_history", e)}
            />
          )
        );
      case 4:
        return (
          (status === "treatment" ||
            status === "awaitingTransport" ||
            isAdmin) && (
            <PrimarySurvey
              prfData={prfData.primary_survey}
              handleInputChange={(e) => handleInputChange("primary_survey", e)}
            />
          )
        );
      case 5:
        return (
          (status === "treatment" ||
            status === "awaitingTransport" ||
            isAdmin) && (
            <Observations
              prfData={prfData.Observations}
              handleInputChange={(e) => handleInputChange("observations", e)}
            />
          )
        );
      case 6:
        return (
          (status === "treatment" || isAdmin) && (
            <SecondarySurvey
              prfData={prfData}
              handleInputChange={(name, value) => {
                setPrfData((prev) => {
                  const prevSecondarySurvey = prev.SecondarySurvey || {};

                  const updatedSecondarySurvey = {
                    ...prevSecondarySurvey,
                    [name]: value,
                  };

                  return {
                    ...prev,
                    SecondarySurvey: updatedSecondarySurvey,
                  };
                });
              }}
            />
          )
        );
      case 7:
        return (
          (status === "treatment" || isAdmin) && (
            <ClinicalInterventions
              prfData={prfData.ClinicalInterventions}
              handleInputChange={(e) =>
                handleInputChange("ClinicalInterventions", e)
              }
            />
          )
        );
      case 8:
        return (
          (status === "treatment" || isAdmin) && (
            <ClinicalImpression
              prfData={prfData.ClinicalImpression}
              handleInputChange={(e) =>
                handleInputChange("ClinicalImpression", e)
              }
            />
          )
        );
      case 9:
        return (
          (status === "recognitionOfLifeExtinct" || isAdmin) && (
            <RecognitionOfLifeExtinct
              prfData={prfData.RecognitionOfLifeExtinct}
              handleInputChange={(e) =>
                handleInputChange("RecognitionOfLifeExtinct", e)
              }
            />
          )
        );
      case 10:
        return (
          <AdviceGiven
            prfData={prfData.advice_given}
            handleInputChange={(name, value) =>
              handleInputChange("advice_given", name, value)
            }
          />
        );
      case 11:
        return (
          <DispositionOutcome
            prfData={prfData.disposition_outcome}
            handleInputChange={(name, value) =>
              handleInputChange("disposition_outcome", name, value)
            }
          />
        );
      default:
        return null;
    }
  };

  const handlePrint = () => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
      <head>
        <title>PRF Print</title>
        <style>
          .print-content { font-family: Arial, sans-serif; }
        </style>
      </head>
      <body>
        <div id="print-section"></div>
        <script>
          window.onload = function() {
            window.print();
          };
        </script>
      </body>
      </html>
    `);

    printWindow.document
      .getElementById("print-section")
      .appendChild(document.createElement("div"));
    printWindow.document.getElementById(
      "print-section"
    ).firstElementChild.innerHTML =
      document.getElementById("prf-print").innerHTML;

    logAuditEntry(user.id, custom_prf_id, "PRF Printed", {
      message: `PRF with ID ${custom_prf_id} was printed.`,
    });
  };

  return (
    <Box sx={{ p: 4, backgroundColor: "#f0f0f0", minHeight: "100vh" }}>
      {!isOnline && <OfflineBanner />}
      {loading && <LinearProgress />}

      <Grid container justifyContent="center">
        <Grid item xs={12} md={10} lg={8}>
          <Paper elevation={3} sx={{ borderRadius: 2, padding: 4 }}>
            <Typography
              variant="h4"
              sx={{
                mb: 4,
                textAlign: "center",
                fontWeight: "bold",
                color: "#005EB8",
              }}
            >
              {custom_prf_id
                ? "Edit Patient Report Form (PRF)"
                : "Create Patient Report Form (PRF)"}
            </Typography>

            {error && (
              <Alert severity="error" sx={{ mb: 3 }}>
                {error}
              </Alert>
            )}

            <Box sx={{ flexGrow: 1, p: 3 }}>
              <Typography variant="h6" sx={{ mt: 2 }}>
                Select a Condition:
              </Typography>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                {conditionsList.map((condition) => (
                  <Button
                    variant="contained"
                    key={condition}
                    onClick={() => handleConditionSelect(condition)}
                  >
                    {condition}
                  </Button>
                ))}
              </Box>

              {/* Other components and content */}
            </Box>
            <ConditionActionsSidebar
              selectedCondition={selectedCondition}
              isSidebarOpen={isSidebarOpen}
              setIsSidebarOpen={setIsSidebarOpen}
              recordedActions={recordedActions}
              setRecordedActions={setRecordedActions}
              conditionsActions={conditionsActions}
            />
            <Box sx={{ mb: 4, display: "flex", justifyContent: "center" }}>
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                variant="scrollable" // Allow scrolling when necessary
                scrollButtons="auto"
                allowScrollButtonsMobile
                sx={{
                  maxWidth: "100%", // Ensure tabs take full width but within max width bounds
                  overflowX: "auto", // Ensure scrollability on small screens
                  ".MuiTabs-flexContainer": {
                    border: null, // Highlight border for selected tab
                    justifyContent: "center", // Center the tabs
                    flexWrap: "wrap", // Allow wrapping if space allows
                  },
                  ".MuiTab-root": {
                    minWidth: 120, // Set minimum width for consistent button sizes
                    borderRadius: 2, // Rounded borders for a smoother look
                    padding: "12px 16px", // Increase padding for easier touch interaction
                    color: "#333",
                    "&.Mui-selected": {
                      border: "2px solid #004A9F", // Highlight border for selected tab
                      backgroundColor: "#E0EFFF", // Highlight background
                      color: "#005EB8", // Active text color
                      fontWeight: "bold", // Bold font for selected tab
                    },
                    "&:hover": {
                      backgroundColor: "#f5f5f5", // Slight hover effect
                    },
                  },
                }}
              >
                <Tab label="Incident Details" />
                <Tab
                  label="Patient Demographics"
                  disabled={!isAdmin && prfData?.status === "dischargedEvent"}
                />
                <Tab
                  label="Presenting Complaint"
                  disabled={
                    !isAdmin &&
                    prfData?.status !== "triage" &&
                    prfData?.status !== "treatment"
                  }
                />
                <Tab
                  label="Social History"
                  disabled={!isAdmin && prfData?.status !== "treatment"}
                />
                <Tab
                  label="Primary Survey"
                  disabled={
                    !isAdmin &&
                    prfData?.status !== "treatment" &&
                    prfData?.status !== "awaitingTransport"
                  }
                />
                <Tab
                  label="Observations"
                  disabled={
                    !isAdmin &&
                    prfData?.status !== "treatment" &&
                    prfData?.status !== "awaitingTransport"
                  }
                />
                <Tab
                  label="Secondary Survey"
                  disabled={!isAdmin && prfData?.status !== "treatment"}
                />
                <Tab
                  label="Clinical Interventions"
                  disabled={!isAdmin && prfData?.status !== "treatment"}
                />
                <Tab
                  label="Clinical Impression"
                  disabled={!isAdmin && prfData?.status !== "treatment"}
                />
                <Tab
                  label="Recognition of Life Extinct"
                  disabled={
                    !isAdmin && prfData?.status !== "recognitionOfLifeExtinct"
                  }
                />
                <Tab
                  label="Advice Given"
                  disabled={!isAdmin && prfData?.status !== "treatment"}
                />
                <Tab
                  label="Disposition/Outcome"
                  disabled={!isAdmin && prfData?.status !== "treatment"}
                />
              </Tabs>
            </Box>

            {!prfData ? (
              <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
                <CircularProgress />
              </Box>
            ) : (
              <Box sx={{ mt: 4 }}>{renderActiveTabContent()}</Box>
            )}

            <PRFStatus prfData={prfData} />

            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}
            >
              <Button
                onClick={custom_prf_id ? handleUpdatePRF : handleCreatePRF}
                variant="contained"
                color="primary"
                disabled={!isOnline || loading}
                sx={{ width: "48%" }}
              >
                {loading ? (
                  <CircularProgress size={24} />
                ) : custom_prf_id ? (
                  "Update PRF"
                ) : (
                  "Create PRF"
                )}
              </Button>

              <Button
                onClick={handlePrintPRFPopup}
                variant="contained"
                color="secondary"
                sx={{ width: "48%" }}
              >
                Print PRF
              </Button>
            </Box>

            <div id="prf-print" style={{ display: "none" }}>
              <PrintPRF prfData={prfData} />
            </div>
          </Paper>
        </Grid>
      </Grid>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message={error ? "Error occurred" : "PRF saved successfully!"}
        action={
          <IconButton
            size="small"
            color="inherit"
            onClick={() => setOpenSnackbar(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Box>
  );
};

export default CreatePRF;
