import React, { createContext, useContext, useState } from "react";

// Create a Context for the PRF data
const PRFContext = createContext();

// Provider component to wrap the app
export const PRFProvider = ({ children }) => {
  const [prfData, setPrfData] = useState({
    incidentDetails: {
      event_id: "",
      incident_date: "",
      incident_location: "",
      chief_complaint: "",
      squad_id:"",
      custom_prf_id:"",
    },
    demographics: {
      patient_name: "",
      dob: "",
      gender: "",
      address: "",
      contact_number: "",
      next_of_kin_name: "",
      next_of_kin_contact: "",
      relationship_to_nok: "",
      past_medical_history: "",
      medications: "",
      allergies: "",
    },
    PresentingComplaint: {
      presenting_complaint: "",
      history_of_presenting_complaint: "",
      past_medical_history: "",
      last_oral_intake: "",
      family_history: "",
    },
    PrimarySurvey: {
      consent: "",
      understanding: "",
      retaining: "",
      weighing: "",
      communicating: "",
      capacity_conclusion: "",
      involvement: "",
      wishes_feelings: "",
      understand_consequences: "",
      least_restrictive_option: "",
      catastrophic_haemorrhage: "",
      cervical_spine: "",
      airway: "",
      breathing: [],
      circulation: [],
    },
    SocialHistory: {
      needs: [],
      marital_status: "",
      home_circumstances: "",
      support: [],
      level_of_support: "",
      mobility: "",
      religious_affiliation: "",
      risk_factors: [],
      safeguarding_concerns: "",
      safeguarding_concerns_details: "",
      disability: [],
      clinical_frailty: "",
      language: [],
      interpreter_required: "",
    },
    SecondarySurvey: {
      medical: {
        respiratory: "",
        cardiac: "",
        neurological: "",
        gastrointestinal: "",
        urinary: "",
        obs_gynae: "",
      },
      trauma: {
        msk_injury_assessment: "",
        rtc_documentation: "",
        head: "",
        neck: "",
        chest: "",
        abdo: "",
        pelvis: "",
        upper_limb: "",
        lower_limb: "",
        back: "",
        mechanism_of_injury: "",
        freetext: "",
      },
      psych: {
        harm: "",
        overdose: "",
        physical: "",
        behavioral: "",
        suicidal: "",
        speech: "",
        thoughts: "",
        mood: "",
      },
    },
    observations: [],
    ClinicalInterventions: {
      airway: [],
      cpr_providers: [],
      defibrillation: [],
      drugs_administered: [],
      ventilation_method: "",
      iv_access: "",
      io_access: "",
      splints: [],
      wound_treatments: [],
      advanced_interventions: [],
      medications_administered: "",
    },
    clinical_impression: [],
    DispositionOutcome: {
      disposition_outcome: "",
      time_left_dc_care: "",
      discharging_clinician: "",
      clinician_consulted_name: "",
      clinician_consulted_role: "",
      clinical_discussion_summary: "",
    },
    ROLE: {
      dnacpr: false,
      advanced_directive: false,
      terminally_ill: false,
      decapitation: false,
      maceration: false,
      decomposition: false,
      hemicorporectomy: false,
      hypostasis: false,
      incineration: false,
      massive_cerebral_destruction: false,
      rigor_mortis: false,
      patient_asystolic: false,
      remained_asystolic: false,
      asystole_30_seconds: false,
      asystole_20_minutes_after_als: false,
      collapse_gt_15_minutes: false,
      no_evidence_of_cpr_prior_to_arrival: false,
      no_contradiction_to_protocol: false,
      role_time: "",
      role_clinician_name: "",
      role_clinician_grade: "",
    },
  });

  const handleInputChange = (section, name, value) => {
    setPrfData((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [name]: value,
      },
    }));
  };

  const setAllergies = (value) => {
    setPrfData((prev) => ({
      ...prev,
      PresentingComplaint: {
        ...prev.PresentingComplaint,
        allergies: value,
      },
    }));
  };

  const setMedications = (value) => {
    setPrfData((prev) => ({
      ...prev,
      PresentingComplaint: {
        ...prev.PresentingComplaint,
        medications: value,
      },
    }));
  };

  const setSocialHistoryMultipleValues = (name, value) => {
    setPrfData((prev) => ({
      ...prev,
      SocialHistory: {
        ...prev.SocialHistory,
        [name]: value,
      },
    }));
  };

  const handleObservationChange = (index, field, value) => {
    setPrfData((prevState) => {
      const updatedObservations = [...prevState.observations];
      updatedObservations[index] = {
        ...updatedObservations[index],
        [field]: value,
      };
      return { ...prevState, observations: updatedObservations };
    });
  };

  const addObservation = () => {
    setPrfData((prev) => ({
      ...prev,
      observations: [
        ...prev.observations,
        {
          date_time: "",
          heart_rate: "",
          blood_pressure: "",
          oxygen_saturation: "",
          respiratory_rate: "",
          temperature: "",
          gcs: "",
          pain_score: "",
          ecg: "",
          comments: "",
        },
      ],
    }));
  };

  // Function to handle adding defibrillation actions
  const addDefibAction = () => {
    setPrfData((prev) => ({
      ...prev,
      ClinicalInterventions: {
        ...prev.ClinicalInterventions,
        defibrillation: [
          ...prev.ClinicalInterventions.defibrillation,
          {
            time: "",
            pad_placement: "",
            rhythm: "",
            shocks_delivered: "",
          },
        ],
      },
    }));
  };

  const handleDefibActionChange = (index, name, value) => {
    const updatedDefib = prfData.ClinicalInterventions.defibrillation.map(
      (action, i) => (i === index ? { ...action, [name]: value } : action)
    );
    setPrfData((prev) => ({
      ...prev,
      ClinicalInterventions: {
        ...prev.ClinicalInterventions,
        defibrillation: updatedDefib,
      },
    }));
  };

  const addDrugAction = () => {
    setPrfData((prev) => ({
      ...prev,
      ClinicalInterventions: {
        ...prev.ClinicalInterventions,
        drugs_administered: [
          ...prev.ClinicalInterventions.drugs_administered,
          {
            drug_name: "",
            time_administered: "",
          },
        ],
      },
    }));
  };

  const handleDrugActionChange = (index, name, value) => {
    const updatedDrugs = prfData.ClinicalInterventions.drugs_administered.map(
      (drug, i) => (i === index ? { ...drug, [name]: value } : drug)
    );
    setPrfData((prev) => ({
      ...prev,
      ClinicalInterventions: {
        ...prev.ClinicalInterventions,
        drugs_administered: updatedDrugs,
      },
    }));
  };

  return (
    <PRFContext.Provider
      value={{
        prfData,
        setPrfData,
        handleInputChange,
        setAllergies,
        setMedications,
        setSocialHistoryMultipleValues,
        handleObservationChange,
        addObservation,
        addDefibAction,
        handleDefibActionChange,
        addDrugAction,
        handleDrugActionChange,
      }}
    >
      {children}
    </PRFContext.Provider>
  );
};

// Hook to use the PRFContext
export const usePRF = () => {
  return useContext(PRFContext);
};
