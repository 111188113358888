import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  TablePagination,
} from "@mui/material";
import apiClient from "../../utils/requestHandler";

const AuditLogsPage = () => {
  const [auditLogs, setAuditLogs] = useState([]);
  const [filters, setFilters] = useState({
    prfId: "",
    userId: "",
    action: "",
    dateFrom: "",
    dateTo: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Fetch audit logs from the API
  const fetchAuditLogs = async () => {
    setLoading(true);
    try {
      const response = await apiClient.get("/api/audit-log", {
        params: filters,
      });
      setAuditLogs(response.data);
    } catch (error) {
      console.error("Error fetching audit logs:", error);
      setError("Failed to fetch audit logs.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAuditLogs();
  }, [filters]);

  // Handle filtering input changes
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Parse and format the details field
  const parseDetails = (details) => {
    try {
      // Step 1: Clean the string by trimming spaces and quotes
      let cleaned = details.trim();

      // Step 2: Handle double-escaped quotes and other escape sequences
      cleaned = cleaned
        .replace(/^["'](.+(?=["']$))["']$/, "$1") // Remove leading/trailing quotes
        .replace(/\\\\/g, "\\") // Convert double backslashes to a single one
        .replace(/\\"/g, '"') // Handle escaped double quotes
        .replace(/\\'/g, "'"); // Handle escaped single quotes

      // Step 3: Attempt to parse the cleaned string
      const parsed = JSON.parse(cleaned);

      // Step 4: Return pretty-printed JSON if parsed successfully
      return typeof parsed === "object"
        ? JSON.stringify(parsed, null, 2)
        : parsed;
    } catch (error) {
      console.error("Error parsing details:", error.message);
      return details; // Return raw details if parsing fails
    }
  };

  // Render the audit logs in a table
  return (
    <div style={{ padding: "20px" }}>
      <h2>Audit Logs Report</h2>

      {/* Filter Inputs */}
      <div
        style={{
          display: "flex",
          gap: "20px",
          marginBottom: "20px",
          flexWrap: "wrap",
        }}
      >
        <TextField
          label="PRF ID"
          name="prfId"
          variant="outlined"
          size="small"
          value={filters.prfId}
          onChange={handleFilterChange}
        />
        <TextField
          label="User ID"
          name="userId"
          variant="outlined"
          size="small"
          value={filters.userId}
          onChange={handleFilterChange}
        />
        <TextField
          label="Action"
          name="action"
          variant="outlined"
          size="small"
          value={filters.action}
          onChange={handleFilterChange}
        />
        <TextField
          label="Date From"
          name="dateFrom"
          type="date"
          InputLabelProps={{ shrink: true }}
          size="small"
          variant="outlined"
          value={filters.dateFrom}
          onChange={handleFilterChange}
        />
        <TextField
          label="Date To"
          name="dateTo"
          type="date"
          InputLabelProps={{ shrink: true }}
          size="small"
          variant="outlined"
          value={filters.dateTo}
          onChange={handleFilterChange}
        />
        <Button variant="contained" color="primary" onClick={fetchAuditLogs}>
          Filter
        </Button>
      </div>

      {/* Error Message */}
      {error && <p style={{ color: "red" }}>{error}</p>}

      {/* Audit Logs Table */}
      {loading ? (
        <CircularProgress />
      ) : (
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>PRF ID</TableCell>
                  <TableCell>User Email</TableCell>
                  <TableCell>Action</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {auditLogs
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((log) => (
                    <TableRow key={log.id}>
                      <TableCell>{log.prf?.custom_prf_id || "N/A"}</TableCell>
                      <TableCell>{log.user?.email || "N/A"}</TableCell>
                      <TableCell>{log.action_type}</TableCell>
                      <TableCell>
                        {new Date(log.date_time).toLocaleString()}
                      </TableCell>
                      <TableCell>{parseDetails(log.changed_fields)}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Pagination */}
          <TablePagination
            component="div"
            count={auditLogs.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </Paper>
      )}
    </div>
  );
};

export default AuditLogsPage;
