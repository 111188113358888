import React, { useEffect, useState } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import apiClient from "../../utils/requestHandler";
import PrintPRF from "../../utils/PrintPRF";
import { usePRF } from "../../utils/PRFContext";

const PRFDetails = () => {
  const { prf_id } = useParams(); // Get prf_id from the URL
  const { prfData, setPrfData } = usePRF(); // Use PRF context to manage state
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchPRFDetails = async () => {
      try {
        const response = await apiClient.get(`/api/prf/${prf_id}`);
        console.log("API Response:", response.data); // Debugging

        if (response.data) {
          setPrfData((prevData) => ({
            ...prevData,
            incidentDetails: {
              event_name: response.data.event?.name || "",
              incident_date: response.data.incident_date || "",
              incident_location: response.data.incident_location || "",
              chief_complaint: response.data.chief_complaint || "",
              history_of_presenting_complaint:
                response.data.history_of_presenting_complaint || "",
              scene_description: response.data.scene_description || "",
            },
            demographics: {
              name: response.data.patient?.name || "",
              dob: response.data.patient?.dob || "",
              gender: response.data.patient?.gender || "",
              address: response.data.patient?.address || "",
              contact_number: response.data.patient?.contact_number || "",
              next_of_kin_name: response.data.patient?.next_of_kin_name || "-",
              next_of_kin_contact:
                response.data.patient?.next_of_kin_contact || "-",
              relationship_to_nok:
                response.data.patient?.relationship_to_nok || "-",
              past_medical_history:
                response.data.patient?.past_medical_history || "-",
              medications: response.data.patient?.medications || "-",
              allergies: response.data.patient?.allergies || "-",
            },
            PresentingComplaint: {
              presenting_complaint:
                response.data.PresentingComplaint?.presenting_complaint || "",
              history_of_presenting_complaint:
                response.data.PresentingComplaint
                  ?.history_of_presenting_complaint || "",
              allergies: response.data.PresentingComplaint?.allergies || [],
              medications: response.data.PresentingComplaint?.medications || [],
              past_medical_history:
                response.data.PresentingComplaint?.past_medical_history || "",
              last_oral_intake:
                response.data.PresentingComplaint?.last_oral_intake || "",
              family_history:
                response.data.PresentingComplaint?.family_history || "",
            },
            PrimarySurvey: {
              consent: response.data.PrimarySurvey?.consent || "",
              understanding: response.data.PrimarySurvey?.understanding || "",
              retaining: response.data.PrimarySurvey?.retaining || "",
              weighing: response.data.PrimarySurvey?.weighing || "",
              communicating: response.data.PrimarySurvey?.communicating || "",
              capacity_conclusion:
                response.data.PrimarySurvey?.capacity_conclusion || "",
              involvement: response.data.PrimarySurvey?.involvement || "",
              wishes_feelings:
                response.data.PrimarySurvey?.wishes_feelings || "",
              understand_consequences:
                response.data.PrimarySurvey?.understand_consequences || "",
              least_restrictive_option:
                response.data.PrimarySurvey?.least_restrictive_option || "",
              catastrophic_haemorrhage:
                response.data.PrimarySurvey?.catastrophic_haemorrhage || "",
              cervical_spine: response.data.PrimarySurvey?.cervical_spine || "",
              airway: response.data.PrimarySurvey?.airway || "",
              breathing: response.data.PrimarySurvey?.breathing || [],
              circulation: response.data.PrimarySurvey?.circulation || [],
            },
            SocialHistory: {
              marital_status: response.data.socialHistory?.marital_status || "",
              home_circumstances:
                response.data.socialHistory?.home_circumstances || "",
              level_of_support:
                response.data.socialHistory?.level_of_support || "",
              risk_factors: response.data.socialHistory?.risk_factors || [],
              disability: response.data.socialHistory?.disability || [],
              clinical_frailty:
                response.data.socialHistory?.clinical_frailty || "",
            },
            SecondarySurvey: {
              medical: {
                respiratory: response.data.secondarySurvey?.respiratory || "",
                cardiac: response.data.secondarySurvey?.cardiac || "",
                neurological: response.data.secondarySurvey?.neurological || "",
                gastrointestinal:
                  response.data.secondarySurvey?.gastrointestinal || "",
                urinary: response.data.secondarySurvey?.urinary || "",
                obs_gynae: response.data.secondarySurvey?.obs_gynae || "",
              },
              trauma: {
                msk_injury_assessment:
                  response.data.secondarySurvey?.msk_injury_assessment || "",
                rtc_documentation:
                  response.data.secondarySurvey?.rtc_documentation || "",
                head: response.data.secondarySurvey?.head || "",
                neck: response.data.secondarySurvey?.neck || "",
                chest: response.data.secondarySurvey?.chest || "",
                abdo: response.data.secondarySurvey?.abdo || "",
                pelvis: response.data.secondarySurvey?.pelvis || "",
                upper_limb: response.data.secondarySurvey?.upper_limb || "",
                lower_limb: response.data.secondarySurvey?.lower_limb || "",
                back: response.data.secondarySurvey?.back || "",
              },
              psych: {
                harm: response.data.secondarySurvey?.harm || "",
                overdose: response.data.secondarySurvey?.overdose || "",
                physical: response.data.secondarySurvey?.physical || "",
                behavioral: response.data.secondarySurvey?.behavioral || "",
                suicidal: response.data.secondarySurvey?.suicidal || "",
                speech: response.data.secondarySurvey?.speech || "",
                thoughts: response.data.secondarySurvey?.thoughts || "",
                mood: response.data.secondarySurvey?.mood || "",
              },
            },
            ClinicalInterventions: {
              airway: response.data.ClinicalIntervention?.airway || [],
              cpr_providers:
                response.data.ClinicalIntervention?.cpr_providers || [],
              defibrillation:
                response.data.ClinicalIntervention?.defibrillation || [],
              drugs_administered:
                response.data.ClinicalIntervention?.drugs_administered || [],
              ventilation_method:
                response.data.ClinicalIntervention?.ventilation_method || "",
              iv_access: response.data.ClinicalIntervention?.iv_access || "",
              io_access: response.data.ClinicalIntervention?.io_access || "",
              splints: response.data.ClinicalIntervention?.splints || [],
              wound_treatments:
                response.data.ClinicalIntervention?.wound_treatments || [],
              advanced_interventions:
                response.data.ClinicalIntervention?.advanced_interventions ||
                [],
              medications_administered:
                response.data.ClinicalIntervention?.medications_administered ||
                "",
            },
            DispositionOutcome: {
              disposition_outcome:
                response.data.dispositionOutcome?.disposition_outcome || "",
              time_left_dc_care:
                response.data.dispositionOutcome?.time_left_dc_care || "",
              discharging_clinician:
                response.data.dispositionOutcome?.discharging_clinician || "",
              clinician_consulted_name:
                response.data.dispositionOutcome?.clinician_consulted_name ||
                "",
              clinician_consulted_role:
                response.data.dispositionOutcome?.clinician_consulted_role ||
                "",
              clinical_discussion_summary:
                response.data.dispositionOutcome?.clinical_discussion_summary ||
                "",
            },
            observations: response.data.observations || [],
            status: response.data.status || "",
            custom_prf_id: response.data.custom_prf_id || "",
          }));
        }
      } catch (error) {
        console.error("Error fetching PRF details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPRFDetails();
  }, [prf_id, setPrfData]);
  // Fetch PRF details when prf_id changes

  return (
    <Box p={3}>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ fontWeight: "bold", textAlign: "center", mb: 4 }}
      >
        PRF Details
      </Typography>

      {loading ? (
        <CircularProgress /> // Show loading spinner while data is being fetched
      ) : prfData ? (
        <PrintPRF prfData={prfData} /> // Render detailed PRF data
      ) : (
        <Typography>No PRF data found.</Typography> // Handle case where no data is found
      )}
    </Box>
  );
};

export default PRFDetails;
