import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import apiClient from "../../utils/requestHandler";

const IMSDashboard = () => {
  const [totalInventory, setTotalInventory] = useState(0);
  const [lowStockItems, setLowStockItems] = useState([]);
  const [expiringBatches, setExpiringBatches] = useState([]);

  // Fetch Dashboard Data
  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        // Fetch total inventory count
        const inventoryRes = await apiClient.get("/api/inventory");
        setTotalInventory(inventoryRes.data.length);

        // Fetch low stock items
        const lowStockRes = await apiClient.get("/api/inventory/lowStock");
        setLowStockItems(lowStockRes.data);

        // Fetch expiring batches
        const expiringBatchesRes = await apiClient.get("/api/batches/expiring");
        setExpiringBatches(expiringBatchesRes.data);
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    };

    fetchDashboardData();
  }, []);

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Inventory Management Dashboard
      </Typography>

      {/* Top-Level Metrics */}
      <Grid container spacing={3}>
        {/* Total Inventory */}
        <Grid item xs={12} sm={4}>
          <Card>
            <CardContent>
              <Typography variant="h6">Total Inventory Items</Typography>
              <Typography variant="h4">{totalInventory}</Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Low Stock Alerts */}
        <Grid item xs={12} sm={4}>
          <Card>
            <CardContent>
              <Typography variant="h6">Low Stock Alerts</Typography>
              <Typography variant="h4">{lowStockItems.length}</Typography>
              <Typography variant="body2">
                {lowStockItems.slice(0, 3).map((item, index) => (
                  <span key={index}>{item.item_name}, </span>
                ))}
              </Typography>
              <Button component={Link} to="/inventory/low-stock" size="small">
                View All
              </Button>
            </CardContent>
          </Card>
        </Grid>

        {/* Expiring Batches */}
        <Grid item xs={12} sm={4}>
          <Card>
            <CardContent>
              <Typography variant="h6">Upcoming Expiries</Typography>
              <Typography variant="h4">{expiringBatches.length}</Typography>
              <Typography variant="body2">
                {expiringBatches.slice(0, 3).map((batch, index) => (
                  <span key={index}>
                    Batch {batch.batch_number} ({batch.expiry_date}),{" "}
                  </span>
                ))}
              </Typography>
              <Button
                component={Link}
                to="/inventory/expiring-batches"
                size="small"
              >
                View All
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Navigation Links */}
      <Box sx={{ mt: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Button
              component={Link}
              to="/inventory/boms"
              fullWidth
              variant="contained"
            >
              View BOMs
            </Button>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              component={Link}
              to="/inventory/items"
              fullWidth
              variant="contained"
            >
              View Stock
            </Button>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              component={Link}
              to="/inventory/suppliers"
              fullWidth
              variant="contained"
            >
              View Suppliers
            </Button>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              component={Link}
              to="/inventory/usage-logs"
              fullWidth
              variant="contained"
            >
              View Usage Logs
            </Button>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              component={Link}
              to="/inventory/transfer-logs"
              fullWidth
              variant="contained"
            >
              View Transfer Logs
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default IMSDashboard;
