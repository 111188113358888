import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Paper,
  Modal,
  TextField,
  CircularProgress,
} from "@mui/material";
import apiClient from "../../utils/requestHandler";
import { useLoading } from "../../contexts/LoadingContext"; // Import the useLoading hook

const modalStyles = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
  backgroundColor: "#fff",
  borderRadius: "10px",
  boxShadow: 24,
  width: "80%",
  maxWidth: 500,
  maxHeight: "80vh",
  overflowY: "auto",
};

const EventDetails = () => {
  const { eventID } = useParams();
  const { loading, setLoading } = useLoading(); // Use useLoading
  const [event, setEvent] = useState(null);
  const [error, setError] = useState("");
  const [userRole, setUserRole] = useState("");
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editedEvent, setEditedEvent] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserAndEvent = async () => {
      setLoading(true);
      try {
        const userRes = await apiClient.get("/api/auth/user", {
          withCredentials: true,
        });
        setUserRole(userRes.data.role);

        const eventRes = await apiClient.get(`/api/client/event/${eventID}`, {
          withCredentials: true,
        });
        setEvent(eventRes.data);
        setEditedEvent(eventRes.data);
      } catch (err) {
        console.error("Error fetching event or user details:", err);
        setError("Your session has expired. Please log in again.");
        setTimeout(() => {
          navigate("/"); // Redirect to login page
        }, 3000);
      } finally {
        setLoading(false);
      }
    };

    fetchUserAndEvent();
  }, [eventID, navigate, setLoading]);

  const handleEditModalOpen = () => setEditModalOpen(true);
  const handleEditModalClose = () => setEditModalOpen(false);

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditedEvent((prev) => ({ ...prev, [name]: value }));
  };

  const handleEditSubmit = async () => {
    setLoading(true);
    try {
      await apiClient.put(`/api/client/event/${eventID}`, editedEvent, {
        withCredentials: true,
      });
      setEvent(editedEvent);
      handleEditModalClose();
    } catch (err) {
      console.error("Error updating event:", err.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );

  if (error)
    return (
      <Box sx={{ textAlign: "center", marginTop: "50px" }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );

  if (!event) return null;

  return (
    <Box sx={{ p: 4, backgroundColor: "#f5f5f5", minHeight: "100vh" }}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={8} lg={6}>
          <Paper elevation={3} sx={{ borderRadius: 2 }}>
            <Card>
              <CardContent>
                <Typography
                  variant="h4"
                  sx={{ mb: 3, textAlign: "center", fontWeight: "bold" }}
                >
                  {event.name}
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  <strong>Date:</strong>{" "}
                  {new Date(event.date).toLocaleDateString()}
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  <strong>Location:</strong>{" "}
                  {event.location || "No location specified"}
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  <strong>Status:</strong> {event.status}
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  <strong>Description:</strong>{" "}
                  {event.description || "No description available"}
                </Typography>
                <Box sx={{ mt: 4 }}>
                  <Grid container spacing={2} justifyContent="center">
                    <Grid item>
                      <Button
                        component={Link}
                        to={`/client/event/${eventID}/dashboard`}
                        variant="contained"
                        color="primary"
                      >
                        View Dashboard
                      </Button>
                    </Grid>
                    {userRole === "admin" && (
                      <>
                        <Grid item>
                          <Button
                            onClick={handleEditModalOpen}
                            variant="contained"
                            color="secondary"
                          >
                            Edit Event
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            component={Link}
                            to={`/client/event/${eventID}/delete`}
                            variant="contained"
                            color="error"
                          >
                            Delete Event
                          </Button>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Paper>
        </Grid>
      </Grid>

      {/* Edit Event Modal */}
      <Modal open={editModalOpen} onClose={handleEditModalClose}>
        <Box sx={modalStyles}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Edit Event
          </Typography>
          <TextField
            label="Event Name"
            name="name"
            value={editedEvent.name || ""}
            onChange={handleEditChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Date"
            name="date"
            type="date"
            value={editedEvent.date || ""}
            onChange={handleEditChange}
            fullWidth
            sx={{ mb: 2 }}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Location"
            name="location"
            value={editedEvent.location || ""}
            onChange={handleEditChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Status"
            name="status"
            value={editedEvent.status || ""}
            onChange={handleEditChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Description"
            name="description"
            value={editedEvent.description || ""}
            onChange={handleEditChange}
            fullWidth
            multiline
            rows={4}
            sx={{ mb: 2 }}
          />
          <Button
            onClick={handleEditSubmit}
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            Save Changes
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default EventDetails;
