import React, { useState } from "react";
import {
  Box,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Button,
  TextField,
  Checkbox,
  OutlinedInput,
  FormControlLabel,
} from "@mui/material";
import { usePRF } from "../../utils/PRFContext";
import apiClient from "../../utils/requestHandler";

const adviceTemplateOptions = [
  {
    value: "rest_hydration",
    label: "Rest and Hydration",
    content: "Please rest and stay hydrated. Drink plenty of fluids...",
  },
  {
    value: "followup_gp",
    label: "Follow-up with GP",
    content: "We recommend that you follow up with your GP in the next few days...",
  },
  // Add other templates as needed
];

const AdviceGiven = () => {
  const { prfData } = usePRF();
  const [emailToPatient, setEmailToPatient] = useState(false);
  const [patientEmail, setPatientEmail] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [templateContent, setTemplateContent] = useState("");

  const handleTemplateChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedTemplate(selectedValue);

    const selectedOption = adviceTemplateOptions.find(
      (option) => option.value === selectedValue
    );
    setTemplateContent(selectedOption ? selectedOption.content : "");
  };

  const handleSendEmail = async () => {
    if (emailToPatient && patientEmail && templateContent) {
      try {
        const response = await apiClient.post('/api/send-email',{
          withCredentials: true,
          to: patientEmail,
          name: prfData.demographics.name || 'Patient',
          subject: 'Advice Given',
          content: templateContent,
          senderDetails: {
          },
        });
  
        console.log('Advice email sent successfully', response.data);
      } catch (error) {
        console.error('Failed to send email:', error);
      }
    } else {
      console.warn('Please fill in all required fields.');
    }
  };
  
  

  return (
    <Box>
      {/* Advice Given - Select a Template */}
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="advice-template-label">Advice Given</InputLabel>
        <Select
          labelId="advice-template-label"
          name="advice_given"
          value={selectedTemplate}
          onChange={handleTemplateChange}
          input={<OutlinedInput label="Advice Given" />}
        >
          {adviceTemplateOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Editable Template Content */}
      {selectedTemplate && (
        <TextField
          label="Email Content"
          value={templateContent}
          onChange={(e) => setTemplateContent(e.target.value)}
          fullWidth
          multiline
          rows={10}
          variant="outlined"
          sx={{ mb: 2 }}
        />
      )}

      {/* Email Input */}
      <TextField
        label="Patient Email"
        value={patientEmail}
        onChange={(e) => setPatientEmail(e.target.value)}
        fullWidth
        sx={{ mb: 2 }}
      />

      {/* Option to Email Advice */}
      <FormControlLabel
        control={
          <Checkbox
            checked={emailToPatient}
            onChange={() => setEmailToPatient(!emailToPatient)}
          />
        }
        label="Email to Patient"
      />

      {/* Button to Send Email */}
      <Button
        variant="contained"
        onClick={handleSendEmail}
        sx={{ mt: 2 }}
        disabled={!emailToPatient || !patientEmail || !templateContent}
      >
        Send Advice Email
      </Button>
    </Box>
  );
};

export default AdviceGiven;
