// PrintPRF.jsx

import React from "react";
import { usePRF } from "./PRFContext";

const PrintPRF = () => {
  const { prfData } = usePRF(); // Access prfData from context

  if (!prfData) {
    return <p>No PRF data available to print.</p>;
  }

  const containerStyle = {
    fontFamily: "Arial, sans-serif",
    margin: "20px",
    padding: "10px",
    color: "#333",
  };

  const sectionStyle = {
    border: `1px solid ${
      prfData?.incidentDetails?.custom_prf_id ? "#1976d2" : "#673ab7"
    }`,
    padding: "15px",
    marginBottom: "20px",
    borderRadius: "8px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#f9f9f9",
    pageBreakInside: "avoid", // Prevents page breaks inside the section
  };

  const titleStyle = {
    borderBottom: `2px solid ${
      prfData?.incidentDetails?.custom_prf_id ? "#1976d2" : "#673ab7"
    }`,
    paddingBottom: "5px",
    marginBottom: "10px",
    fontSize: "20px",
    fontWeight: "bold",
    color: "#333",
  };

  const subsectionTitleStyle = {
    borderBottom: "1px solid #ccc",
    paddingBottom: "5px",
    marginTop: "20px",
    marginBottom: "10px",
    fontSize: "18px",
    fontWeight: "bold",
    color: "#555",
  };

  const twoColumn = {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
  };

  const column = {
    flex: "1 1 45%",
    minWidth: "250px",
  };

  const dividerStyle = {
    borderBottom: "1px solid #673ab7",
    margin: "20px 0",
  };

  const footerStyle = {
    fontSize: "12px",
    color: "#333",
    paddingTop: "10px",
    marginTop: "20px",
    textAlign: "center",
    // Ensure the footer is at the bottom when printed
    position: "relative",
    bottom: "0",
    width: "100%",
    pageBreakInside: "avoid",
  };

  // Helper function to check if ROLE section has any data
  const isROLEEmpty = () => {
    if (!prfData?.ROLE) return true;
    const roleValues = Object.values(prfData.ROLE);
    // Exclude fields that are not boolean or string
    const relevantValues = roleValues.filter(
      (value) => typeof value === "boolean" || typeof value === "string"
    );
    return relevantValues.every(
      (value) => value === false || value === "" || value === null
    );
  };

  // Get current year for footer
  const currentYear = new Date().getFullYear();

  // Styles for page breaks
  const pageBreakStyle = {
    pageBreakAfter: "always",
    breakAfter: "page",
  };

  // Helper function to calculate age from date of birth
  const getAge = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    if (
      m < 0 ||
      (m === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  // Helper function to generate the narrative
  const generateNarrative = (data) => {
    const patientName = data?.patient?.name || "The patient";
    const age = data?.patient?.dob ? getAge(data.patient.dob) : "";
    const gender = data?.patient?.gender || "";

    const incidentDate = data?.incidentDetails?.incident_date
      ? new Date(data.incidentDetails.incident_date).toLocaleDateString()
      : "an unknown date";

    const incidentLocation =
      data?.incidentDetails?.incident_location || "an unknown location";
    const chiefComplaint =
      data?.incidentDetails?.chief_complaint || "an unknown complaint";

    const presentingComplaint =
      data?.PresentingComplaint?.presenting_complaint || "";

    const clinicalImpression = data?.clinical_impression?.length
      ? data.clinical_impression.join(", ")
      : "";

    const dispositionOutcome =
      data?.DispositionOutcome?.disposition_outcome || "";

    // Build the narrative

    let narrative = `${patientName}`;

    if (age) {
      narrative += `, a ${age}-year-old`;
      if (gender) {
        narrative += ` ${gender}`;
      }
    } else if (gender) {
      narrative += `, a ${gender}`;
    }

    narrative += `, was seen on ${incidentDate} at ${incidentLocation}. `;

    if (chiefComplaint) {
      narrative += `The chief complaint was ${chiefComplaint}. `;
    }

    if (presentingComplaint) {
      narrative += `Presenting complaint: ${presentingComplaint}. `;
    }

    if (clinicalImpression) {
      narrative += `Clinical impression: ${clinicalImpression}. `;
    }

    if (dispositionOutcome) {
      narrative += `Disposition outcome: ${dispositionOutcome}. `;
    }

    return narrative;
  };

  return (
    <div style={containerStyle}>
      {/* Header */}
      <div>
        <div style={twoColumn}>
          <div style={column}>
            <h1 style={{ fontWeight: "bold", color: "#1976d2" }}>
              PRF Report: #{prfData?.incidentDetails?.custom_prf_id || "N/A"}
            </h1>
            <p style={{ fontSize: "20px", color: "#9c27b0" }}>
              DC Event Medical Ltd.
            </p>
          </div>
          <div style={{ ...column, textAlign: "right" }}>
            <img
              src="https://dceventmedical.co.uk/static/media/DCLogo.cf85b7c3b87b0326262a.png"
              alt="DC Event Medical Logo"
              style={{ maxHeight: "80px" }}
            />
          </div>
        </div>
        <hr style={{ borderTop: "3px solid #000", marginBottom: "20px" }} />

        {/* Automated Narrative Section */}
        <div style={sectionStyle}>
          <h2 style={titleStyle}>Summary</h2>
          <p>{generateNarrative(prfData)}</p>
        </div>

        {/* Incident Details Section */}
        <div style={{ ...sectionStyle, ...pageBreakStyle }}>
          <h2 style={titleStyle}>Incident Details</h2>
          <div style={twoColumn}>
            <div style={column}>
              <p>
                <strong>Date:</strong>{" "}
                {prfData?.incidentDetails?.incident_date
                  ? new Date(
                      prfData.incidentDetails.incident_date
                    ).toLocaleDateString()
                  : "N/A"}
              </p>
              <p>
                <strong>Event Name:</strong>{" "}
                {prfData?.incidentDetails?.event_name || "N/A"}
              </p>
              <p>
                <strong>Incident Location:</strong>{" "}
                {prfData?.incidentDetails?.incident_location || "N/A"}
              </p>
              <p>
                <strong>Chief Complaint:</strong>{" "}
                {prfData?.incidentDetails?.chief_complaint || "N/A"}
              </p>
            </div>
            <div style={column}>
              <p>
                <strong>Dispatch ID:</strong>{" "}
                {prfData?.incidentDetails?.custom_prf_id || "N/A"}
              </p>
              <p>
                <strong>Time:</strong>{" "}
                {prfData?.incidentDetails?.incident_date
                  ? new Date(
                      prfData.incidentDetails.incident_date
                    ).toLocaleTimeString()
                  : "N/A"}
              </p>
            </div>
          </div>
        </div>

        {/* Patient Details Section */}
        <div style={sectionStyle}>
          <h2 style={titleStyle}>Patient Details</h2>
          <div style={twoColumn}>
            <div style={column}>
              <p>
                <strong>Name:</strong> {prfData?.patient?.name || "N/A"}
              </p>
              <p>
                <strong>Date of Birth:</strong>{" "}
                {prfData?.patient?.dob
                  ? new Date(prfData.patient.dob).toLocaleDateString()
                  : "N/A"}
              </p>
              <p>
                <strong>Contact Number:</strong>{" "}
                {prfData?.patient?.contact_number || "N/A"}
              </p>
              <p>
                <strong>Address:</strong> {prfData?.patient?.address || "N/A"}
              </p>
            </div>
            <div style={column}>
              <p>
                <strong>Gender:</strong> {prfData?.patient?.gender || "N/A"}
              </p>
              <p>
                <strong>Next of Kin:</strong>{" "}
                {prfData?.patient?.next_of_kin_name || "N/A"}
              </p>
              <p>
                <strong>NOK Contact:</strong>{" "}
                {prfData?.patient?.next_of_kin_contact || "N/A"}
              </p>
              <p>
                <strong>Relationship to NOK:</strong>{" "}
                {prfData?.patient?.relationship_to_nok || "N/A"}
              </p>
            </div>
          </div>
        </div>

        {/* Presenting Complaint Section */}
        <div style={sectionStyle}>
          <h2 style={titleStyle}>Presenting Complaint</h2>
          <div style={twoColumn}>
            <div style={column}>
              <p>
                <strong>Presenting Complaint:</strong>{" "}
                {prfData?.PresentingComplaint?.presenting_complaint || "N/A"}
              </p>
              <p>
                <strong>Allergies:</strong>{" "}
                {prfData?.PresentingComplaint?.allergies || "N/A"}
              </p>
              <p>
                <strong>Medications:</strong>{" "}
                {prfData?.PresentingComplaint?.medications || "N/A"}
              </p>
              <p>
                <strong>Past Medical History:</strong>{" "}
                {prfData?.PresentingComplaint?.past_medical_history || "N/A"}
              </p>
            </div>
            <div style={column}>
              <p>
                <strong>History of Presenting Complaint:</strong>{" "}
                {prfData?.PresentingComplaint
                  ?.history_of_presenting_complaint || "N/A"}
              </p>
              <p>
                <strong>Last Oral Intake:</strong>{" "}
                {prfData?.PresentingComplaint?.last_oral_intake || "N/A"}
              </p>
              <p>
                <strong>Family History:</strong>{" "}
                {prfData?.PresentingComplaint?.family_history || "N/A"}
              </p>
            </div>
          </div>
        </div>

        {/* Social History Section */}
        <div style={{ ...sectionStyle, ...pageBreakStyle }}>
          <h2 style={titleStyle}>Social History</h2>
          <div style={twoColumn}>
            <div style={column}>
              <p>
                <strong>Marital Status:</strong>{" "}
                {prfData?.SocialHistory?.marital_status || "N/A"}
              </p>
              <p>
                <strong>Home Circumstances:</strong>{" "}
                {prfData?.SocialHistory?.home_circumstances || "N/A"}
              </p>
              <p>
                <strong>Support:</strong>{" "}
                {prfData?.SocialHistory?.support || "N/A"}
              </p>
              <p>
                <strong>Level of Support:</strong>{" "}
                {prfData?.SocialHistory?.level_of_support || "N/A"}
              </p>
              <p>
                <strong>Mobility:</strong>{" "}
                {prfData?.SocialHistory?.mobility || "N/A"}
              </p>
              <p>
                <strong>Disability:</strong>{" "}
                {prfData?.SocialHistory?.disability || "N/A"}
              </p>
            </div>
            <div style={column}>
              <p>
                <strong>Religious Affiliation:</strong>{" "}
                {prfData?.SocialHistory?.religious_affiliation || "N/A"}
              </p>
              <p>
                <strong>Risk Factors:</strong>{" "}
                {prfData?.SocialHistory?.risk_factors || "N/A"}
              </p>
              <p>
                <strong>Safeguarding Concerns:</strong>{" "}
                {prfData?.SocialHistory?.safeguarding_concerns || "N/A"}
              </p>
              <p>
                <strong>Clinical Frailty:</strong>{" "}
                {prfData?.SocialHistory?.clinical_frailty || "N/A"}
              </p>
              <p>
                <strong>Language:</strong>{" "}
                {prfData?.SocialHistory?.language || "N/A"}
              </p>
              <p>
                <strong>Interpreter Required:</strong>{" "}
                {prfData?.SocialHistory?.interpreter_required || "N/A"}
              </p>
            </div>
          </div>
        </div>

        {/* Secondary Survey Section */}
        <div style={sectionStyle}>
          <h2 style={titleStyle}>Secondary Survey</h2>

          {/* Medical Subsection */}
          <h3 style={subsectionTitleStyle}>Medical</h3>
          <div style={twoColumn}>
            <div style={column}>
              <p>
                <strong>Respiratory:</strong>{" "}
                {prfData?.SecondarySurvey?.respiratory || "N/A"}
              </p>
              <p>
                <strong>Cardiac:</strong>{" "}
                {prfData?.SecondarySurvey?.cardiac || "N/A"}
              </p>
              <p>
                <strong>Neurological:</strong>{" "}
                {prfData?.SecondarySurvey?.neurological || "N/A"}
              </p>
            </div>
            <div style={column}>
              <p>
                <strong>Gastrointestinal:</strong>{" "}
                {prfData?.SecondarySurvey?.gastrointestinal || "N/A"}
              </p>
              <p>
                <strong>Urinary:</strong>{" "}
                {prfData?.SecondarySurvey?.urinary || "N/A"}
              </p>
              <p>
                <strong>Obs/Gynae:</strong>{" "}
                {prfData?.SecondarySurvey?.obs_gynae || "N/A"}
              </p>
            </div>
          </div>

          {/* Trauma Subsection */}
          <h3 style={subsectionTitleStyle}>Trauma</h3>
          <div style={twoColumn}>
            <div style={column}>
              <p>
                <strong>MSK Injury Assessment:</strong>{" "}
                {prfData?.SecondarySurvey?.msk_injury_assessment || "N/A"}
              </p>
              <p>
                <strong>Head:</strong>{" "}
                {prfData?.SecondarySurvey?.head || "N/A"}
              </p>
              <p>
                <strong>Neck:</strong>{" "}
                {prfData?.SecondarySurvey?.neck || "N/A"}
              </p>
              <p>
                <strong>Chest:</strong>{" "}
                {prfData?.SecondarySurvey?.chest || "N/A"}
              </p>
            </div>
            <div style={column}>
              <p>
                <strong>Abdomen:</strong>{" "}
                {prfData?.SecondarySurvey?.abdo || "N/A"}
              </p>
              <p>
                <strong>Pelvis:</strong>{" "}
                {prfData?.SecondarySurvey?.pelvis || "N/A"}
              </p>
              <p>
                <strong>Upper Limb:</strong>{" "}
                {prfData?.SecondarySurvey?.upper_limb || "N/A"}
              </p>
              <p>
                <strong>Lower Limb:</strong>{" "}
                {prfData?.SecondarySurvey?.lower_limb || "N/A"}
              </p>
            </div>
          </div>
          <div style={twoColumn}>
            <div style={column}>
              <p>
                <strong>Back:</strong>{" "}
                {prfData?.SecondarySurvey?.back || "N/A"}
              </p>
              <p>
                <strong>Mechanism of Injury:</strong>{" "}
                {prfData?.SecondarySurvey?.mechanism_of_injury || "N/A"}
              </p>
            </div>
            <div style={column}>
              <p>
                <strong>RTC Documentation:</strong>{" "}
                {prfData?.SecondarySurvey?.rtc_documentation || "N/A"}
              </p>
              <p>
                <strong>Free Text:</strong>{" "}
                {prfData?.SecondarySurvey?.freetext || "N/A"}
              </p>
            </div>
          </div>

          {/* Psychological Subsection */}
          <h3 style={subsectionTitleStyle}>Psychological</h3>
          <div style={twoColumn}>
            <div style={column}>
              <p>
                <strong>Harm:</strong>{" "}
                {prfData?.SecondarySurvey?.harm || "N/A"}
              </p>
              <p>
                <strong>Overdose:</strong>{" "}
                {prfData?.SecondarySurvey?.overdose || "N/A"}
              </p>
              <p>
                <strong>Physical:</strong>{" "}
                {prfData?.SecondarySurvey?.physical || "N/A"}
              </p>
              <p>
                <strong>Behavioral:</strong>{" "}
                {prfData?.SecondarySurvey?.behavioral || "N/A"}
              </p>
            </div>
            <div style={column}>
              <p>
                <strong>Suicidal:</strong>{" "}
                {prfData?.SecondarySurvey?.suicidal || "N/A"}
              </p>
              <p>
                <strong>Speech:</strong>{" "}
                {prfData?.SecondarySurvey?.speech || "N/A"}
              </p>
              <p>
                <strong>Thoughts:</strong>{" "}
                {prfData?.SecondarySurvey?.thoughts || "N/A"}
              </p>
              <p>
                <strong>Mood:</strong>{" "}
                {prfData?.SecondarySurvey?.mood || "N/A"}
              </p>
            </div>
          </div>
        </div>

        {/* Observations Section */}
        <div style={{ ...sectionStyle, ...pageBreakStyle }}>
          <h2 style={titleStyle}>Observations</h2>
          {prfData?.Observations?.length ? (
            prfData.Observations.map((obs, index) => (
              <div key={index}>
                <div style={twoColumn}>
                  <div style={column}>
                    <p>
                      <strong>Date/Time:</strong>{" "}
                      {obs.date_time
                        ? new Date(obs.date_time).toLocaleString()
                        : "N/A"}
                    </p>
                    <p>
                      <strong>Heart Rate:</strong>{" "}
                      {obs.heart_rate || "N/A"}
                    </p>
                    <p>
                      <strong>Blood Pressure:</strong>{" "}
                      {obs.blood_pressure || "N/A"}
                    </p>
                    <p>
                      <strong>Oxygen Saturation:</strong>{" "}
                      {obs.oxygen_saturation || "N/A"}
                    </p>
                  </div>
                  <div style={column}>
                    <p>
                      <strong>Respiratory Rate:</strong>{" "}
                      {obs.respiratory_rate || "N/A"}
                    </p>
                    <p>
                      <strong>Temperature:</strong>{" "}
                      {obs.temperature || "N/A"}
                    </p>
                    <p>
                      <strong>Pain Score:</strong>{" "}
                      {obs.pain_score || "N/A"}
                    </p>
                    <p>
                      <strong>GCS:</strong> {obs.gcs || "N/A"}
                    </p>
                  </div>
                </div>
                {index < prfData.Observations.length - 1 && (
                  <div style={dividerStyle}></div>
                )}
              </div>
            ))
          ) : (
            <p>No Observations Available</p>
          )}
        </div>

        {/* Clinical Interventions */}
        <div style={sectionStyle}>
          <h2 style={titleStyle}>Clinical Interventions</h2>
          <div style={twoColumn}>
            <div style={column}>
              <p>
                <strong>Airway:</strong>{" "}
                {prfData?.ClinicalInterventions?.airway
                  ? prfData.ClinicalInterventions.airway.join(", ")
                  : "N/A"}
              </p>
              <p>
                <strong>CPR Providers:</strong>{" "}
                {prfData?.ClinicalInterventions?.cpr_providers
                  ? prfData.ClinicalInterventions.cpr_providers.join(", ")
                  : "N/A"}
              </p>
              <p>
                <strong>Defibrillation:</strong>{" "}
                {prfData?.ClinicalInterventions?.defibrillation
                  ? prfData.ClinicalInterventions.defibrillation.join(", ")
                  : "N/A"}
              </p>
              <p>
                <strong>Drugs Administered:</strong>{" "}
                {prfData?.ClinicalInterventions?.drugs_administered
                  ? prfData.ClinicalInterventions.drugs_administered.join(", ")
                  : "N/A"}
              </p>
              <p>
                <strong>Ventilation Method:</strong>{" "}
                {prfData?.ClinicalInterventions?.ventilation_method || "N/A"}
              </p>
            </div>
            <div style={column}>
              <p>
                <strong>IV Access:</strong>{" "}
                {prfData?.ClinicalInterventions?.iv_access || "N/A"}
              </p>
              <p>
                <strong>IO Access:</strong>{" "}
                {prfData?.ClinicalInterventions?.io_access || "N/A"}
              </p>
              <p>
                <strong>Splints:</strong>{" "}
                {prfData?.ClinicalInterventions?.splints
                  ? prfData.ClinicalInterventions.splints.join(", ")
                  : "N/A"}
              </p>
              <p>
                <strong>Wound Treatments:</strong>{" "}
                {prfData?.ClinicalInterventions?.wound_treatments
                  ? prfData.ClinicalInterventions.wound_treatments.join(", ")
                  : "N/A"}
              </p>
              <p>
                <strong>Advanced Interventions:</strong>{" "}
                {prfData?.ClinicalInterventions?.advanced_interventions
                  ? prfData.ClinicalInterventions.advanced_interventions.join(
                      ", "
                    )
                  : "N/A"}
              </p>
            </div>
          </div>
        </div>

        {/* Clinical Impression */}
        <div style={sectionStyle}>
          <h2 style={titleStyle}>Clinical Impression</h2>
          <div>
            {prfData?.clinical_impression?.length
              ? prfData.clinical_impression.join(", ")
              : "N/A"}
          </div>
        </div>

        {/* Disposition Outcome */}
        <div style={{ ...sectionStyle, ...pageBreakStyle }}>
          <h2 style={titleStyle}>Disposition Outcome</h2>
          <div style={twoColumn}>
            <div style={column}>
              <p>
                <strong>Outcome:</strong>{" "}
                {prfData?.DispositionOutcome?.disposition_outcome || "N/A"}
              </p>
              <p>
                <strong>Time Left DC Care:</strong>{" "}
                {prfData?.DispositionOutcome?.time_left_dc_care
                  ? new Date(
                      prfData.DispositionOutcome.time_left_dc_care
                    ).toLocaleString()
                  : "N/A"}
              </p>
              <p>
                <strong>Discharging Clinician:</strong>{" "}
                {prfData?.DispositionOutcome?.discharging_clinician || "N/A"}
              </p>
            </div>
            <div style={column}>
              <p>
                <strong>Clinician Consulted Name:</strong>{" "}
                {prfData?.DispositionOutcome?.clinician_consulted_name ||
                  "N/A"}
              </p>
              <p>
                <strong>Clinician Consulted Role:</strong>{" "}
                {prfData?.DispositionOutcome?.clinician_consulted_role ||
                  "N/A"}
              </p>
              <p>
                <strong>Clinical Discussion Summary:</strong>{" "}
                {prfData?.DispositionOutcome?.clinical_discussion_summary ||
                  "N/A"}
              </p>
            </div>
          </div>
        </div>

        {/* ROLE Section - Conditionally Rendered */}
        {!isROLEEmpty() && (
          <div style={sectionStyle}>
            <h2 style={titleStyle}>Recognition of Life Extinct (ROLE)</h2>
            <div style={twoColumn}>
              <div style={column}>
                <p>
                  <strong>DNACPR:</strong>{" "}
                  {prfData?.ROLE?.dnacpr ? "Yes" : "No"}
                </p>
                <p>
                  <strong>Advanced Directive:</strong>{" "}
                  {prfData?.ROLE?.advanced_directive ? "Yes" : "No"}
                </p>
                <p>
                  <strong>Terminally Ill:</strong>{" "}
                  {prfData?.ROLE?.terminally_ill ? "Yes" : "No"}
                </p>
                {/* Include other ROLE fields as needed */}
              </div>
              <div style={column}>
                <p>
                  <strong>ROLE Time:</strong>{" "}
                  {prfData?.ROLE?.role_time
                    ? new Date(prfData.ROLE.role_time).toLocaleString()
                    : "N/A"}
                </p>
                <p>
                  <strong>Clinician Name:</strong>{" "}
                  {prfData?.ROLE?.role_clinician_name || "N/A"}
                </p>
                <p>
                  <strong>Clinician Grade:</strong>{" "}
                  {prfData?.ROLE?.role_clinician_grade || "N/A"}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Footer */}
      <div style={footerStyle}>
        <hr style={{ borderTop: "1px solid #ccc" }} />
        <p>
          <strong>DC Event Medical Ltd.</strong> | Registered in England No.{" "}
          <strong>11165671</strong> | ICO Registration No.{" "}
          <strong>ZB625778</strong>
        </p>
        <p>
          Registered Address: 4 Falcon Close, Fareham, Hampshire, PO16 8PL |
          Phone: 0330 058 8348 | Mobile: 07881 290082
        </p>
        <p>
          Email:{" "}
          <a href="mailto:liam@dceventmedical.co.uk">
            liam@dceventmedical.co.uk
          </a>{" "}
          | Website:{" "}
          <a href="https://www.dceventmedical.co.uk">
            www.dceventmedical.co.uk
          </a>
        </p>
        <p>
          This document contains confidential medical information intended
          solely for the individual or entity to whom it is addressed.
          Unauthorized disclosure, copying, distribution, or reliance upon the
          contents of this document is strictly prohibited. All personal data is
          processed in compliance with GDPR and the Data Protection Act 2018.
        </p>
        <p>
          DC Event Medical Ltd. operates in alignment with ISO 27001 and ISO
          9001 standards, and follows Cyber Essentials guidelines to ensure the
          highest levels of information security and quality management. While
          not formally accredited by the Care Quality Commission (CQC), we
          adhere to their standards and best practices.
        </p>
        <p>
          For feedback or complaints about our services, please contact us at{" "}
          <a href="mailto:feedback@dceventmedical.co.uk">
            feedback@dceventmedical.co.uk
          </a>{" "}
          or call 0330 058 8348.
        </p>
        <p>© {currentYear} DC Event Medical Ltd. All rights reserved.</p>
      </div>
    </div>
  );
};

export default PrintPRF;
