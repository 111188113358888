import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import apiClient from "../../utils/requestHandler";
import "./PatientFlow.css";

function PatientFlow({ flowRates, user }) {
  const {
    triage,
    waiting,
    treatment,
    dischargedEvent,
    dischargedUTC,
    dischargedHome,
    awaitingTransport,
    transportedOwnMeans,
    transportedAmbulance,
  } = flowRates;

  const [openModal, setOpenModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [prfList, setPrfList] = useState([]);

  const handleOpenModal = (status) => {
    if (user?.role === "admin" || user?.role === "staff") {
      setSelectedStatus(status);
      fetchPRFsByStatus(status);
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setPrfList([]);
  };

  const fetchPRFsByStatus = async (status) => {
    try {
      const response = await apiClient.get(`/api/prfs?status=${status}`);
      setPrfList(response.data);
    } catch (error) {
      console.error("Error fetching PRFs:", error);
    }
  };

  const getBoxColor = (rate) => {
    if (rate > 10) return "#DC3545"; // NHS Alert Red
    if (rate > 5) return "#FFD100"; // NHS Yellow
    return "#28A745"; // NHS Green
  };

  const getPulseSpeed = (rate) => {
    if (rate >= 10) return "fast";
    if (rate >= 5) return "medium";
    return "slow";
  };

  return (
    <Box
      sx={{
        textAlign: "center",
        padding: 4,
        maxWidth: "1200px",
        margin: "0 auto",
      }}
    >
      <Typography
        variant="h5"
        sx={{ marginBottom: 2, fontWeight: "bold", color: "#005EB8" }}
      >
        Patient Flow Overview
      </Typography>

      <Box className="flow-row">
        {/* Active Status */}
        <Box className="flow-column">
          <Paper
            className={`flow-box ${getPulseSpeed(triage)}`}
            sx={{ backgroundColor: getBoxColor(triage), cursor: "pointer" }}
            onClick={() => handleOpenModal("triage")}
          >
            <Typography variant="h6" sx={{ color: "#fff", fontWeight: "bold" }}>
              Triage
            </Typography>
            <Typography sx={{ color: "#fff" }}>{triage} Patients</Typography>
          </Paper>
          <Typography className="flow-arrow">↓</Typography>
          <Paper
            className={`flow-box ${getPulseSpeed(waiting)}`}
            sx={{ backgroundColor: getBoxColor(waiting), cursor: "pointer" }}
            onClick={() => handleOpenModal("waiting")}
          >
            <Typography variant="h6" sx={{ color: "#fff", fontWeight: "bold" }}>
              Waiting
            </Typography>
            <Typography sx={{ color: "#fff" }}>{waiting} Patients</Typography>
          </Paper>
          <Typography className="flow-arrow">↓</Typography>
          <Paper
            className={`flow-box ${getPulseSpeed(treatment)}`}
            sx={{ backgroundColor: getBoxColor(treatment), cursor: "pointer" }}
            onClick={() => handleOpenModal("treatment")}
          >
            <Typography variant="h6" sx={{ color: "#fff", fontWeight: "bold" }}>
              Treatment
            </Typography>
            <Typography sx={{ color: "#fff" }}>{treatment} Patients</Typography>
          </Paper>
        </Box>

        {/* Discharge Status */}
        <Box className="flow-column">
          <Paper
            className={`flow-box ${getPulseSpeed(dischargedEvent)}`}
            sx={{
              backgroundColor: getBoxColor(dischargedEvent),
              cursor: "pointer",
            }}
            onClick={() => handleOpenModal("dischargedEvent")}
          >
            <Typography variant="h6" sx={{ color: "#fff", fontWeight: "bold" }}>
              Discharged to Event
            </Typography>
            <Typography sx={{ color: "#fff" }}>
              {dischargedEvent} Patients
            </Typography>
          </Paper>
          <Typography className="flow-arrow">↓</Typography>
          <Paper
            className={`flow-box ${getPulseSpeed(dischargedUTC)}`}
            sx={{
              backgroundColor: getBoxColor(dischargedUTC),
              cursor: "pointer",
            }}
            onClick={() => handleOpenModal("dischargedUTC")}
          >
            <Typography variant="h6" sx={{ color: "#fff", fontWeight: "bold" }}>
              Discharged to UTC
            </Typography>
            <Typography sx={{ color: "#fff" }}>
              {dischargedUTC} Patients
            </Typography>
          </Paper>
          <Typography className="flow-arrow">↓</Typography>
          <Paper
            className={`flow-box ${getPulseSpeed(dischargedHome)}`}
            sx={{
              backgroundColor: getBoxColor(dischargedHome),
              cursor: "pointer",
            }}
            onClick={() => handleOpenModal("dischargedHome")}
          >
            <Typography variant="h6" sx={{ color: "#fff", fontWeight: "bold" }}>
              Discharged Home
            </Typography>
            <Typography sx={{ color: "#fff" }}>
              {dischargedHome} Patients
            </Typography>
          </Paper>
        </Box>

        {/* Transport Status */}
        <Box className="flow-column">
          <Paper
            className={`flow-box ${getPulseSpeed(awaitingTransport)}`}
            sx={{
              backgroundColor: getBoxColor(awaitingTransport),
              cursor: "pointer",
            }}
            onClick={() => handleOpenModal("awaitingTransport")}
          >
            <Typography variant="h6" sx={{ color: "#fff", fontWeight: "bold" }}>
              Awaiting Transport
            </Typography>
            <Typography sx={{ color: "#fff" }}>
              {awaitingTransport} Patients
            </Typography>
          </Paper>
          <Typography className="flow-arrow">↓</Typography>
          <Paper
            className={`flow-box ${getPulseSpeed(transportedOwnMeans)}`}
            sx={{
              backgroundColor: getBoxColor(transportedOwnMeans),
              cursor: "pointer",
            }}
            onClick={() => handleOpenModal("transportedOwnMeans")}
          >
            <Typography variant="h6" sx={{ color: "#fff", fontWeight: "bold" }}>
              Transported by Own Means
            </Typography>
            <Typography sx={{ color: "#fff" }}>
              {transportedOwnMeans} Patients
            </Typography>
          </Paper>
          <Typography className="flow-arrow">↓</Typography>
          <Paper
            className={`flow-box ${getPulseSpeed(transportedAmbulance)}`}
            sx={{
              backgroundColor: getBoxColor(transportedAmbulance),
              cursor: "pointer",
            }}
            onClick={() => handleOpenModal("transportedAmbulance")}
          >
            <Typography variant="h6" sx={{ color: "#fff", fontWeight: "bold" }}>
              Transported by Ambulance
            </Typography>
            <Typography sx={{ color: "#fff" }}>
              {transportedAmbulance} Patients
            </Typography>
          </Paper>
        </Box>
      </Box>

      {/* Modal for displaying PRF list */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 2,
            p: 4,
          }}
        >
          <Typography variant="h6" gutterBottom>
            PRFs in {selectedStatus} Status
          </Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>PRF ID</TableCell>
                  <TableCell>Patient Name</TableCell>
                  <TableCell>Date of Incident</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {prfList.length > 0 ? (
                  prfList.map((prf) => (
                    <TableRow key={prf.prf_id}>
                      <TableCell>{prf.prf_id}</TableCell>
                      <TableCell>{prf.patient_name}</TableCell>
                      <TableCell>
                        {new Date(prf.incident_date).toLocaleDateString()}
                      </TableCell>
                      <TableCell>{prf.status}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No PRFs found for this status.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box mt={3}>
            <Button variant="contained" onClick={handleCloseModal}>
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default PatientFlow;
