// PatientRegister.jsx

import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Paper,
  Grid,
  Alert,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  Tooltip,
  Autocomplete,
  Divider,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import apiClient from "../utils/requestHandler";

const PatientRegister = () => {
  const [patient, setPatient] = useState({
    name: "",
    dob: "",
    gender: "",
    address: "",
    contact_number: "",
    next_of_kin_name: "",
    next_of_kin_contact: "",
    relationship_to_nok: "",
    past_medical_history: "",
    medications: "",
    allergies: "",
    email: "",
    password: "",
    cheer_team_id: "",
    cheer_squad_id: "",
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [privacyConsent, setPrivacyConsent] = useState(false);
  const [createAccount, setCreateAccount] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [isBCACheerMember, setIsBCACheerMember] = useState(false);

  // State for cheer teams and squads
  const [cheerTeams, setCheerTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedSquad, setSelectedSquad] = useState(null);

  useEffect(() => {
    // Fetch cheer teams and squads when the component mounts
    const fetchCheerTeams = async () => {
      try {
        const response = await apiClient.get(`/api/cheer/teams`);
        setCheerTeams(response.data);
      } catch (error) {
        console.error("Error fetching cheer teams:", error);
      }
    };

    fetchCheerTeams();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPatient((prev) => ({ ...prev, [name]: value }));
  };

  const handleConsentChange = (e) => setPrivacyConsent(e.target.checked);
  const handleAccountToggle = (e) => setCreateAccount(e.target.checked);

  const handleBCAChange = (e) => {
    setIsBCACheerMember(e.target.checked);
    if (!e.target.checked) {
      // Reset team and squad selection when unchecked
      setSelectedTeam(null);
      setSelectedSquad(null);
      setPatient((prev) => ({
        ...prev,
        cheer_team_id: "",
        cheer_squad_id: "",
      }));
    }
  };

  const handleTeamChange = (event, newValue) => {
    setSelectedTeam(newValue);
    setSelectedSquad(null); // Reset squad selection when team changes
    setPatient((prev) => ({
      ...prev,
      cheer_team_id: newValue ? newValue.team_id : "",
      cheer_squad_id: "",
    }));
  };

  const handleSquadChange = (event, newValue) => {
    setSelectedSquad(newValue);
    setPatient((prev) => ({
      ...prev,
      cheer_squad_id: newValue ? newValue.squad_id : "",
    }));
  };

  const isEmailValid = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const isPhoneNumberValid = (number) => /^[0-9]{10,15}$/.test(number);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!privacyConsent) {
      setError("You must accept the privacy policy and terms to register.");
      return;
    }

    if (createAccount && !isEmailValid(patient.email)) {
      setError("Please enter a valid email for account creation.");
      return;
    }

    if (!isPhoneNumberValid(patient.contact_number)) {
      setError("Please enter a valid contact number (10-15 digits).");
      return;
    }

    if (isBCACheerMember && (!selectedTeam || !selectedSquad)) {
      setError("Please select your cheer team and squad.");
      return;
    }

    setError("");
    setSuccessMessage("");
    setLoading(true);

    try {
      // Adjusted submission logic
      let user_id = null;
      if (createAccount) {
        // Create user first
        const userResponse = await apiClient.post("/api/users/register", {
          email: patient.email,
          password: patient.password,
        });
        user_id = userResponse.data.user_id;
      }

      // Create patient
      const patientResponse = await apiClient.post("/api/patients", {
        ...patient,
        user_id,
      });
      const patient_id = patientResponse.data.patient_id;

      // Link patient to squad if applicable
      if (isBCACheerMember) {
        await apiClient.post("/api/patient-squad", {
          patient_id,
          squad_id: patient.cheer_squad_id,
          start_date: new Date().toISOString(),
        });
      }

      setSuccessMessage("Registration successful! You can now log in.");
      setPatient({
        name: "",
        dob: "",
        gender: "",
        address: "",
        contact_number: "",
        next_of_kin_name: "",
        next_of_kin_contact: "",
        relationship_to_nok: "",
        past_medical_history: "",
        medications: "",
        allergies: "",
        email: "",
        password: "",
        cheer_team_id: "",
        cheer_squad_id: "",
      });
      setPrivacyConsent(false);
      setCreateAccount(false);
      setIsBCACheerMember(false);
      setSelectedTeam(null);
      setSelectedSquad(null);
      localStorage.removeItem("patientData");
    } catch (err) {
      console.error(err);
      setError("Registration failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (date) => {
    const d = new Date(date);
    return d.toISOString().split("T")[0];
  };

  return (
    <Container component="main" maxWidth="md" sx={{ mt: 4 }}>
      <Paper elevation={8} sx={{ padding: 4, borderRadius: 3 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{ fontWeight: "bold", mb: 2 }}
          >
            Patient Registration
          </Typography>
          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{ mb: 3, color: "text.secondary" }}
          >
            Register to manage your medical information securely.
          </Typography>

          {error && (
            <Alert severity="error" sx={{ width: "100%", mb: 2 }}>
              {error}
            </Alert>
          )}
          {successMessage && (
            <Alert severity="success" sx={{ width: "100%", mb: 2 }}>
              {successMessage}
            </Alert>
          )}

          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 2, width: "100%" }}
          >
            <Grid container spacing={2}>
              {/* Personal Information */}
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Personal Information
                </Typography>
                <Divider sx={{ mb: 2 }} />
              </Grid>

              {[
                { label: "Full Name", id: "name", required: true },
                {
                  label: "Date of Birth",
                  id: "dob",
                  type: "date",
                  required: true,
                },
                { label: "Gender", id: "gender", required: true },
                { label: "Address", id: "address", required: true },
                {
                  label: "Contact Number",
                  id: "contact_number",
                  required: true,
                },
              ].map(({ label, id, type = "text", required }) => (
                <Grid item xs={12} sm={6} key={id}>
                  <TextField
                    required={required}
                    fullWidth
                    id={id}
                    label={label}
                    name={id}
                    type={type}
                    value={
                      type === "date" && patient[id]
                        ? formatDate(patient[id])
                        : patient[id]
                    }
                    onChange={handleChange}
                    InputLabelProps={
                      type === "date" ? { shrink: true } : undefined
                    }
                    autoComplete="off"
                  />
                </Grid>
              ))}

              {/* Next of Kin Information */}
              <Grid item xs={12} sx={{ mt: 4 }}>
                <Typography variant="h6" gutterBottom>
                  Next of Kin Information
                </Typography>
                <Divider sx={{ mb: 2 }} />
              </Grid>

              {[
                { label: "Next of Kin Name", id: "next_of_kin_name" },
                { label: "Next of Kin Contact", id: "next_of_kin_contact" },
                {
                  label: "Relationship to Next of Kin",
                  id: "relationship_to_nok",
                },
              ].map(({ label, id }) => (
                <Grid item xs={12} sm={4} key={id}>
                  <TextField
                    fullWidth
                    id={id}
                    label={label}
                    name={id}
                    value={patient[id]}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </Grid>
              ))}

              {/* Medical Information */}
              <Grid item xs={12} sx={{ mt: 4 }}>
                <Typography variant="h6" gutterBottom>
                  Medical Information
                </Typography>
                <Divider sx={{ mb: 2 }} />
              </Grid>

              {[
                {
                  label: "Past Medical History",
                  id: "past_medical_history",
                  tooltip: "Any relevant past medical conditions",
                },
                {
                  label: "Medications",
                  id: "medications",
                  tooltip: "Current medications you are taking",
                },
                {
                  label: "Allergies",
                  id: "allergies",
                  tooltip: "Please include drug and food allergies",
                },
              ].map(({ label, id, tooltip }) => (
                <Grid item xs={12} key={id}>
                  <Tooltip title={tooltip || ""} placement="top" arrow>
                    <TextField
                      fullWidth
                      id={id}
                      label={label}
                      name={id}
                      value={patient[id]}
                      onChange={handleChange}
                      autoComplete="off"
                      multiline
                      rows={2}
                    />
                  </Tooltip>
                </Grid>
              ))}

              {/* BCA Cheer Comps Membership */}
              <Grid item xs={12} sx={{ mt: 4 }}>
                <Typography variant="h6" gutterBottom>
                  Cheer Association
                </Typography>
                <Divider sx={{ mb: 2 }} />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isBCACheerMember}
                      onChange={handleBCAChange}
                      color="primary"
                    />
                  }
                  label="I am part of the BCA Cheer Comps"
                />
              </Grid>

              {/* Team and Squad Selection with Autocomplete */}
              {isBCACheerMember && (
                <>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      options={cheerTeams}
                      getOptionLabel={(option) => option.team_name}
                      value={selectedTeam}
                      onChange={handleTeamChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Cheer Team"
                          required
                          fullWidth
                        />
                      )}
                    />
                  </Grid>

                  {selectedTeam && (
                    <Grid item xs={12} sm={6}>
                      <Autocomplete
                        options={selectedTeam.squads || []}
                        getOptionLabel={(option) => option.squad_name}
                        value={selectedSquad}
                        onChange={handleSquadChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Cheer Squad"
                            required
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                  )}
                </>
              )}

              {/* Account Creation Option */}
              <Grid item xs={12} sx={{ mt: 4 }}>
                <Typography variant="h6" gutterBottom>
                  Account Details
                </Typography>
                <Divider sx={{ mb: 2 }} />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={createAccount}
                      onChange={handleAccountToggle}
                      color="primary"
                    />
                  }
                  label="Create an account to access and update your information"
                />
              </Grid>

              {createAccount && (
                <>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      label="Email"
                      name="email"
                      type="email"
                      value={patient.email}
                      onChange={handleChange}
                      autoComplete="off"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      id="password"
                      label="Password"
                      name="password"
                      type="password"
                      value={patient.password}
                      onChange={handleChange}
                      autoComplete="off"
                    />
                  </Grid>
                </>
              )}

              {/* Privacy Policy and Terms */}
              <Grid item xs={12} sx={{ mt: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={privacyConsent}
                      onChange={handleConsentChange}
                      color="primary"
                    />
                  }
                  label={
                    <Typography variant="body2">
                      I agree to the{" "}
                      <Button
                        color="primary"
                        onClick={() => setShowPrivacyPolicy(true)}
                      >
                        Privacy Policy
                      </Button>{" "}
                      and{" "}
                      <Button
                        color="primary"
                        onClick={() => setShowTerms(true)}
                      >
                        Terms of Service
                      </Button>
                      .
                    </Typography>
                  }
                />
              </Grid>

              {/* Submit Button */}
              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={loading || !privacyConsent}
                  sx={{
                    py: 1.5,
                    mt: 1,
                    boxShadow: "none",
                    ":hover": { boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)" },
                  }}
                >
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Register"
                  )}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Paper>

      {/* Privacy Policy Dialog */}
      <Dialog
        open={showPrivacyPolicy}
        onClose={() => setShowPrivacyPolicy(false)}
        maxWidth="md"
      >
        <DialogTitle>Privacy Policy</DialogTitle>
        <DialogContent dividers>
          <DialogContentText component="div">
            <Typography variant="subtitle1" gutterBottom>
              Effective Date: 28/10/2024
            </Typography>

            <Typography variant="h6" gutterBottom>
              1. Introduction
            </Typography>
            <Typography paragraph>
              DC Event Medical ("we", "us", or "our") is committed to
              protecting your personal data in accordance with the UK GDPR, the
              Data Protection Act 2018, and other applicable laws. This Privacy
              Policy explains how we collect, use, disclose, and safeguard your
              personal data when you use our services.
            </Typography>

            <Typography variant="h6" gutterBottom>
              2. Data Controller
            </Typography>
            <Typography paragraph>
              DC Event Medical is the data controller responsible for your
              personal data. If you have any questions about this policy or our
              data practices, please contact us:
            </Typography>
            <Typography paragraph>
              Data Protection Officer: Liam Cole
              <br />
              Email: dpo@dceventmedical.co.uk
              <br />
              Address: 4 Falcon Close, Fareham, Hampshire, PO16 8PL
            </Typography>

            <Typography variant="h6" gutterBottom>
              3. Personal Data We Collect
            </Typography>
            <Typography paragraph>
              We may collect the following types of personal data:
            </Typography>
            <ul>
              <li>Identity Data: name, date of birth, gender.</li>
              <li>Contact Data: address, email address, phone number.</li>
              <li>
                Health Data: medical history, medications, allergies, treatment
                records.
              </li>
              <li>
                Technical Data: IP address, browser type, operating system.
              </li>
            </ul>

            <Typography variant="h6" gutterBottom>
              4. How We Collect Personal Data
            </Typography>
            <Typography paragraph>
              We collect personal data directly from you when you:
            </Typography>
            <ul>
              <li>Register or create an account.</li>
              <li>
                Fill out forms or provide information during consultations.
              </li>
              <li>Contact us via email, phone, or other means.</li>
              <li>
                Use our website or application (through cookies and similar
                technologies).
              </li>
            </ul>

            <Typography variant="h6" gutterBottom>
              5. Legal Basis for Processing
            </Typography>
            <Typography paragraph>
              We process your personal data based on:
            </Typography>
            <ul>
              <li>
                <strong>Consent:</strong> When you have given clear consent for
                us to process your personal data for a specific purpose.
              </li>
              <li>
                <strong>Contractual Necessity:</strong> To fulfill a contract
                with you or take steps at your request before entering into a
                contract.
              </li>
              <li>
                <strong>Legal Obligation:</strong> To comply with legal and
                regulatory requirements.
              </li>
              <li>
                <strong>Vital Interests:</strong> To protect your vital
                interests or those of another person.
              </li>
            </ul>

            <Typography variant="h6" gutterBottom>
              6. How We Use Your Personal Data
            </Typography>
            <Typography paragraph>We use your personal data to:</Typography>
            <ul>
              <li>Provide medical and healthcare services.</li>
              <li>Maintain accurate medical records.</li>
              <li>
                Communicate with you regarding appointments and treatment.
              </li>
              <li>Improve our services and patient experience.</li>
              <li>
                Comply with legal obligations and regulatory requirements.
              </li>
            </ul>

            <Typography variant="h6" gutterBottom>
              7. Disclosure of Your Personal Data
            </Typography>
            <Typography paragraph>
              We may share your personal data with:
            </Typography>
            <ul>
              <li>Healthcare professionals involved in your care.</li>
              <li>Regulatory bodies such as the CQC.</li>
              <li>
                Third-party service providers who process data on our behalf
                (e.g., IT support, cloud services).
              </li>
              <li>
                Law enforcement or other authorities when required by law.
              </li>
            </ul>
            <Typography paragraph>
              We require all third parties to respect the security of your
              personal data and to treat it in accordance with the law.
            </Typography>

            <Typography variant="h6" gutterBottom>
              8. International Data Transfers
            </Typography>
            <Typography paragraph>
              Your personal data is stored and processed within the UK and
              European Economic Area (EEA). If we transfer data outside these
              areas, we will ensure appropriate safeguards are in place.
            </Typography>

            <Typography variant="h6" gutterBottom>
              9. Data Security
            </Typography>
            <Typography paragraph>
              We have implemented appropriate technical and organizational
              measures to protect your personal data against unauthorized
              access, alteration, disclosure, or destruction, in line with ISO
              27001 standards.
            </Typography>

            <Typography variant="h6" gutterBottom>
              10. Data Retention
            </Typography>
            <Typography paragraph>
              We retain your personal data only for as long as necessary to
              fulfill the purposes for which it was collected, including for the
              purposes of satisfying any legal, accounting, or reporting
              requirements.
            </Typography>

            <Typography variant="h6" gutterBottom>
              11. Your Rights
            </Typography>
            <Typography paragraph>
              Under data protection laws, you have rights including:
            </Typography>
            <ul>
              <li>
                <strong>Access:</strong> Request access to your personal data.
              </li>
              <li>
                <strong>Rectification:</strong> Request correction of inaccurate
                data.
              </li>
              <li>
                <strong>Erasure:</strong> Request deletion of your data under
                certain conditions.
              </li>
              <li>
                <strong>Restriction:</strong> Request restriction of processing
                your data.
              </li>
              <li>
                <strong>Objection:</strong> Object to processing of your data.
              </li>
              <li>
                <strong>Data Portability:</strong> Request transfer of your data
                to you or a third party.
              </li>
            </ul>
            <Typography paragraph>
              To exercise your rights, please contact us using the details
              provided in Section 2.
            </Typography>

            <Typography variant="h6" gutterBottom>
              12. Cookies and Similar Technologies
            </Typography>
            <Typography paragraph>
              We use cookies to enhance your experience on our website. You can
              manage your cookie preferences through your browser settings.
            </Typography>

            <Typography variant="h6" gutterBottom>
              13. Changes to This Privacy Policy
            </Typography>
            <Typography paragraph>
              We may update this Privacy Policy periodically. We encourage you
              to review this policy regularly for any changes.
            </Typography>

            <Typography variant="h6" gutterBottom>
              14. Complaints
            </Typography>
            <Typography paragraph>
              If you have concerns about how we handle your personal data, you
              have the right to complain to the Information Commissioner's
              Office (ICO).
            </Typography>

            <Typography variant="body2" color="textSecondary">
              By using our services, you acknowledge that you have read and
              understood this Privacy Policy.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowPrivacyPolicy(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Terms of Service Dialog */}
      <Dialog
        open={showTerms}
        onClose={() => setShowTerms(false)}
        maxWidth="md"
      >
        <DialogTitle>Terms of Service</DialogTitle>
        <DialogContent dividers>
          <DialogContentText component="div">
            <Typography variant="subtitle1" gutterBottom>
              Effective Date: 28/10/2024
            </Typography>

            <Typography variant="h6" gutterBottom>
              1. Acceptance of Terms
            </Typography>
            <Typography paragraph>
              By accessing or using our services, you agree to be bound by these
              Terms of Service ("Terms"). If you do not agree to these Terms,
              please do not use our services.
            </Typography>

            <Typography variant="h6" gutterBottom>
              2. Description of Services
            </Typography>
            <Typography paragraph>
              We provide medical and healthcare services, including access to
              personal medical information and communication tools.
            </Typography>

            <Typography variant="h6" gutterBottom>
              3. User Obligations
            </Typography>
            <Typography paragraph>You agree to:</Typography>
            <ul>
              <li>
                Provide accurate and up-to-date information during registration
                and use of our services.
              </li>
              <li>
                Keep your account credentials confidential and notify us
                immediately of any unauthorized use.
              </li>
              <li>Comply with all applicable laws and regulations.</li>
            </ul>

            <Typography variant="h6" gutterBottom>
              4. Permitted Use and Restrictions
            </Typography>
            <Typography paragraph>
              You may use our services for personal, non-commercial purposes.
              You agree not to:
            </Typography>
            <ul>
              <li>
                Copy, distribute, or disclose any part of the services in any
                medium.
              </li>
              <li>
                Attempt to interfere with or compromise the system integrity or
                security.
              </li>
              <li>
                Use the services for any unlawful or prohibited activities.
              </li>
            </ul>

            <Typography variant="h6" gutterBottom>
              5. Intellectual Property Rights
            </Typography>
            <Typography paragraph>
              All content and materials on our services are protected by
              intellectual property laws. You are granted a limited license to
              access and use the services for personal purposes.
            </Typography>

            <Typography variant="h6" gutterBottom>
              6. Limitation of Liability
            </Typography>
            <Typography paragraph>
              To the maximum extent permitted by law, we shall not be liable for
              any indirect or consequential damages arising from your use of the
              services.
            </Typography>

            <Typography variant="h6" gutterBottom>
              7. Disclaimers
            </Typography>
            <Typography paragraph>
              The services are provided "as is" without warranties of any kind.
              We do not guarantee the accuracy or completeness of any
              information provided.
            </Typography>

            <Typography variant="h6" gutterBottom>
              8. Termination
            </Typography>
            <Typography paragraph>
              We may terminate or suspend your access to the services
              immediately, without prior notice, if you breach these Terms.
            </Typography>

            <Typography variant="h6" gutterBottom>
              9. Governing Law and Jurisdiction
            </Typography>
            <Typography paragraph>
              These Terms are governed by the laws of England and Wales. Any
              disputes shall be resolved in the courts of England and Wales.
            </Typography>

            <Typography variant="h6" gutterBottom>
              10. Changes to Terms
            </Typography>
            <Typography paragraph>
              We reserve the right to modify these Terms at any time. Changes
              will be effective upon posting on our website. Your continued use
              of the services constitutes acceptance of the new Terms.
            </Typography>

            <Typography variant="h6" gutterBottom>
              11. Complaints and Dispute Resolution
            </Typography>
            <Typography paragraph>
              If you have any complaints or disputes regarding our services,
              please contact us at:
            </Typography>
            <Typography paragraph>
              Email: complaints@dceventmedical.co.uk
              <br />
              Address: 4 Falcon Close, Fareham, Hampshire, PO16 8PL
            </Typography>
            <Typography paragraph>
              We will make reasonable efforts to resolve any disputes promptly.
            </Typography>

            <Typography variant="h6" gutterBottom>
              12. Contact Information
            </Typography>
            <Typography paragraph>
              For any questions about these Terms, please contact us using the
              information provided above.
            </Typography>

            <Typography variant="body2" color="textSecondary">
              By using our services, you acknowledge that you have read,
              understood, and agree to be bound by these Terms of Service.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowTerms(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default PatientRegister;
