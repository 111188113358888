// Footer.jsx

import React from "react";
import { Box, Link, Typography, Container, IconButton } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        py: 4,
        px: 2,
        mt: "auto",
        backgroundColor: "#2c2c2c",
        color: "#fff",
      }}
    >
      <Container maxWidth="md">
        <Typography variant="body1" align="center">
          © {new Date().getFullYear()} DC Event Medical
        </Typography>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* Navigation Links */}
          <Box sx={{ display: "flex", justifyContent: "center", gap: 3 }}>
            <Link
              component={RouterLink}
              to="/privacy-policy"
              variant="body2"
              color="inherit"
              underline="hover"
            >
              Privacy Policy
            </Link>
            <Link
              component={RouterLink}
              to="/terms-of-service"
              variant="body2"
              color="inherit"
              underline="hover"
            >
              Terms of Service
            </Link>
            <Link
              component={RouterLink}
              to="/cookie-policy"
              variant="body2"
              color="inherit"
              underline="hover"
            >
              Cookie Policy
            </Link>
            <Link
              component={RouterLink}
              to="/contact-us"
              variant="body2"
              color="inherit"
              underline="hover"
            >
              Contact Us
            </Link>
          </Box>

          {/* Contact Information */}
          <Box
            sx={{
              mt: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Typography variant="body2">For support or inquiries:</Typography>
            <Typography variant="body2">
              Email:{" "}
              <Link
                href="mailto:enquiries@dceventmedical.co.uk"
                color="inherit"
                underline="hover"
              >
                enquiries@dceventmedical.co.uk
              </Link>
            </Typography>
            <Typography variant="body2">
              Phone:{" "}
              <Link href="tel:+443300588348" color="inherit" underline="hover">
                03300 588 348
              </Link>
            </Typography>
          </Box>

          {/* Social Media Icons */}
          <Box sx={{ mt: 2, display: "flex", gap: 1 }}>
            <IconButton
              href="https://www.facebook.com/dceventmedical"
              target="_blank"
              rel="noopener"
              aria-label="Facebook"
              color="inherit"
            >
              <FacebookIcon />
            </IconButton>
            <IconButton
              href="https://twitter.com/dceventmedical"
              target="_blank"
              rel="noopener"
              aria-label="Twitter"
              color="inherit"
            >
              <TwitterIcon />
            </IconButton>
            <IconButton
              href="https://www.instagram.com/dceventmedical"
              target="_blank"
              rel="noopener"
              aria-label="Instagram"
              color="inherit"
            >
              <InstagramIcon />
            </IconButton>
            {/* Add other social media icons if needed */}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
