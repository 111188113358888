import CryptoJS from "crypto-js";

export const storeEncryptedData = (key, data) => {
  const ciphertext = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    "secret-key"
  ).toString();
  sessionStorage.setItem(key, ciphertext);
};

export const retrieveDecryptedData = (key) => {
  const encryptedData = sessionStorage.getItem(key);

  // Check if the data exists
  if (!encryptedData) {
    console.warn(`No data found for key: ${key}`);
    return null; // Or return a default value
  }

  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, "secret-key");
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);

    return decryptedData ? JSON.parse(decryptedData) : null;
  } catch (error) {
    console.error("Error decrypting data:", error);
    return null; // Handle decryption errors gracefully
  }
};
