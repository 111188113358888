// ConditionActionsSidebar.js
import React from "react";
import {
  Box,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Typography,
  Checkbox,
  TextField,
} from "@mui/material";

const ConditionActionsSidebar = ({
  selectedCondition,
  isSidebarOpen,
  setIsSidebarOpen,
  recordedActions,
  setRecordedActions,
  conditionsActions,
}) => {
  const handleActionToggle = (action) => {
    setRecordedActions((prev) => ({
      ...prev,
      [selectedCondition]: {
        ...prev[selectedCondition],
        [action]: !prev[selectedCondition]?.[action],
      },
    }));
  };

  const handleNotesChange = (e) => {
    const notes = e.target.value;
    setRecordedActions((prev) => ({
      ...prev,
      [selectedCondition]: {
        ...prev[selectedCondition],
        Notes: notes,
      },
    }));
  };

  return (
    <Drawer
      anchor="right"
      open={isSidebarOpen}
      onClose={() => setIsSidebarOpen(false)}
    >
      <Box sx={{ width: 300, p: 2 }}>
        <Typography variant="h5">{selectedCondition} Actions</Typography>
        <List>
          {conditionsActions[selectedCondition]?.map((action, index) => {
            if (action === "Notes") {
              return (
                <ListItem key={index}>
                  <TextField
                    label="Notes"
                    multiline
                    rows={4}
                    fullWidth
                    value={
                      recordedActions[selectedCondition]?.[action] || ""
                    }
                    onChange={handleNotesChange}
                  />
                </ListItem>
              );
            } else {
              return (
                <ListItem key={index}>
                  <Checkbox
                    checked={
                      recordedActions[selectedCondition]?.[action] || false
                    }
                    onChange={() => handleActionToggle(action)}
                  />
                  <ListItemText primary={action} />
                </ListItem>
              );
            }
          })}
        </List>
        <Button
          variant="contained"
          onClick={() => setIsSidebarOpen(false)}
          sx={{ mt: 2 }}
        >
          Close
        </Button>
      </Box>
    </Drawer>
  );
};

export default ConditionActionsSidebar;
