import React from "react";
import { TextField, Box } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { usePRF } from "../../utils/PRFContext"; // Import the PRF context
import dayjs from "dayjs";

const PresentingComplaint = () => {
  const { prfData, setPrfData } = usePRF();

  // Handle change for other fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPrfData((prev) => ({
      ...prev,
      PresentingComplaint: {
        ...prev.PresentingComplaint,
        [name]: value,
      },
    }));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box>
        <TextField
          label="Presenting Complaint"
          name="presenting_complaint"
          value={prfData.PresentingComplaint.presenting_complaint || ""}
          onChange={handleInputChange}
          fullWidth
          sx={{ mb: 2 }}
        />

        <TextField
          label="History of Presenting Complaint"
          name="history_of_presenting_complaint"
          value={
            prfData.PresentingComplaint.history_of_presenting_complaint || ""
          }
          onChange={handleInputChange}
          fullWidth
          multiline
          rows={3}
          sx={{ mb: 2 }}
        />

        <TextField
          label="Past Medical History"
          name="past_medical_history"
          value={prfData.PresentingComplaint.past_medical_history || ""}
          onChange={handleInputChange}
          fullWidth
          multiline
          rows={2}
          sx={{ mb: 2 }}
        />

        <DateTimePicker
          label="Last Oral Intake"
          value={
            prfData.PresentingComplaint.last_oral_intake
              ? dayjs(prfData.PresentingComplaint.last_oral_intake)
              : null
          }
          onChange={(newValue) =>
            setPrfData((prev) => ({
              ...prev,
              PresentingComplaint: {
                ...prev.PresentingComplaint,
                last_oral_intake: newValue ? newValue.toISOString() : "",
              },
            }))
          }
          renderInput={(params) => (
            <TextField {...params} fullWidth sx={{ mb: 2 }} />
          )}
        />

        <TextField
          label="Family History"
          name="family_history"
          value={prfData.PresentingComplaint.family_history || ""}
          onChange={handleInputChange}
          fullWidth
          multiline
          rows={2}
          sx={{ mb: 2 }}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default PresentingComplaint;
