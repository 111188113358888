import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Button,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Snackbar,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import {
  ExpandMore,
  AddCircleOutline,
  Edit,
  Delete,
  Print,
} from "@mui/icons-material";
import apiClient from "../../utils/requestHandler";
import PrintBOM from "./PrintBOM";

const BOMsPage = () => {
  // State variables
  const [boms, setBOMs] = useState([]);
  const [bags, setBags] = useState([]);
  const [pouches, setPouches] = useState([]);
  const [inventoryItems, setInventoryItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [editingEntry, setEditingEntry] = useState(null);
  const [dialogType, setDialogType] = useState("");
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  useEffect(() => {
    fetchData();
  }, []);

  // Fetch data from API
  const fetchData = async () => {
    setLoading(true);
    try {
      const [bomResponse, bagResponse, pouchResponse, inventoryResponse] =
        await Promise.all([
          apiClient.get("/api/inventory/billOfMaterials"),
          apiClient.get("/api/inventory/responseBags"),
          apiClient.get("/api/inventory/pouches"),
          apiClient.get("/api/inventory"),
        ]);
      setBOMs(bomResponse.data);
      setBags(bagResponse.data);
      setPouches(pouchResponse.data);
      setInventoryItems(inventoryResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setSnackbar({
        open: true,
        message: "Error fetching data.",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  // Handle adding a new entry
  const handleAdd = (type) => {
    setEditingEntry({
      type,
      data:
        type === "BOM"
          ? {
              bag_id: "",
              pouch_id: "",
              item_id: "",
              quantity_required: 1,
              location_in_bag: "",
              notes: "",
              expiry_date: "",
              lot_number: "",
            }
          : type === "Bag"
          ? {
              bag_name: "",
              description: "",
              location_id: "",
              last_stock_check: "",
            }
          : {
              pouch_name: "",
              description: "",
              bag_id: "",
            },
    });
    setDialogType(type);
    setOpenDialog(true);
  };

  const handleEdit = (entry, type) => {
    if (type === "BOM") {
      // Extract the necessary fields from the entry
      const data = {
        bom_id: entry.bom_id,
        bag_id: entry.bag_id,
        pouch_id: entry.pouch_id || "",
        item_id: entry.item_id,
        quantity_required: entry.quantity_required,
        location_in_bag: entry.location_in_bag || "",
        notes: entry.notes || "",
        expiry_date: entry.expiry_date || "",
        lot_number: entry.lot_number || "",
      };
      setEditingEntry({ type, data });
    } else {
      // For Bag and Pouch, we can keep the existing logic
      setEditingEntry({ type, data: { ...entry } });
    }
    setDialogType(type);
    setOpenDialog(true);
  };

  // Handle saving an entry
  const handleSaveEntry = async () => {
    const { type, data } = editingEntry;

    // Basic validation
    if (
      (type === "BOM" && (!data.bag_id || !data.item_id)) ||
      (type === "Bag" && !data.bag_name) ||
      (type === "Pouch" && !data.pouch_name)
    ) {
      setSnackbar({
        open: true,
        message: "Please fill all required fields.",
        severity: "warning",
      });
      return;
    }

    try {
      if (data.id) {
        // Update existing entry
        await apiClient.put(
          `/api/inventory/${type.toLowerCase()}s/${data.id}`,
          data
        );
        setSnackbar({
          open: true,
          message: `${type} updated successfully.`,
          severity: "success",
        });
      } else {
        // Create new entry
        await apiClient.post(`/api/inventory/${type.toLowerCase()}s`, data);
        setSnackbar({
          open: true,
          message: `${type} added successfully.`,
          severity: "success",
        });
      }
      // Record the action in audit trails (pseudo-code)
      // await apiClient.post('/api/audit', { action: `${type} ${data.id ? 'updated' : 'created'}`, user: currentUser.id });
      fetchData();
      setOpenDialog(false);
    } catch (error) {
      console.error(`Error saving ${type}:`, error);
      setSnackbar({
        open: true,
        message: `Error saving ${type}.`,
        severity: "error",
      });
    }
  };

  // Handle deleting an entry
  const handleDeleteEntry = async (id, type) => {
    if (!window.confirm(`Are you sure you want to delete this ${type}?`)) {
      return;
    }
    try {
      await apiClient.delete(`/api/inventory/${type.toLowerCase()}s/${id}`);
      setSnackbar({
        open: true,
        message: `${type} deleted successfully.`,
        severity: "success",
      });
      fetchData();
    } catch (error) {
      console.error(`Error deleting ${type}:`, error);
      setSnackbar({
        open: true,
        message: `Error deleting ${type}.`,
        severity: "error",
      });
    }
  };

  // Handle input change in forms
  const handleInputChange = (field, value) => {
    setEditingEntry((prev) => ({
      ...prev,
      data: { ...prev.data, [field]: value },
    }));
  };

  // Group BOMs by bag and pouch
  const groupedBOMs = boms.reduce((acc, bom) => {
    const bag = acc[bom.bag_id] || {
      bag: bags.find((b) => b.bag_id === bom.bag_id),
      pouches: {},
    };
    const pouch = bag.pouches[bom.pouch_id] || [];

    const inventoryItem = inventoryItems.find(
      (invItem) => invItem.item_id === bom.item_id
    );

    pouch.push({
      ...bom,
      item_name: inventoryItem?.item_name || "Unknown Item",
      // Include other properties if needed
      item_type: inventoryItem?.item_type || "",
      unit: inventoryItem?.unit || "",
      expiry_date: inventoryItem?.expiry_date || "",
      lot_number: inventoryItem?.lot_number || "",
    });

    bag.pouches[bom.pouch_id] = pouch;
    acc[bom.bag_id] = bag;
    return acc;
  }, {});

  const handlePrintBOMPopup = () => {
    const printContents = document.getElementById("bom-print").innerHTML;
    const printWindow = window.open("", "", "width=800,height=600");
    printWindow.document.write(`
      <html>
        <head>
          <title>Print BOM</title>
          <style>
            /* Include any necessary styles here */
            body { font-family: Arial, sans-serif; margin: 20px; }
            h1, h2, h3 { margin: 0; }
            /* Add more styles as needed */
          </style>
        </head>
        <body>
          ${printContents}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.focus();
    //printWindow.print();
    //printWindow.close();
  };

  // Render dialog content based on type
  const renderDialogContent = () => {
    if (!editingEntry) {
      return null;
    }
    const { type, data } = editingEntry;
    if (type === "BOM") {
      return (
        <>
          <TextField
            label="Bag"
            select
            value={data.bag_id || ""}
            onChange={(e) => handleInputChange("bag_id", e.target.value)}
            fullWidth
            margin="normal"
            required
          >
            {bags.map((bag) => (
              <MenuItem key={bag.bag_id} value={bag.bag_id}>
                {bag.bag_name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Pouch (optional)"
            select
            value={data.pouch_id || ""}
            onChange={(e) => handleInputChange("pouch_id", e.target.value)}
            fullWidth
            margin="normal"
          >
            <MenuItem value="">None</MenuItem>
            {pouches
              .filter((pouch) => pouch.bag_id === data.bag_id)
              .map((pouch) => (
                <MenuItem key={pouch.pouch_id} value={pouch.pouch_id}>
                  {pouch.pouch_name}
                </MenuItem>
              ))}
          </TextField>
          <TextField
            label="Item"
            select
            value={data.item_id || ""}
            onChange={(e) => handleInputChange("item_id", e.target.value)}
            fullWidth
            margin="normal"
            required
          >
            {inventoryItems.map((item) => (
              <MenuItem key={item.item_id} value={item.item_id}>
                {item.item_name} (Lot: {item.lot_number || "N/A"}, Expires:{" "}
                {item.expiry_date || "N/A"})
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Quantity Required"
            type="number"
            value={data.quantity_required || ""}
            onChange={(e) =>
              handleInputChange("quantity_required", e.target.value)
            }
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Location in Bag"
            value={data.location_in_bag || ""}
            onChange={(e) =>
              handleInputChange("location_in_bag", e.target.value)
            }
            fullWidth
            margin="normal"
          />
          <TextField
            label="Notes"
            value={data.notes || ""}
            onChange={(e) => handleInputChange("notes", e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Expiration Date"
            type="date"
            value={data.expiry_date || ""}
            onChange={(e) => handleInputChange("expiry_date", e.target.value)}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            required
          />
          <TextField
            label="Lot Number"
            value={data.lot_number || ""}
            onChange={(e) => handleInputChange("lot_number", e.target.value)}
            fullWidth
            margin="normal"
            required
          />
        </>
      );
    } else if (type === "Bag") {
      return (
        <>
          <TextField
            label="Bag Name"
            value={data.bag_name || ""}
            onChange={(e) => handleInputChange("bag_name", e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Description"
            value={data.description || ""}
            onChange={(e) => handleInputChange("description", e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Location ID"
            type="number"
            value={data.location_id || ""}
            onChange={(e) => handleInputChange("location_id", e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Last Stock Check"
            type="date"
            value={data.last_stock_check || ""}
            onChange={(e) =>
              handleInputChange("last_stock_check", e.target.value)
            }
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
          />
        </>
      );
    } else if (type === "Pouch") {
      return (
        <>
          <TextField
            label="Pouch Name"
            value={data.pouch_name || ""}
            onChange={(e) => handleInputChange("pouch_name", e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Description"
            value={data.description || ""}
            onChange={(e) => handleInputChange("description", e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Bag"
            select
            value={data.bag_id || ""}
            onChange={(e) => handleInputChange("bag_id", e.target.value)}
            fullWidth
            margin="normal"
            required
          >
            {bags.map((bag) => (
              <MenuItem key={bag.id} value={bag.id}>
                {bag.bag_name}
              </MenuItem>
            ))}
          </TextField>
        </>
      );
    }
  };

  return (
    <Box sx={{ mt: 10, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Bill of Materials (BOM) Management
      </Typography>

      {/* BOM Entries */}
      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          BOM Entries
        </Typography>
        {loading ? (
          <CircularProgress />
        ) : (
          <div>
            {Object.values(groupedBOMs).map(({ bag, pouches: bagPouches }) => (
              <Accordion key={bag?.bag_id || Math.random()}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls={`panel-${bag?.bag_id}-content`}
                  id={`panel-${bag?.bag_id}-header`}
                >
                  <Typography variant="subtitle1">
                    Bag: {bag?.bag_name || "Unknown Bag"} (
                    {bag?.description || "No description"})
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {bagPouches &&
                    Object.entries(bagPouches).map(([pouchId, items]) => {
                      const pouch = pouchId
                        ? pouches.find((p) => p.pouch_id === parseInt(pouchId))
                        : null;
                      const pouchName = pouch
                        ? pouch.pouch_name
                        : "Loose Items";
                      return (
                        <Accordion key={pouchId}>
                          <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls={`panel-${pouchId}-content`}
                            id={`panel-${pouchId}-header`}
                          >
                            <Typography variant="subtitle2">
                              Pouch: {pouchName}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <TableContainer component={Paper}>
                              <Table size="small">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Item</TableCell>
                                    <TableCell>Quantity</TableCell>
                                    <TableCell>Location In Bag</TableCell>
                                    <TableCell>Notes</TableCell>
                                    <TableCell>Expiry</TableCell>
                                    <TableCell>Lot</TableCell>
                                    <TableCell align="right">Actions</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {items.map((item) => (
                                    <TableRow
                                      key={item.bom_id || Math.random()}
                                    >
                                      <TableCell>
                                        {item.item_name || "Unknown Item"}
                                      </TableCell>
                                      <TableCell>
                                        {item.quantity_required || 0}
                                      </TableCell>
                                      <TableCell>
                                        {item.location_in_bag || "N/A"}
                                      </TableCell>
                                      <TableCell>
                                        {item.notes || "None"}
                                      </TableCell>
                                      <TableCell>
                                        {item.expiry_date || "N/A"}
                                      </TableCell>
                                      <TableCell>
                                        {item.lot_number || "N/A"}
                                      </TableCell>
                                      <TableCell align="right">
                                        <IconButton
                                          onClick={() =>
                                            handleEdit(item, "BOM")
                                          }
                                          color="primary"
                                        >
                                          <Edit />
                                        </IconButton>
                                        <IconButton
                                          onClick={() =>
                                            handleDeleteEntry(
                                              item.bom_id,
                                              "billOfMaterial"
                                            )
                                          }
                                          color="secondary"
                                        >
                                          <Delete />
                                        </IconButton>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        )}
        <Button
          startIcon={<AddCircleOutline />}
          variant="contained"
          onClick={() => handleAdd("BOM")}
          sx={{ mt: 2, mr: 2 }}
        >
          Add BOM Entry
        </Button>
        {/* Print Button */}
        <Button
          startIcon={<Print />}
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
          onClick={handlePrintBOMPopup}
        >
          Print BOM
        </Button>
      </Paper>
      {/* Hidden component for printing */}
      <div id="bom-print" style={{ display: "none" }}>
        <PrintBOM groupedBOMs={groupedBOMs} bags={bags} pouches={pouches} />
      </div>
      {/* Bag List */}
      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6">Response Bags</Typography>
        <List>
          {bags.map((bag) => (
            <ListItem key={bag.id}>
              <ListItemText
                primary={bag.bag_name}
                secondary={bag.description}
              />
              <IconButton
                onClick={() => handleEdit(bag, "Bag")}
                color="primary"
              >
                <Edit />
              </IconButton>
              <IconButton
                onClick={() => handleDeleteEntry(bag.id, "Bag")}
                color="secondary"
              >
                <Delete />
              </IconButton>
            </ListItem>
          ))}
        </List>
        <Button
          startIcon={<AddCircleOutline />}
          variant="contained"
          onClick={() => handleAdd("Bag")}
          sx={{ mt: 2 }}
        >
          Add Response Bag
        </Button>
      </Paper>

      {/* Pouch List */}
      <Paper elevation={3} sx={{ p: 3 }}>
        <Typography variant="h6">Pouches</Typography>
        <List>
          {pouches.map((pouch) => (
            <ListItem key={pouch.id}>
              <ListItemText
                primary={pouch.pouch_name}
                secondary={pouch.description}
              />
              <IconButton
                onClick={() => handleEdit(pouch, "Pouch")}
                color="primary"
              >
                <Edit />
              </IconButton>
              <IconButton
                onClick={() => handleDeleteEntry(pouch.id, "Pouch")}
                color="secondary"
              >
                <Delete />
              </IconButton>
            </ListItem>
          ))}
        </List>
        <Button
          startIcon={<AddCircleOutline />}
          variant="contained"
          onClick={() => handleAdd("Pouch")}
          sx={{ mt: 2 }}
        >
          Add Pouch
        </Button>
      </Paper>

      {/* Dialog for adding/editing entries */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>
          {dialogType === "BOM"
            ? editingEntry?.data?.id
              ? "Edit BOM Entry"
              : "Add New BOM Entry"
            : dialogType === "Bag"
            ? editingEntry?.data?.id
              ? "Edit Bag"
              : "Add New Bag"
            : editingEntry?.data?.id
            ? "Edit Pouch"
            : "Add New Pouch"}
        </DialogTitle>
        <DialogContent>{renderDialogContent()}</DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveEntry} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for user feedback */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() =>
          setSnackbar((prev) => ({
            ...prev,
            open: false,
          }))
        }
      >
        <Alert
          onClose={() =>
            setSnackbar((prev) => ({
              ...prev,
              open: false,
            }))
          }
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default BOMsPage;
