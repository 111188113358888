import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  TablePagination,
  Button,
  Typography,
  Box,
} from "@mui/material";
import apiClient from "../../utils/requestHandler";
import { useParams } from "react-router-dom";
import _ from "lodash"; // You can use lodash for easier grouping if installed

const CheerTeamsPage = () => {
  const { eventID } = useParams();
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedSquad, setSelectedSquad] = useState(null); // To track the selected squad
  const [prfs, setPrfs] = useState([]); // PRFs for selected squad

  // Fetch cheer teams and squads based on event_id
  const fetchTeams = async () => {
    setLoading(true);
    try {
      const response = await apiClient.get(`/api/event/${eventID}/cheer-teams`);

      // Group the data by team_name and squad_name
      const groupedData = _.groupBy(
        response.data,
        (item) => `${item.team_name}_${item.squad_name}`
      );
      const formattedData = Object.keys(groupedData).map((key) => {
        const [team_name, squad_name] = key.split("_");
        return {
          team_name,
          squad_name,
          patients: groupedData[key], // All patients in this team/squad
        };
      });

      setTeams(formattedData);
    } catch (error) {
      console.error("Error fetching cheer teams:", error);
      setError("Failed to fetch cheer teams.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (eventID) {
      fetchTeams();
    }
  }, [eventID]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ padding: "20px" }}>
      <Typography variant="h4" gutterBottom>
        Cheer Teams and Squads for Event {eventID}
      </Typography>

      {/* Error Message */}
      {error && (
        <Typography variant="body1" color="error" gutterBottom>
          {error}
        </Typography>
      )}

      {/* Cheer Teams Table */}
      {loading ? (
        <Box display="flex" justifyContent="center" my={5}>
          <CircularProgress />
        </Box>
      ) : (
        <Paper elevation={3} sx={{ mb: 4 }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>Team Name</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Squad Name</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Total Patients</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Actions</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {teams.length > 0 ? (
                  teams
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((team) => (
                      <TableRow key={`${team.team_name}_${team.squad_name}`}>
                        <TableCell>{team.team_name}</TableCell>
                        <TableCell>{team.squad_name}</TableCell>
                        <TableCell>{team.patients.length}</TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setSelectedSquad(team.squad_name)}
                            sx={{ textTransform: "none" }}
                          >
                            View Patients
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No teams available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            component="div"
            count={teams.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </Paper>
      )}

      {/* PRF List Section */}
      {selectedSquad && (
        <Box>
          <Typography variant="h5" sx={{ mt: 5, mb: 2 }}>
            Patients for Squad {selectedSquad}
          </Typography>
          <Paper elevation={3} sx={{ padding: 3, mb: 5 }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>Patient Name</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Date of Birth</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Incident Date</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Chief Complaint</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {teams
                    .filter((team) => team.squad_name === selectedSquad)
                    .flatMap((team) => team.patients)
                    .map((patient) => (
                      <TableRow
                        key={patient.patient_name + patient.incident_date}
                      >
                        <TableCell>{patient.patient_name}</TableCell>
                        <TableCell>
                          {new Date(patient.patient_dob).toLocaleDateString()}
                        </TableCell>
                        <TableCell>
                          {new Date(patient.incident_date).toLocaleString()}
                        </TableCell>
                        <TableCell>{patient.chief_complaint}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      )}
    </Box>
  );
};

export default CheerTeamsPage;
