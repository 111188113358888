import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  Button,
  Typography,
  Modal,
  TextField,
  Grid,
  Card,
  CardContent,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  CircularProgress,
  Fab,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Alert,
} from "@mui/material";
import {
  AddCircleOutline,
  Person,
  Logout,
  Gavel,
  Group,
} from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import apiClient from "../utils/requestHandler";
import GDPRNoticeModal from "./GDPRNoticeModal";
import { useAuth } from "../contexts/AuthContext";

const Dashboard = () => {
  const { user } = useAuth();
  const [clients, setClients] = useState([]);
  const [events, setEvents] = useState([]);
  const [users, setUsers] = useState([]); // Track users
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [openClientModal, setOpenClientModal] = useState(false);
  const [openEventModal, setOpenEventModal] = useState(false);
  const [openGDPRModal, setOpenGDPRModal] = useState(false); // GDPR modal state
  const [openUserModal, setOpenUserModal] = useState(false); // User modal state

  const [newClient, setNewClient] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [newEvent, setNewEvent] = useState({
    name: "",
    date: "",
    clientId: "",
    location: "",
    description: "",
  });
  const [newUser, setNewUser] = useState({
    email: "",
    password: "",
    role: "staff",
  });

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await fetchClients();
        await fetchEvents();
        if (user?.role === "admin") {
          await fetchUsers(); // Only fetch users if admin
        }
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
        if (error.response?.status === 401) {
          navigate("/login");
        }
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [navigate, user]);

  const fetchClients = async () => {
    try {
      const res = await apiClient.get("/api/client");
      setClients(res.data);
    } catch (error) {
      setError("Error fetching clients");
    }
  };

  const fetchEvents = async () => {
    try {
      const res = await apiClient.get("/api/events");
      setEvents(res.data);
    } catch (error) {
      setError("Error fetching events");
    }
  };

  const fetchUsers = async () => {
    try {
      const res = await apiClient.get("/api/users");
      setUsers(res.data);
    } catch (error) {
      setError("Error fetching users");
      console.error("User fetch error:", error);
    }
  };

  const handleLogout = async () => {
    try {
      await apiClient.post("/api/auth/logout");
      navigate("/login");
    } catch (error) {
      console.error("Error logging out:", error);
      setError("Logout failed. Please try again.");
    }
  };

  const handleClientSubmit = async () => {
    try {
      await apiClient.post("/api/client", newClient);
      setOpenClientModal(false);
      setNewClient({ name: "", email: "", phone: "" });
      fetchClients();
    } catch (error) {
      setError("Error adding client");
      console.error("Client submission error:", error);
    }
  };

  const handleEventSubmit = async () => {
    try {
      await apiClient.post("/api/events", newEvent);
      setOpenEventModal(false);
      setNewEvent({
        name: "",
        date: "",
        clientId: "",
        location: "",
        description: "",
      });
      fetchEvents();
    } catch (error) {
      setError("Error adding event");
      console.error("Event submission error:", error);
    }
  };

  const handleUserSubmit = async () => {
    try {
      await apiClient.post("/api/users", newUser);
      setOpenUserModal(false);
      setNewUser({ email: "", password: "", role: "staff" });
      fetchUsers();
    } catch (error) {
      setError("Error adding user");
      console.error("User submission error:", error);
    }
  };

  if (loading) {
    return (
      <Box p={3} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      {/* Navigation Bar */}
      <AppBar position="static" color="primary">
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6" component="div">
            Event Medical - Admin Dashboard
          </Typography>
          <Button color="inherit" onClick={handleLogout} startIcon={<Logout />}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>

      <Box p={3}>
        {/* Dashboard Header */}
        <Typography
          variant="h4"
          gutterBottom
          sx={{ fontWeight: "bold", textAlign: "center", mb: 4 }}
        >
          Welcome to the Admin Dashboard
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        {/* Clients Section */}
        <Box mb={4}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Clients
            </Typography>
            <Fab
              color="primary"
              aria-label="add"
              onClick={() => setOpenClientModal(true)}
              size="small"
            >
              <AddCircleOutline />
            </Fab>
          </Box>
          <Grid container spacing={3}>
            {clients.map((client) => (
              <Grid item xs={12} sm={6} md={4} key={client.clientID}>
                <Card elevation={3} sx={{ borderRadius: 3 }}>
                  <CardContent>
                    <Box display="flex" alignItems="center" mb={1}>
                      <Person
                        fontSize="large"
                        sx={{ mr: 1, color: "primary.main" }}
                      />
                      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                        <Link
                          to={`/client/${client.clientID}`}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          {client.name}
                        </Link>
                      </Typography>
                    </Box>
                    <Typography>Email: {client.email}</Typography>
                    <Typography>Phone: {client.phone}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Events Section */}
        <Box mb={4}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            Events
          </Typography>
          <Grid container spacing={3}>
            {events.map((event) => (
              <Grid item xs={12} sm={6} md={4} key={event.eventID}>
                <Card elevation={3} sx={{ borderRadius: 3 }}>
                  <CardContent>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "bold",
                        textDecoration: "underline",
                        mb: 1,
                      }}
                    >
                      <Link
                        to={`/event/${event.eventID}`}
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        {event.name}
                      </Link>
                    </Typography>
                    <Typography>
                      Date: {new Date(event.date).toLocaleDateString()}
                    </Typography>
                    <Typography>Location: {event.location}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* GDPR Notice Management */}
        {user?.role === "admin" && (
          <Box mb={4}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
              GDPR Notice Management
            </Typography>
            <Button
              variant="outlined"
              startIcon={<Gavel />}
              onClick={() => setOpenGDPRModal(true)}
            >
              Manage GDPR Notices
            </Button>
          </Box>
        )}

        {/* User Management */}
        {user?.role === "admin" && (
          <Box mb={4}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
              User Management
            </Typography>
            <Button
              variant="outlined"
              startIcon={<Group />}
              onClick={() => setOpenUserModal(true)}
            >
              Manage Users
            </Button>
          </Box>
        )}

        {/* User Modal */}
        <Dialog open={openUserModal} onClose={() => setOpenUserModal(false)}>
          <DialogTitle>Add New User</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Email"
              type="email"
              fullWidth
              value={newUser.email}
              onChange={(e) =>
                setNewUser({ ...newUser, email: e.target.value })
              }
            />
            <TextField
              margin="dense"
              label="Password"
              type="password"
              fullWidth
              value={newUser.password}
              onChange={(e) =>
                setNewUser({ ...newUser, password: e.target.value })
              }
            />
            <FormControl fullWidth margin="dense">
              <InputLabel>Role</InputLabel>
              <Select
                value={newUser.role}
                onChange={(e) =>
                  setNewUser({ ...newUser, role: e.target.value })
                }
              >
                <MenuItem value="staff">Staff</MenuItem>
                <MenuItem value="patient">Patient</MenuItem>
                <MenuItem value="client">Client</MenuItem>
                <MenuItem value="admin">Admin</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenUserModal(false)}>Cancel</Button>
            <Button onClick={handleUserSubmit}>Add User</Button>
          </DialogActions>
        </Dialog>

        {/* GDPR Notice Modal */}
        <GDPRNoticeModal
          open={openGDPRModal}
          onClose={() => setOpenGDPRModal(false)}
        />
      </Box>
    </Box>
  );
};

export default Dashboard;
