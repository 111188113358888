import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Alert,
  CircularProgress,
} from "@mui/material";
import apiClient from "../utils/requestHandler"; // Centralized Axios handler

const CreateAdminUserPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  // Email validation function
  const isEmailValid = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const isFormValid = () => email && password && isEmailValid(email);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    setLoading(true);

    console.log("Submitting form with:", { email, password, role: "admin" });

    if (!isFormValid()) {
      setError("Please enter a valid email and password");
      setLoading(false);
      return;
    }

    try {
      const response = await apiClient.post("/api/auth/register", {
        email,
        password,
        role: "admin",
      });

      console.log("API response:", response.data);
      setSuccess("Admin user created successfully!");
      setEmail("");
      setPassword("");
    } catch (err) {
      console.error("Error during registration:", err.response || err);
      setError(err.response?.data?.msg || "Failed to create admin user");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Create Admin User
        </Typography>

        {/* Error and Success Alerts */}
        {error && (
          <Alert severity="error" sx={{ width: "100%", mt: 2 }}>
            {error}
          </Alert>
        )}
        {success && (
          <Alert severity="success" sx={{ width: "100%", mt: 2 }}>
            {success}
          </Alert>
        )}

        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ mt: 1, width: "100%" }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!!error && !isEmailValid(email)}
            helperText={
              !isEmailValid(email) && error ? "Please enter a valid email" : ""
            }
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={!!error && !password}
            helperText={!!error && !password ? "Password is required" : ""}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading || !isFormValid()}
            sx={{ mt: 3, mb: 2 }}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Create Admin"
            )}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default CreateAdminUserPage;
