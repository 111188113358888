import React from "react";
import { forwardRef } from "react";

const PrintBOM = forwardRef(({ groupedBOMs, bags, pouches }, ref) => {
  // Styles
  const containerStyle = {
    fontFamily: "Arial, sans-serif",
    margin: "20px",
    padding: "10px",
    color: "#333",
  };

  const headerStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
  };

  const logoStyle = {
    maxHeight: "80px",
  };

  const titleStyle = {
    fontSize: "32px",
    fontWeight: "bold",
    color: "#1976d2",
    margin: 0,
  };

  const subTitleStyle = {
    fontSize: "20px",
    color: "#9c27b0",
    margin: 0,
  };

  const sectionStyle = {
    padding: "15px",
    marginBottom: "20px",
    pageBreakInside: "avoid",
  };

  const bagTitleStyle = {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#333",
    marginBottom: "10px",
    borderBottom: "2px solid #1976d2",
    paddingBottom: "5px",
  };

  const pouchTitleStyle = {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#555",
    marginBottom: "10px",
    borderBottom: "1px solid #ccc",
    paddingBottom: "5px",
  };

  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
    marginBottom: "20px",
  };

  const thStyle = {
    border: "1px solid #ddd",
    padding: "8px",
    backgroundColor: "#f2f2f2",
    textAlign: "left",
  };

  const tdStyle = {
    border: "1px solid #ddd",
    padding: "8px",
  };

  const footerStyle = {
    fontSize: "12px",
    color: "#333",
    marginTop: "40px",
    textAlign: "center",
    pageBreakInside: "avoid",
  };

  const currentYear = new Date().getFullYear();

  return (
    <div ref={ref} style={containerStyle}>
      {/* Header */}
      <div style={headerStyle}>
        <div>
          <h1 style={titleStyle}>Bill of Materials</h1>
          <p style={subTitleStyle}>DC Event Medical Ltd.</p>
        </div>
        <img
          src="https://dceventmedical.co.uk/static/media/DCLogo.cf85b7c3b87b0326262a.png"
          alt="DC Event Medical Logo"
          style={logoStyle}
        />
      </div>
      <hr style={{ borderTop: "3px solid #000", marginBottom: "20px" }} />

      {/* BOM Content */}
      {Object.values(groupedBOMs).map(({ bag, pouches: bagPouches }) => (
        <div key={bag?.bag_id || Math.random()} style={sectionStyle}>
          <h2 style={bagTitleStyle}>
            Bag: {bag?.bag_name || "Unknown Bag"} (
            {bag?.description || "No description"})
          </h2>
          {bagPouches &&
            Object.entries(bagPouches).map(([pouchId, items]) => {
              const pouch = pouchId
                ? pouches.find((p) => p.pouch_id === parseInt(pouchId))
                : null;
              const pouchName = pouch ? pouch.pouch_name : "Loose Items";
              return (
                <div key={pouchId} style={{ marginBottom: "15px" }}>
                  <h3 style={pouchTitleStyle}>Pouch: {pouchName}</h3>
                  <table style={tableStyle}>
                    <thead>
                      <tr>
                        <th style={thStyle}>Item</th>
                        <th style={thStyle}>Quantity</th>
                        <th style={thStyle}>Location</th>
                        <th style={thStyle}>Notes</th>
                        <th style={thStyle}>Expiry</th>
                        <th style={thStyle}>Lot Number</th>
                      </tr>
                    </thead>
                    <tbody>
                      {items.map((item) => (
                        <tr key={item.bom_id || Math.random()}>
                          <td style={tdStyle}>
                            {item.item_name || "Unknown Item"}
                          </td>
                          <td style={tdStyle}>{item.quantity_required || 0}</td>
                          <td style={tdStyle}>
                            {item.location_in_bag || "N/A"}
                          </td>
                          <td style={tdStyle}>{item.notes || "None"}</td>
                          <td style={tdStyle}>{item.expiry_date || "N/A"}</td>
                          <td style={tdStyle}>{item.lot_number || "N/A"}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              );
            })}
        </div>
      ))}

      {/* Footer */}
      <div style={footerStyle}>
        <hr style={{ borderTop: "1px solid #ccc" }} />
        <p>
          <strong>DC Event Medical Ltd.</strong> | Registered in England No.{" "}
          <strong>11165671</strong> | ICO Registration No.{" "}
          <strong>ZB625778</strong>
        </p>
        <p>
          Registered Address: 4 Falcon Close, Fareham, Hampshire, PO16 8PL |
          Phone: 0330 058 8348 | Mobile: 07881 290082
        </p>
        <p>
          Email:{" "}
          <a href="mailto:liam@dceventmedical.co.uk">
            liam@dceventmedical.co.uk
          </a>{" "}
          | Website:{" "}
          <a href="https://www.dceventmedical.co.uk">
            www.dceventmedical.co.uk
          </a>
        </p>
        <p>
          This document contains confidential information intended solely for
          the individual or entity to whom it is addressed. Unauthorized
          disclosure, copying, distribution, or reliance upon the contents of
          this document is strictly prohibited. All personal data is processed
          in compliance with GDPR and the Data Protection Act 2018.
        </p>
        <p>
          DC Event Medical Ltd. operates in alignment with ISO 27001 and ISO
          9001 standards, and follows Cyber Essentials guidelines to ensure the
          highest levels of information security and quality management.
        </p>
        <p>
          For feedback or complaints about our services, please contact us at{" "}
          <a href="mailto:feedback@dceventmedical.co.uk">
            feedback@dceventmedical.co.uk
          </a>{" "}
          or call 0330 058 8348.
        </p>
        <p>© {currentYear} DC Event Medical Ltd. All rights reserved.</p>
      </div>
    </div>
  );
});

export default PrintBOM;
