import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Paper,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
} from "@mui/material";
import { AddCircleOutline, Save, Delete } from "@mui/icons-material";
import apiClient from "../../utils/requestHandler";

const ItemDetails = () => {
  const { itemId } = useParams();
  const navigate = useNavigate();
  const [itemDetails, setItemDetails] = useState({
    item_name: "",
    item_type: "",
    quantity: "",
    unit: "",
    reorder_level: "",
    last_stock_check: "",
    location_id: "",
    batches: [],
  });
  const [loading, setLoading] = useState(true);
  const [locations, setLocations] = useState([]);
  const [editingBatch, setEditingBatch] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        if (itemId) {
          const response = await apiClient.get(`/api/inventory/${itemId}`);
          setItemDetails(response.data);
        }
        const locationsResponse = await apiClient.get("/api/inventory/locations");
        setLocations(locationsResponse.data);
      } catch (error) {
        console.error("Error fetching item details or locations:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [itemId]);

  const handleInputChange = (field, value) => {
    setItemDetails((prevDetails) => ({ ...prevDetails, [field]: value }));
  };

  const handleBatchChange = (field, value) => {
    setEditingBatch((prevBatch) => ({ ...prevBatch, [field]: value }));
  };

  const handleSaveItem = async () => {
    setLoading(true);
    try {
      if (itemId) {
        await apiClient.put(`/api/inventory/${itemId}`, itemDetails);
      } else {
        await apiClient.post(`/api/inventory`, itemDetails);
        navigate("/inventory");
      }
      alert("Item saved successfully");
    } catch (error) {
      console.error("Error saving item:", error);
      alert("Failed to save item");
    } finally {
      setLoading(false);
    }
  };

  const handleAddBatch = () => {
    setEditingBatch({ batch_number: "", expiry_date: "", quantity: "" });
  };

  const handleSaveBatch = () => {
    setItemDetails((prevDetails) => ({
      ...prevDetails,
      batches: [...prevDetails.batches, editingBatch],
    }));
    setEditingBatch(null);
  };

  const handleDeleteBatch = (batchId) => {
    setItemDetails((prevDetails) => ({
      ...prevDetails,
      batches: prevDetails.batches.filter((batch) => batch.batch_id !== batchId),
    }));
  };

  return (
    <Box sx={{ mt: 10, p: 3 }}>
      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Typography variant="h6">{itemId ? "Edit Item" : "Add New Item"}</Typography>
            <TextField
              label="Item Name"
              value={itemDetails.item_name}
              onChange={(e) => handleInputChange("item_name", e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Item Type"
              value={itemDetails.item_type}
              onChange={(e) => handleInputChange("item_type", e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Quantity"
              type="number"
              value={itemDetails.quantity}
              onChange={(e) => handleInputChange("quantity", e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Unit"
              value={itemDetails.unit}
              onChange={(e) => handleInputChange("unit", e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Reorder Level"
              type="number"
              value={itemDetails.reorder_level}
              onChange={(e) => handleInputChange("reorder_level", e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Last Stock Check"
              type="date"
              value={itemDetails.last_stock_check}
              onChange={(e) => handleInputChange("last_stock_check", e.target.value)}
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Location"
              select
              value={itemDetails.location_id}
              onChange={(e) => handleInputChange("location_id", e.target.value)}
              fullWidth
              margin="normal"
            >
              {locations.map((location) => (
                <MenuItem key={location.location_id} value={location.location_id}>
                  {location.location_name}
                </MenuItem>
              ))}
            </TextField>

            <Typography variant="h6" sx={{ mt: 2 }}>
              Batches
            </Typography>
            <List>
              {itemDetails.batches.map((batch) => (
                <ListItem key={batch.batch_id}>
                  <ListItemText
                    primary={`Batch Number: ${batch.batch_number}`}
                    secondary={`Expiry Date: ${batch.expiry_date} - Quantity: ${batch.quantity}`}
                  />
                  <IconButton edge="end" color="secondary" onClick={() => handleDeleteBatch(batch.batch_id)}>
                    <Delete />
                  </IconButton>
                </ListItem>
              ))}
              <Button startIcon={<AddCircleOutline />} onClick={handleAddBatch} sx={{ mt: 2 }}>
                Add Batch
              </Button>
            </List>

            <Button variant="contained" color="primary" startIcon={<Save />} onClick={handleSaveItem} sx={{ mt: 3 }}>
              Save Item
            </Button>
          </>
        )}
      </Paper>

      {/* Dialog for editing/adding a batch */}
      {editingBatch && (
        <Dialog open={Boolean(editingBatch)} onClose={() => setEditingBatch(null)}>
          <DialogTitle>{`${editingBatch.batch_id ? "Edit" : "Add"} Batch`}</DialogTitle>
          <DialogContent>
            <TextField
              label="Batch Number"
              value={editingBatch.batch_number}
              onChange={(e) => handleBatchChange("batch_number", e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Expiry Date"
              type="date"
              value={editingBatch.expiry_date}
              onChange={(e) => handleBatchChange("expiry_date", e.target.value)}
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Quantity"
              type="number"
              value={editingBatch.quantity}
              onChange={(e) => handleBatchChange("quantity", e.target.value)}
              fullWidth
              margin="normal"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditingBatch(null)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSaveBatch} color="primary" variant="contained">
              Save Batch
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default ItemDetails;
