import React, { useState, useEffect } from "react";
import {
  TextField,
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import apiClient from "../../utils/requestHandler";
import useNetworkStatus from "../../hooks/useNetworkStatus";
import { usePRF } from "../../utils/PRFContext"; // Import the PRF context

const PatientDemographics = () => {
  const { prfData, setPrfData } = usePRF(); // Access PRF data and setter from context
  const [searchName, setSearchName] = useState("");
  const [searchDob, setSearchDob] = useState("");
  const [foundPatients, setFoundPatients] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [error, setError] = useState("");
  const [cheerTeams, setCheerTeams] = useState([]); // Track available cheer teams
  const [selectedTeam, setSelectedTeam] = useState(""); // Selected cheer team
  const [selectedSquad, setSelectedSquad] = useState(""); // Selected squad
  const isOnline = useNetworkStatus();

  // Fetch cheer teams and squads for the dropdown
  const fetchCheerTeams = async () => {
    try {
      const response = await apiClient.get(`/api/cheer/teams`); // Endpoint for cheer teams and squads
      setCheerTeams(response.data);
    } catch (error) {
      console.error("Error fetching cheer teams:", error);
    }
  };

  useEffect(() => {
    fetchCheerTeams(); // Fetch cheer teams on component mount
  }, []);

  // Populate form with existing PRF data if available
  useEffect(() => {
    if (
      prfData?.demographics?.patient_id &&
      prfData.demographics.patient_id !== ""
    ) {
      console.log("Loaded existing patient data:", prfData.demographics);
      setSelectedPatient(prfData.demographics);
    } else {
      console.log("No existing patient data found");
    }
  }, [prfData]);

  const handleSearchPatient = async () => {
    if (!isOnline) return;

    try {
      const response = await apiClient.get(
        `/api/patients/search?name=${searchName}&dob=${searchDob}`
      );
      setFoundPatients(response.data);
      setError("");
    } catch (err) {
      setError("No patient found. Please create a new patient.");
      setFoundPatients([]);
    }
  };

  const handleCreateOrUpdatePatient = async () => {
    if (!isOnline) return;

    try {
      let response;
      if (selectedPatient?.patient_id) {
        // Update existing patient
        response = await apiClient.put(
          `/api/patient/${selectedPatient.patient_id}`,
          {
            ...prfData.demographics,
            cheer_team_id: selectedTeam,
            cheer_squad_id: selectedSquad,
          }
        );
      } else {
        // Create new patient
        response = await apiClient.post("/api/patient", {
          ...prfData.demographics,
          cheer_team_id: selectedTeam,
          cheer_squad_id: selectedSquad, // Store the selected cheer team and squad
        });
        setFoundPatients([response.data]);
        setPrfData((prev) => ({
          ...prev,
          patient_id: response.data.patient_id,
        }));
      }

      // Add the patient to the squad only if a squad is selected
      if (selectedSquad) {
        await apiClient.post("/api/patient/patient-squad", {
          patient_id: response.data.patient_id,
          squad_id: selectedSquad,
          start_date: new Date().toISOString(),
        });
      }

      setError("");
    } catch (err) {
      setError("Failed to save patient. Please try again.");
    }
  };

  const handlePatientSelect = async (patient) => {
    setSelectedPatient(patient);

    // Populate PRF data with selected patient details, including patient_id
    setPrfData((prev) => ({
      ...prev,
      demographics: {
        patient_id: patient.patient_id,
        name: patient.name,
        dob: patient.dob,
        gender: patient.gender,
        address: patient.address,
        contact_number: patient.contact_number,
        next_of_kin_name: patient.next_of_kin_name,
        next_of_kin_contact: patient.next_of_kin_contact,
        relationship_to_nok: patient.relationship_to_nok,
        past_medical_history: patient.past_medical_history,
        medications: patient.medications,
        allergies: patient.allergies,
      },
    }));

    // Assign the patient to the selected cheer squad
    if (selectedSquad === !"")
      await apiClient.post("/api/patient-squad", {
        patient_id: patient.patient_id,
        squad_id: selectedSquad,
        start_date: new Date().toISOString(),
      });
  };

  const handleInputChangeLocal = (e) => {
    const { name, value } = e.target;
    setPrfData((prev) => ({
      ...prev,
      demographics: {
        ...prev.demographics,
        [name]: value,
      },
    }));
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0]; // Extract only the date part (yyyy-MM-dd)
  };

  return (
    <Box>
      {!isOnline && (
        <Typography variant="body1" color="error" sx={{ mb: 2 }}>
          You are currently offline. Patient search is not available.
        </Typography>
      )}

      {isOnline &&
        (!selectedPatient || prfData.demographics.patient_id === "") && (
          <>
            <TextField
              label="Patient Name"
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="Date of Birth"
              type="date"
              value={searchDob}
              onChange={(e) => setSearchDob(e.target.value)}
              fullWidth
              sx={{ mb: 2 }}
              InputLabelProps={{ shrink: true }}
            />
            <Button
              onClick={handleSearchPatient}
              variant="contained"
              sx={{ mb: 2 }}
              disabled={!searchName || !searchDob}
            >
              Search
            </Button>

            {foundPatients.length > 0 && (
              <List>
                {foundPatients.map((patient) => (
                  <ListItem
                    button
                    key={patient.patient_id}
                    onClick={() => handlePatientSelect(patient)}
                  >
                    <ListItemText
                      primary={`${patient.name} - DOB: ${new Date(
                        patient.dob
                      ).toLocaleDateString()}`}
                      secondary={`Contact: ${patient.contact_number}`}
                    />
                  </ListItem>
                ))}
              </List>
            )}
          </>
        )}

      {error && (
        <Box sx={{ color: "red", mt: 2 }}>
          <Typography variant="body2">{error}</Typography>
        </Box>
      )}

      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="cheer-team-label">Cheer Team</InputLabel>
        <Select
          labelId="cheer-team-label"
          value={selectedTeam}
          label="Cheer Team"
          onChange={(e) => setSelectedTeam(e.target.value)}
        >
          {cheerTeams.map((team) => (
            <MenuItem key={team.team_id} value={team.team_id}>
              {team.team_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {selectedTeam && (
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel id="cheer-squad-label">Cheer Squad</InputLabel>
          <Select
            labelId="cheer-squad-label"
            value={selectedSquad}
            label="Cheer Squad"
            onChange={(e) => setSelectedSquad(e.target.value)}
          >
            {cheerTeams
              .find((team) => team.team_id === selectedTeam)
              ?.squads.map((squad) => (
                <MenuItem key={squad.squad_id} value={squad.squad_id}>
                  {squad.squad_name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}

      {selectedPatient ? (
        <Box>
          <TextField
            label="Patient Name"
            name="name"
            value={prfData.demographics?.name || ""}
            onChange={handleInputChangeLocal}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Date of Birth"
            name="dob"
            type="date"
            value={
              prfData.demographics?.dob
                ? formatDate(prfData.demographics.dob)
                : ""
            }
            onChange={handleInputChangeLocal}
            fullWidth
            sx={{ mb: 2 }}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Gender"
            name="gender"
            value={prfData.demographics?.gender || ""}
            onChange={handleInputChangeLocal}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Address"
            name="address"
            value={prfData.demographics?.address || ""}
            onChange={handleInputChangeLocal}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Contact Number"
            name="contact_number"
            value={prfData.demographics?.contact_number || ""}
            onChange={handleInputChangeLocal}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Next of Kin Name"
            name="next_of_kin_name"
            value={prfData.demographics?.next_of_kin_name || ""}
            onChange={handleInputChangeLocal}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Next of Kin Contact"
            name="next_of_kin_contact"
            value={prfData.demographics?.next_of_kin_contact || ""}
            onChange={handleInputChangeLocal}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Relationship to Next of Kin"
            name="relationship_to_nok"
            value={prfData.demographics?.relationship_to_nok || ""}
            onChange={handleInputChangeLocal}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Past Medical History"
            name="past_medical_history"
            value={prfData.demographics?.past_medical_history || ""}
            onChange={handleInputChangeLocal}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Medications"
            name="medications"
            value={prfData.demographics?.medications || ""}
            onChange={handleInputChangeLocal}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Allergies"
            name="allergies"
            value={prfData.demographics?.allergies || ""}
            onChange={handleInputChangeLocal}
            fullWidth
            sx={{ mb: 2 }}
          />
          <Button
            onClick={handleCreateOrUpdatePatient}
            variant="contained"
            sx={{ mt: 2 }}
          >
            {selectedPatient ? "Update Patient" : "Create Patient"}
          </Button>
        </Box>
      ) : (
        <Box>
          <TextField
            label="Patient Name"
            name="name"
            value={prfData.demographics?.name || ""}
            onChange={handleInputChangeLocal}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Date of Birth"
            name="dob"
            type="date"
            value={
              prfData.demographics?.dob
                ? formatDate(prfData.demographics.dob)
                : ""
            }
            onChange={handleInputChangeLocal}
            fullWidth
            sx={{ mb: 2 }}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Gender"
            name="gender"
            value={prfData.demographics?.gender || ""}
            onChange={handleInputChangeLocal}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Address"
            name="address"
            value={prfData.demographics?.address || ""}
            onChange={handleInputChangeLocal}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Contact Number"
            name="contact_number"
            value={prfData.demographics?.contact_number || ""}
            onChange={handleInputChangeLocal}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Next of Kin Name"
            name="next_of_kin_name"
            value={prfData.demographics?.next_of_kin_name || ""}
            onChange={handleInputChangeLocal}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Next of Kin Contact"
            name="next_of_kin_contact"
            value={prfData.demographics?.next_of_kin_contact || ""}
            onChange={handleInputChangeLocal}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Relationship to Next of Kin"
            name="relationship_to_nok"
            value={prfData.demographics?.relationship_to_nok || ""}
            onChange={handleInputChangeLocal}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Past Medical History"
            name="past_medical_history"
            value={prfData.demographics?.past_medical_history || ""}
            onChange={handleInputChangeLocal}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Medications"
            name="medications"
            value={prfData.demographics?.medications || ""}
            onChange={handleInputChangeLocal}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Allergies"
            name="allergies"
            value={prfData.demographics?.allergies || ""}
            onChange={handleInputChangeLocal}
            fullWidth
            sx={{ mb: 2 }}
          />
          <Button
            onClick={handleCreateOrUpdatePatient}
            variant="contained"
            disabled={!isOnline}
            sx={{ mt: 2 }}
          >
            Create Patient
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default PatientDemographics;
